import {Injectable} from '@angular/core';
import SortEnum from '../enums/sort.enum';
import { Sort } from '../models/Sort';

@Injectable({
  providedIn: 'root'
})

export class SortService {

  public getSort( sort: SortEnum ): Sort {
    switch (sort) {
      case SortEnum.id:
        return { orderBy: 'id', direction: 'DESC' };
        break;
      case SortEnum.price_high:
        return {orderBy: 'price', direction: 'DESC'};
        break;
      case SortEnum.price_low:
        return {orderBy: 'price', direction: 'ASC'};
        break;
      case SortEnum.refreshedAt:
        return {orderBy: 'refreshedAt', direction: 'ASC'};
        break;
      default:
        return { orderBy: 'id', direction: 'DESC' };
        break;
    }
  }
}
