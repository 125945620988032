import { Component, Input, OnInit } from '@angular/core';

import { from } from 'rxjs';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';

import { AdsCustomFieldsData } from '../../../models/CustomField/AdsCustomFieldsData';

@Component({
  selector: 'app-custom-field-data-container',
  templateUrl: './custom-field-data-container.component.html',
  styleUrls: ['./custom-field-data-container.component.scss'],
})
export class CustomFieldDataContainerComponent implements OnInit {

  @Input() adsCustomFieldData: any[] = [];

  groupedAdsCustomFieldData: any[] = [];

  ngOnInit() {
    this.groupAdsCustomFieldData();
  }

  groupAdsCustomFieldData(): void {
    from(this.adsCustomFieldData)
      .pipe(
        groupBy(
          customField => customField.customFieldId
        ),
        mergeMap(group => group.pipe(toArray())),
        toArray()
      )
      .subscribe( data =>
        {
          this.groupedAdsCustomFieldData = data;
        });

  }

  checkIfCustomFieldNumberOrSelectExist(): boolean {
    return !!this.adsCustomFieldData.find( adCustomFieldData => adCustomFieldData.type !== 'multiselect' );
  }
}
