import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Ad } from '../../_shared/models/Ad/Ad';
import { BaseApiService } from '../../_shared/services/base-api.service';
import {CreateAd} from "../../_shared/models/Ad/CreateAd";

@Injectable({
  providedIn: 'root'
})
export class ClassfieldService extends BaseApiService {

  /**
   * Fetch all ads.
   */
  public index(pageIndex: number, pageSize: number, priceFrom: number, priceTo: number, currency: string,
               category: string, locations = null,  keyword: string = null, orderBy: string = 'id',
               direction: string = 'DESC', customFieldFilters: string[] = null): Observable<any> {

    let params = new HttpParams();
    params     = (priceFrom ? params.set('priceFrom', JSON.stringify(priceFrom)) : params);
    params     = (priceTo ? params.set('priceTo', JSON.stringify(priceTo)) : params);
    params     = (currency ? params.set('currency', currency) : params);
    params     = (category ? params.set('category', category) : params);
    if (locations) {
      const locationsArray = locations.split(',');
      locationsArray.forEach(location => params = params.append('locations', +location));
    }
    if ( customFieldFilters ) {
      if (Array.isArray(customFieldFilters)){
        customFieldFilters.forEach(customFieldFilter => params = params.append('filters', customFieldFilter));
      } else {
        params = params.append('filters', customFieldFilters );
      }
    }


    params = (pageIndex ? params.set('pageIndex', JSON.stringify(pageIndex)) : params);
    params = (pageSize ? params.set('pageSize', JSON.stringify(pageSize)) : params);
    params = (keyword ? params.set('keyword', keyword) : params);
    params = params.set('active', JSON.stringify(true));
    params = (orderBy ? params.set('orderBy', orderBy) : params);
    params = (direction ? params.set('direction', direction) : params);

    return this.http.get(`${this.apiUrl}/api/classfields/paginated`, {params});
  }

  /**
   * Fetch user ads.
   */
  public userAds(userId: number, pageIndex: number, pageSize: number, active: boolean, keyword: string,
                 orderBy: string, direction: string): Observable<any> {

    let params = new HttpParams();
    //params     = (userId ? params.set('id', JSON.stringify(userId)) : params);
    params     = (pageIndex ? params.set('pageIndex', JSON.stringify(pageIndex)) : params);
    params     = (pageSize ? params.set('pageSize', JSON.stringify(pageSize)) : params);
    params     = params.set('active', JSON.stringify(active));
    params     = (keyword ? params.set('keyword', keyword) : params);
    params     = (orderBy ? params.set('orderBy', orderBy) : params);
    params     = (direction ? params.set('direction', direction) : params);

    return this.http.get(`${this.apiUrl}/api/classfields/owner/${userId}`, {params});
  }

  /**
   * Fetch all user ads.
   */
  public userAllAds(userId: number, pageIndex: number, pageSize: number, active: boolean, keyword: string,
                    orderBy: string, direction: string): Observable<any> {

    let params = new HttpParams();
    //params     = (userId ? params.set('id', JSON.stringify(userId)) : params);
    params     = (pageIndex ? params.set('pageIndex', JSON.stringify(pageIndex)) : params);
    params     = (pageSize ? params.set('pageSize', JSON.stringify(pageSize)) : params);
    params     = params.set('active', JSON.stringify(active));
    params     = (keyword ? params.set('keyword', keyword) : params);
    params     = (orderBy ? params.set('orderBy', orderBy) : params);
    params     = (direction ? params.set('direction', direction) : params);

    return this.http.get(`${this.apiUrl}/api/classfields/owner/${userId}/all`, {params});
  }

  /**
   * Fetch one ad.
   */
  public show(id: number): Observable<any> {

    return this.http.get(`${this.apiUrl}/api/classfields/${id}`);
  }

  public showBySlug(slug: string): Observable<Ad> {
    return this.http.get<Ad>(`${this.apiUrl}/api/classfields/slug/${slug}`);
  }

  /**
   * Create new Ad.
   */
  public create(ad: CreateAd): Observable<Ad> {

    return this.http.post<Ad>(`${this.apiUrl}/api/classfields`, ad);
  }

  /**
   * Update Ad.
   */
  public update(ad: Ad): Observable<Ad> {

    return this.http.put<Ad>(`${this.apiUrl}/api/classfields/${ad.id}`, ad);
  }

  /**
   * Delete Ad.
   */
  public destroy(adId: number): Observable<any> {

    return this.http.delete(`${this.apiUrl}/api/classfields/${adId}`);
  }

  /**
   * Update Ad type.
   */
  public updateAdType(adId: number, adTypesIds: Array<number>): Observable<any> {
    return this.http.put(`${this.apiUrl}/api/classfields/${adId}/change-ad-type`, { adTypesIds });
  }

  /**
   * Strike Ad
   */
  strike(classfieldId: number): Observable<any> {
    return this.http.patch(`${this.apiUrl}/api/classfields/${classfieldId}/strike/admin`, {});
  }

  /**
   * Set Ad to top.
   */
  public setClassfieldOnTop(adId: number): Observable<any> {

    return this.http.put(`${this.apiUrl}/api/classfields/${adId}/set-on-top`, {});
  }

  /**
   * Fetch phone number as Image
   *
   * @returns Base64
   */

  public getPhoneNumberPicture( adId: number): Observable<any> {

    const encodedAdId = this.encodeData( adId.toString() );

    return  this.http.get(`${this.apiUrl}/api/classfields/${ encodedAdId }/phone-number`);
  }

  /**
   * Create Base64 from string
   *
   * @returns Base64
   */

  private encodeData( data: string ){
    return btoa(data);
  }

}
