import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { Category } from '../../../models/Category';

@Component({
  selector: 'app-category-picker-item',
  templateUrl: './category-picker-item.component.html',
  styleUrls: ['./category-picker-item.component.scss'],
})
export class CategoryPickerItemComponent implements OnDestroy{

  @Input() category: Category = undefined;
  @Input() chosenCategory: Category = undefined;

  @Output() selectedCategory = new EventEmitter<Category>();

  openSubcategories(): void {
    this.selectedCategory.emit(this.category);
  }

  openSubcategoriesPipeline(category): void {
    this.selectedCategory.emit(category);
  }

  ngOnDestroy() {
    this.chosenCategory = undefined;
  }
}
