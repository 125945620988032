import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import { AdFilter } from '../../../models/AdFilter';
import { CustomFieldFilter } from '../../../models/CustomField/CustomFieldFilter';
import {CustomFieldService} from '../../../services/custom-field.service';

@Component({
  selector: 'app-classfield-list-filter-info',
  templateUrl: './classfield-list-filter-info.component.html',
  styleUrls: ['./classfield-list-filter-info.component.scss'],
})
export class ClassfieldListFilterInfoComponent implements OnInit {

  @Input() filters: Partial<AdFilter>;
  @Input() breadcrumbs = [];

  @Output() cancelFilterEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelAllFiltersEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor( private customFieldService: CustomFieldService ) { }

  ngOnInit() { }

  getCustomFieldFiters(): CustomFieldFilter[] {

    return  this.customFieldService.parseStringToCustomFieldFilter( this.filters.customFieldFilters );
  }

  cancelFilter(type: string, selectedFilter: any = null) {
    const body = {
      type,
      selectedFilter
    };
    this.cancelFilterEvent.emit(body);
  }

  cancelAllFilters() {
    this.cancelAllFiltersEvent.emit();
  }
}
