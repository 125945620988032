import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { IonInput } from '@ionic/angular';

import {Store} from '@ngrx/store';

import { TitleAndDescriptionService } from 'src/app/_shared/services/title-and-description.service';
import { ToastService } from 'src/app/_shared/services/toast.service';
import { AuthService } from '../auth.service';

import { AppState } from '../../reducers';
import { activateAccountByPhoneNumber } from '../auth.actions';
import { IpAddressService } from '../../_shared/services/ip-address.service';
import { VerifyPhoneNumber } from '../../_shared/models/Auth/VerifyPhoneNumber';

@Component({
  selector: 'app-confirm-phone-number',
  templateUrl: './confirm-phone-number.component.html',
  styleUrls: ['./confirm-phone-number.component.scss'],
})

export class ConfirmPhoneNumberComponent {

  phoneNumber: string = undefined;
  loadingPhoneNumberSending = false;
  loaded = false;
  form: UntypedFormGroup = undefined;
  formActive = false;
  timeLeft = 0;
  interval;


  @ViewChild('input1', {static: false}) firstInputField: IonInput;

  curerntInputField: IonInput;

  ipAddress: string = undefined;

  constructor(private titleAndDescriptionService: TitleAndDescriptionService,
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              // private ipAddressService: IpAddressService,
              private toastService: ToastService,
              private formBuilder: UntypedFormBuilder,
              private store: Store<AppState>) { }

  ionViewWillEnter() {
    this.initialize();
    this.loaded = true;
  }

  ionViewDidEnter() {
    setTimeout(() => {
      this.firstInputField.setFocus();
    }, 500);
  }

  initialize() {
    this.titleAndDescriptionService.setTitle('Potvrda broja mobilnog telefona');
    this.titleAndDescriptionService.setDescription('Ovo je stranica za potvrdu broja mobilnog telefona');

    this.fetchPhoneNumber();
    this.buildPhoneNumberVerificationForm();
  }

  buildPhoneNumberVerificationForm() {

    this.form = this.formBuilder.group({

      digit1: [null, [Validators.required]],
      digit2: [null, [Validators.required]],
      digit3: [null, [Validators.required]],
      digit4: [null, [Validators.required]],
      digit5: [null, [Validators.required]],
      digit6: [null, [Validators.required]]
    });

    this.formActive = true;
  }

  fetchPhoneNumber(): void {

    if (this.route.snapshot.queryParamMap.has('phone_number')) {
      this.phoneNumber = this.route.snapshot.queryParamMap.get('phone_number');

    } else {
      this.router.navigate(['/auth/prijava']);
    }
  }

  validateInputField(currentInputField: IonInput, nextInputField: IonInput): void {

    if (currentInputField.value.toString().length > 1) {
      currentInputField.value = '';
    }

    if (currentInputField.value === '') {
      return;
    }

    currentInputField.value = currentInputField.value.toString().replace(/[^0-9]*/g, '');

    if (currentInputField.value.toString().length > 0 && nextInputField){
      this.gotoNextInputField(nextInputField);
    }
    else if (!nextInputField && !this.form.invalid) {
      this.sendVerificationCode();
      this.curerntInputField = this.firstInputField;
    }
  }

  gotoNextInputField(nextInputField: IonInput): void {

    nextInputField.setFocus();
    this.curerntInputField = nextInputField;
  }

  sendVerificationCode() {

    const token =   this.form.controls.digit1.value.toString() + ''
                  + this.form.controls.digit2.value.toString() + ''
                  + this.form.controls.digit3.value.toString() + ''
                  + this.form.controls.digit4.value.toString() + ''
                  + this.form.controls.digit5.value.toString() + ''
                  + this.form.controls.digit6.value.toString();

    if(token.length !== 6) {
      return;
    }

    this.loadingPhoneNumberSending = true;

    this.authService.confirmPhoneNumber(token, this.phoneNumber).subscribe(
      result => {
        this.toastService.showSuccess('Vaš broj telefona je uspešno verifikovan.');
        this.store.dispatch(activateAccountByPhoneNumber());

        this.handleNavigate();

        this.loadingPhoneNumberSending = false;
      },
      error => {

        this.form.reset();
        this.toastService.showError(error.error.message).then((toast) => {
          this.firstInputField.setFocus();
          this.curerntInputField = this.firstInputField;

          toast.onDidDismiss().then(() => {
            this.curerntInputField.setFocus();
          });
        });
        this.loadingPhoneNumberSending = false;

      }
    );
  }

  handleNavigate(): void {

    if (this.route.snapshot.queryParamMap.has('navigate_to')){
      this.router.navigate([`${this.route.snapshot.queryParamMap.get('navigate_to')}`], {replaceUrl: true});
    } else {
      this.router.navigate(['/'], {replaceUrl: true});
    }
  }

  resendVerificationCode() {

    // if (!this.ipAddress) {
    //   this.ipAddressService.getIp().subscribe(result => {
    //     this.ipAddress = result;
    //     this.verifyPhoneNumberRequest();
    //   });
    // }
    // else {
    //   this.verifyPhoneNumberRequest();
    // }

    this.verifyPhoneNumberRequest(true);
  }

  verifyPhoneNumberRequest(showToast: boolean) {

    const verifyPhoneNumber = {
      phoneNumber: this.phoneNumber,
      // ip: this.ipAddress
    } as VerifyPhoneNumber;

    this.authService.verifyPhoneNumber(verifyPhoneNumber).subscribe(
      result => {
        if ( showToast ){
          this.toastService.showSuccess('Novi verifikacinoi SMS je poslat na Vaš broj telefona..');
        }
      },
      error => {
        this.toastService.showError(error.error.message);
      }
    );
  }

  startTimer() {
    this.interval = setInterval(() => {
      if ( this.timeLeft > 0 ) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }
}
