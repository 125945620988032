import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from '../../services/platform.service';
import { FeedbackComponent } from '../feedback/feedback.component';

@Component({
             selector:    'app-footer',
             templateUrl: './footer.component.html',
             styleUrls:   ['./footer.component.scss'],
           })
export class FooterComponent implements OnInit {

  feedbackModal: any;

  constructor(public platformService: PlatformService,
              public modalController: ModalController) {
  }

  ngOnInit() {
  }

  async openFeedbackModal() {
    this.feedbackModal = await this.modalController.create({
      component: FeedbackComponent,
      cssClass: 'feedback-modal-class',
      componentProps: {
        title: 'Primetili ste problem?',
        text:  'Ukoliko ste primetili neki problem molimo vas da nas obavestite ovde.',
        alertMessage: 'Ova forma se koristi isključivo za prijavu problema a ne za dodavanje oglasa!'
      }
    });

    return await this.feedbackModal.present();
  }

}
