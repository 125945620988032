import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Rating } from '../../models/Rating/Rating';

@Component({
  selector: 'app-rating-list-holder',
  templateUrl: './rating-list-holder.component.html',
  styleUrls: ['./rating-list-holder.component.scss'],
})
export class RatingListHolderComponent {

  @Input()  ratings: Rating[]              = [];
  @Output() loadData: EventEmitter<void>   = new EventEmitter<void>();
  @Input()  isLoading;
  @Input()  listEnd                        = false;

  onLoadData(event) {

    this.loadData.emit();
    event.target.complete();
  }
}
