import {ActivatedRoute, Router} from '@angular/router';
import { Component } from '@angular/core';
import { PlatformService } from '../../_shared/services/platform.service';
import { TitleAndDescriptionService } from '../../_shared/services/title-and-description.service';
import { AuthService } from '../auth.service';
import { ToastService } from '../../_shared/services/toast.service';
import {select, Store} from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import {activateAccountByEmail, getCurrentUser, logout} from '../auth.actions';
import { IpAddressService } from '../../_shared/services/ip-address.service';
import { VerifyEmail } from '../../_shared/models/Auth/VerifyEmail';
import {filter} from 'rxjs/operators';
import {authUser} from '../auth.selectors';
import {YesNoDialogComponent} from '../../_shared/components/yes-no-dialog/yes-no-dialog.component';
import {PopoverController} from '@ionic/angular';
import {JwtTokenService} from '../../_shared/services/jwt-token.service';


@Component({
  selector:    'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls:   ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent {

  email: string = undefined;
  token: any = undefined;

  loadingVerifying = false;
  loadingEmailSending = false;
  tokenFailed = false;

  loaded = false;

  ipAddress: string = undefined;
  yesNoDialog: any;

  constructor(public platformService: PlatformService,
              private titleAndDescriptionService: TitleAndDescriptionService,
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private toastService: ToastService,
              // private ipAddressService: IpAddressService,
              private jwtTokenService: JwtTokenService,
              private store: Store<AppState>,
              private popoverController: PopoverController) {
  }

  ionViewWillEnter() {
    this.initialize();
    this.loaded = true;
  }

  initialize() {
    this.titleAndDescriptionService.setTitle('Potvrda e-adrese');
    this.titleAndDescriptionService.setDescription('Ovo je stranica za potvrdu e-adrese');

    if (this.route.snapshot.queryParamMap.has('token')) {
      this.fetchToken();
    }
    this.fetchEmail();
  }

  fetchEmail() {

    if (this.route.snapshot.queryParamMap.has('email')) {
      this.email = this.route.snapshot.queryParamMap.get('email');
      if (!this.route.snapshot.queryParamMap.has('token')) {
        this.sendVerifyEmailRequest(false);
      }
    } else {
      this.router.navigate(['/auth/prijava']);
    }
  }

  fetchToken() {

    if (this.route.snapshot.queryParamMap.has('token')) {
      this.token = this.route.snapshot.queryParamMap.get('token');
    }

    // return;
    this.loadingVerifying = true;
    this.authService.confirmEmail(this.token).subscribe(
      result => {
        this.toastService.showSuccess('Vaš email je uspešno verifikovan.');
        this.store.dispatch(activateAccountByEmail());
        if (localStorage.getItem('accessToken')){
          this.router.navigate(['/']);
        } else {
          this.router.navigate(['/auth/prijava']);
        }
      },
      error => {
        this.token = undefined;
        this.tokenFailed = true;
      },
      () => this.loadingVerifying = false
    );
  }

  resendVerificationEmail() {

    // if (!this.ipAddress) {
    //   this.ipAddressService.getIp().subscribe(result => {
    //     this.ipAddress = result;
    //     this.sendVerifyEmailRequest();
    //   });
    // }
    // else {
    //   this.sendVerifyEmailRequest();
    // }
    this.sendVerifyEmailRequest(true);
  }

  sendVerifyEmailRequest(showToast: boolean) {

    const verifyEmail = {
      email: this.email,
      // ip: this.ipAddress
    } as VerifyEmail;

    this.loadingEmailSending = true;
    this.authService.verifyEmail(verifyEmail).subscribe(
      result => {
        if ( showToast ) {
          this.toastService.showSuccess('Novi verifikacinoi mail je poslat na Vašu e-adresu.');
        }
        this.loadingEmailSending = false;
      },
      error => {
        if ( showToast ){
          this.toastService.showError(error.error.message);
        }
        this.loadingEmailSending = false;
      }
    );
  }

  getCurrentUser() {
    this.store.dispatch(getCurrentUser());
  }


  async openYesNoDialog() {
    this.yesNoDialog = await this.popoverController.create({
      component: YesNoDialogComponent,

      componentProps: {dialogText: `Da li ste sigurni?`, buttonText: ['Da', 'Ne']}
    });

    this.yesNoDialog.onDidDismiss().then((response: any) => {
      if (response.role === 'decision' && response.data) {
        this.logOut();
      }
    });

    return await this.yesNoDialog.present();
  }

  logOut() {

    // tslint:disable-next-line:no-shadowed-variable
    let authUserInStore;

    this.store
      .pipe(select(authUser))
      .pipe(filter(user => !!user))
      .subscribe(response => {

        authUserInStore = response;

      }).unsubscribe();

    this.store.dispatch(logout({user: authUserInStore}));
    this.router.navigate(['/auth/prijava'],{ replaceUrl: true });
  }
}
