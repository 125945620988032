import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { BaseApiService } from './base-api.service';
import { PaginationResponse } from '../models/PaginationResponse';
import { Location } from '../models/Location';

@Injectable({
              providedIn: 'root'
            })
export class LocationService extends BaseApiService {

  public index(pageIndex: number, pageSize: number, keyword: string = '', orderBy: string = 'id', direction: string = 'ASC'): Observable<PaginationResponse<Location>> {

    let params = new HttpParams();
    params     = (pageIndex ? params.set('pageIndex', JSON.stringify(pageIndex)) : params);
    params     = (pageSize ? params.set('pageSize', JSON.stringify(pageSize)) : params);
    params     = (keyword ? params.set('keyword', JSON.stringify(keyword)) : params);
    params     = (orderBy ? params.set('orderBy', JSON.stringify(orderBy)) : params);
    params     = (direction ? params.set('direction', JSON.stringify(direction)) : params);

    return this.http.get<PaginationResponse<Location>>(`${this.apiUrl}/api/locations`, {params});
  }

  public all(): Observable<Location[]> {
    // return of([]);

    return this.http.get<Location[]>(`${this.apiUrl}/api/locations/all`);
  }

}
