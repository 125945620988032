import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule} from '@angular/core';
import { BrowserModule, BrowserTransferStateModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ClipboardModule } from 'ngx-clipboard';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { metaReducers, reducers } from './reducers';
import {EntityDataModule, EntityDataService, EntityDefinitionService, EntityMetadataMap} from '@ngrx/data';
import { BrowserStateInterceptor } from './_shared/interceptors/browser-state.interceptor';
import * as fromLocation from './_shared/store/location/reducers/location.reducers';
import { locationReducer } from './_shared/store/location/reducers/location.reducers';
import { LocationEffects } from './_shared/store/location/effects/location-effects.service';
import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { FacebookService } from './_shared/services/facebook.service';
import { SharedModule } from './_shared/shared.module';

import * as fromClassfieldCount from './_shared/store/classfield-count/reducers/classfield-count.reducers';
import { classfieldCountReducer } from './_shared/store/classfield-count/reducers/classfield-count.reducers';
import {ClassfieldResolver} from './home/classfield/resolvers/classfield.resolver';
import {ClassfieldEntityService} from './home/classfield/classfield-entity.service';
import {ClassfieldEditorResolver} from './home/classfield/resolvers/classfield-editor.resolver';
import {ClassfieldDataService} from './home/classfield/classfield-data.service';
import CollectionEnum from './_shared/enums/collection.enum';

import * as fromCategory from './_shared/store/category/reducers/category.reducers';
import { categoryReducer } from './_shared/store/category/reducers/category.reducers';
import { CategoryEffects } from './_shared/store/category/effects/category-effects.service';
import { ClassfieldListPage } from './home/classfield/classfield-list/classfield-list.page';
import { ClassfieldDetailPage } from './home/classfield/classfield-detail/classfield-detail.page';
import { HomePageModule } from './home/home.module';

import { CookieService } from 'ngx-cookie-service';
import {LandingPageModule} from './landing-page/landing-page.module';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

const entityMetadata: EntityMetadataMap = {
  Ad: {
  }
};


@NgModule({
    declarations: [AppComponent, ClassfieldListPage, ClassfieldDetailPage],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    IonicModule.forRoot({animated: false}),
    AuthModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {metaReducers}),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Minimal
    }),
    EntityDataModule.forRoot({}),
    ClipboardModule,
    BrowserTransferStateModule,
    StoreModule.forFeature(fromCategory.categoryFeatureKey, categoryReducer),
    StoreModule.forFeature(fromLocation.locationFeatureKey, locationReducer),
    StoreModule.forFeature(fromClassfieldCount.classfieldCountFeatureKey, classfieldCountReducer),
    EffectsModule.forFeature([LocationEffects, CategoryEffects]),
    SocialLoginModule,
    SharedModule,
    HomePageModule,
    LandingPageModule,
    StoreModule.forRoot({}, {})
  ],
    providers: [
        ClassfieldEntityService,
        ClassfieldResolver,
        CookieService,
        ClassfieldDataService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(environment.facebook.appId, FacebookService.config)
                    }
                ]
            } as SocialAuthServiceConfig,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BrowserStateInterceptor,
            multi: true
        },
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        Title
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private eds: EntityDefinitionService,
              private entityDataService: EntityDataService,
              private classfieldDataService: ClassfieldDataService) {

    eds.registerMetadataMap(entityMetadata);
    entityDataService.registerService(CollectionEnum.Ad, classfieldDataService);
  }
}
