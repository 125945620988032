import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

import {IonInput, ModalController} from '@ionic/angular';
import {ActionsSubject, Store} from '@ngrx/store';

import {login, loginFacebook, loginFailure} from 'src/app/auth/auth.actions';
import { AppState } from '../../../../reducers';

import { PlatformService } from '../../../services/platform.service';

import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import {ActivatedRoute, Router} from '@angular/router';
import { ofType } from '@ngrx/effects';
import { ToastService } from '../../../services/toast.service';
import {AuthService} from "../../../../auth/auth.service";
import {FacebookService} from "../../../services/facebook.service";
import {SocialUser} from "angularx-social-login";

@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: UntypedFormGroup;
  submitted = false;
  phoneNumberRegex = /^(06){1}[0-9]{1}(-|\/)?[0-9]{1}((-)?[0-9](-)?){4,5}[0-9]{1}$/;

  isAuthenticatingIndicator = false;

  @ViewChild('password', { static: false }) password: IonInput;

  constructor(public platformService: PlatformService,
              private formBuilder: UntypedFormBuilder,
              private store: Store<AppState>,
              private modalController: ModalController,
              private router: Router,
              private route: ActivatedRoute,
              private actionsSubject: ActionsSubject,
              private authService: AuthService,
              private facebookService: FacebookService,
              private toastService: ToastService) {
  }

  ngOnInit() {
    this.buildLoginForm();
    this.subscribeToLoginFailure();
  }


  buildLoginForm(): void {
    this.loginForm = this.formBuilder.group({
      verifier: ['', [ Validators.required ]],
      password: ['', [ Validators.required ]]
    }, {
    validators: [
      this.checkVerifier
    ]
  });
  }

  facebookLogin() {
    this.facebookService.showLoginModal(this.dispatchFacebookLoginAction());
  }

  dispatchFacebookLoginAction() {
    return (response: SocialUser) => {
      this.store.dispatch(loginFacebook({ accessToken: response.authToken }));
    };
  }

  checkVerifier(form: UntypedFormGroup): ValidationErrors | null {

    const verifier = form.controls.verifier.value;

    if (verifier && verifier === '' ||  /^(06){1}[0-9]{1}(-|\/)?[0-9]{1}((-)?[0-9](-)?){4,5}[0-9]{1}$/.test(verifier)) {
      return;
    }

    if (verifier && verifier.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      return;
    }

    return { verifierNotValid: true };
  }


  login(): void {

    this.submitted = true;
    if (!this.loginForm.valid) {
      return;
    }
    if (this.phoneNumberRegex.test(this.loginForm.controls.verifier.value)) {

      this.removeSpecialCharacters();
    }

    this.isAuthenticatingIndicator = true;



    this.store.dispatch(login({
      user: {
        verifier: this.loginForm.controls.verifier.value,
        password: this.loginForm.controls.password.value
      }
    }));

  }

  removeSpecialCharacters(): void {

    const phoneNumber = this.loginForm.controls.verifier.value.toString().replace(/[\/,-]/g, '');
    this.loginForm.controls.verifier.setValue( phoneNumber );
    this.loginForm.controls.verifier.updateValueAndValidity();
  }

  showPassword(password): void {

    password.type = password.type === 'password' ? 'text' : 'password';
  }

  async openForgotPasswordModal() {

    const modal = await this.modalController.create({
      component:      ForgotPasswordComponent,
      cssClass:       'forgot-password-modal',
      componentProps: {
        verifier: this.loginForm.controls.verifier.value,
      }
    });
    return await modal.present();
  }

  navigateToRegisterPage(): void {
    if (this.route.snapshot.queryParamMap.has('navigate_to')){
      this.router.navigate(['/auth/registracija'], { queryParams: { navigate_to: this.route.snapshot.queryParamMap.get('navigate_to') } });
    } else {
      this.router.navigate(['/auth/registracija'], { replaceUrl: true });
    }
  }

  subscribeToLoginFailure() {

    this.actionsSubject.pipe(ofType(loginFailure.type))
      .subscribe(async (data) => {
        this.isAuthenticatingIndicator = false;
        const toast = await this.toastService.showError(data['error']['message']);
        this.password.setFocus();
        toast.onDidDismiss().then(() => {
          this.password.setFocus();
        });
      });
  }

  ngOnDestroy(): void {

    this.loginForm.reset();
  }

}
