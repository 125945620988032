import { Injectable } from '@angular/core';

import {Subject, Subscription} from 'rxjs';

import * as signalR from '@microsoft/signalr';

import { BaseApiService } from './base-api.service';

import { Store } from '@ngrx/store';

import { AppState } from '../../reducers';

import { Chat } from '../models/Chat';
import { HttpClient } from '@angular/common/http';
import {getNotificationsSuccess} from '../../auth/auth.actions';

@Injectable({
  providedIn: 'root'
})

export class NotificationService extends BaseApiService {

  public newNotification$: Subject<Chat> = new Subject<Chat>();

  constructor(public http: HttpClient,
              private store: Store<AppState>) {
    super(http);
  }

  private hubConnection: signalR.HubConnection;
  subscriptions: Subscription[] = [];
  unreadChatIds: number[] = [];


  public getHubConnection() {
    return this.hubConnection;
  }

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.apiUrl}/api/chat/chat-hub`, { accessTokenFactory: () => localStorage.getItem('accessToken') })
      .build();

    this.hubConnection.start()
      .then(() => { })
      .catch(err => console.log(err));
  }


  public addNotificationListener = () => {

    this.hubConnection.on('ReceiveMessage', (chat: Chat)  => {
      this.newNotification$.next(chat);
      this.store.dispatch(getNotificationsSuccess({chatIds: [chat.id]}));
    });
  }

  public disconnect() {

    this.hubConnection.stop().then(() => { });
  }

}
