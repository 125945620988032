import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import * as feather from 'feather-icons';

@Component({
  selector:    'app-logo',
  templateUrl: './logo.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LogoComponent implements OnInit {

  @Input() darkMode = false;

  constructor() { }

  ngOnInit() {}
}
