import { createReducer, on } from '@ngrx/store';

import { CategoryActions } from '../action-types';

import { CategoryTreeService } from 'src/app/_shared/services/category-tree.service';

import { SideMenuCategory } from '../../../models/SideMenuCategory';

export const categoryFeatureKey = 'category';

export const initialSideMenuCategoryState: SideMenuCategory = {
  isLoaded: false,
  categories: [],
  breadCrumbs: []
};

export const categoryReducer = createReducer(

  initialSideMenuCategoryState,

  on(CategoryActions.topCategories, (state, _action) => {
    return {
      ...state
    };
  }),

  on(CategoryActions.topCategoriesSuccess, (state, _action) => {
    return {
       ...state,
       isLoaded: true,
       categories: CategoryTreeService.prototype.numerateCategories(_action.categories)
    };
  }),

  on(CategoryActions.topCategoriesFailure, (state, _action) => {
    return {
      ...state,
      isLoaded: false,
      categories: []
    };
  }),

  on(CategoryActions.categories, (state, _action) => {
    return {
      ...state
    };
  }),

  on(CategoryActions.categoriesSuccess, (state, _action) => {
    return {
      ...state,
      isLoaded: true,
      categories: _action.openCategories ?
         CategoryTreeService.prototype.moveCategoryToTop(
           CategoryTreeService.prototype.sortCategories(
             CategoryTreeService.prototype.numerateCategories(
               CategoryTreeService.prototype.setOpenedCategory(
                 CategoryTreeService.prototype.mapCategories(_action.categories),
                 _action.slug
               ).subcategories,
             )
           )
         )
        : _action.categories
    };
  }),

  on(CategoryActions.categoriesFailure, (state, _action) => {
    return {
      ...state,
      isLoaded: false,
      categories: []
    };
  }),

  on(CategoryActions.breadCrumbsSuccess, (state, _action) => {
    return {
      ...state,
      breadCrumbs: _action.breadCrumbs
    };
  })
);
