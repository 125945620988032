import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import { Login } from '../_shared/models/Auth/Login';
import { Register } from '../_shared/models/Auth/Register';
import { Token } from '../_shared/models/Auth/Token';
import { BaseApiService } from '../_shared/services/base-api.service';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ForgotPassword } from '../_shared/models/Auth/ForgotPassword';
import { ResetPassword } from '../_shared/models/Auth/ResetPassword';
import {VerifyEmail} from "../_shared/models/Auth/VerifyEmail";
import {VerifyPhoneNumber} from "../_shared/models/Auth/VerifyPhoneNumber";
import {User} from "../_shared/models/User";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService {

  login(login: Login): Observable<Token> {

    return this.http.post<Token>(`${this.apiUrl}/api/accounts/login`, login);
  }

  register(register: Register, isLegal: boolean): Observable<any> {

    if ( isLegal ) {
      return this.registerLegalPerson(register);
    } else {
      return this.registerPhysicallyPerson(register);
    }
  }

  registerLegalPerson(register: Register): Observable<any> {

    return this.http.post<any>(`${this.apiUrl}/api/accounts/register/company`, register);
  }

  registerPhysicallyPerson(register: Register): Observable<any> {

    return this.http.post<any>(`${this.apiUrl}/api/accounts/register`, register);
  }

  logout(): Observable<any> {

    return this.http.post(`${this.apiUrl}/api/accounts/logout`, {});
  }

  isLoggedIn(): Observable<any> {
    if (!localStorage.getItem('accessToken')) {
      return throwError('Access token not available');
    }
    return this.http.get(`${this.apiUrl}/api/accounts/current-user`);
  }

  revokeToken(token: Token, accessToken: string): Observable<Token> {

    return this.http.post<Token>(`${this.apiUrl}/api/accounts/revoke-token`, token,
                                 {headers: new HttpHeaders({'Expired-token': `Bearer ${accessToken}`})});
  }

  forgotPassword(forgotPassword: ForgotPassword): Observable<any> {

    return this.http.post(`${this.apiUrl}/api/accounts/forgot-password`, forgotPassword);
  }

  resetPassword(resetPassword: ResetPassword): Observable<any> {

    return this.http.post(`${this.apiUrl}/api/accounts/reset-password?token=${resetPassword.token}`, {
      phoneNumber:       resetPassword?.phoneNumber ? resetPassword.phoneNumber : null,
      password:          resetPassword.password,
      confirmedPassword: resetPassword.confirmedPassword
    });
  }

  verifyEmail(verifyEmail: VerifyEmail): Observable<any> {

    return this.http.post(`${this.apiUrl}/api/accounts/verify-email`, verifyEmail);
  }

  confirmEmail(token: string): Observable<any> {

    return this.http.post(`${this.apiUrl}/api/accounts/confirm-email?token=${token}`, {});
  }

  verifyPhoneNumber(verifyPhoneNumber: VerifyPhoneNumber): Observable<any> {

    return this.http.post(`${this.apiUrl}/api/accounts/verify-phone-number`, verifyPhoneNumber);
  }

  confirmPhoneNumber(token: string, phoneNumber: string): Observable<any> {

    return  this.http.post(`${this.apiUrl}/api/accounts/confirm-phone-number`, { token, phoneNumber });
  }

  loginWithFacebook(accessToken: string): Observable<Token> {
    return this.http.post<Token>(`${this.apiUrl}/api/accounts/login/facebook`, { accessToken });
  }

  /**
   * Non http calls ----------------------------------------------------------------------------------------------------
   */

  /**
   * Check if password has at least: - one non alphanumeric character,
   *                                 - one digit,
   *                                 - one uppercase.
   *
   * @param form Form data
   * @return ValidateErrors | null
   */
  strongPassword(form: UntypedFormGroup): ValidationErrors | null {

    const password           = form.controls.password.value;
    const hasNonAlphanumeric = /\W/;
    const hasNumber          = /\d/;
    const hasUppercase       = /[A-Z]/;

    return (hasNonAlphanumeric.test(password) && hasNumber.test(password) && hasUppercase.test(password) ? null :
      {passwordNotStrong: true});
  }

  /**
   * Check if password and password confirmation are the same.
   *
   * @param form Form data
   *
   * @return ValidationErrors | null
   */
  passwordsMatch(form: UntypedFormGroup): ValidationErrors | null {
    const password     = form.controls.password.value;
    const confirmation = form.controls.confirmedPassword.value;

    return (password === confirmation ? null : {passwordsNotMatched: true});
  }
}
