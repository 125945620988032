import { CategoryTreeService } from '../../../_shared/services/category-tree.service';
import { ToastService } from '../../../_shared/services/toast.service';
import { FeedbackService } from '../../../_shared/services/feedback.service';
import { CategoryService } from '../../../_shared/services/category.service';
import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';

import {
  IonContent,
  IonRouterOutlet,
  ModalController,
  NavController,
  Platform,
  PopoverController,
  ToastController
} from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { ClipboardService } from 'ngx-clipboard';
import {Subscriber, Subscription} from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import { ClassfieldMenuComponent } from '../../../_shared/components/classfield/classfield-menu/classfield-menu.component';
import { FullscreenImagePage } from '../../../_shared/components/fullscreen-image/fullscreen-image.page';
import { NumberPopoverComponent } from '../../../_shared/components/number-popover/number-popover.component';
import { loadedCategories } from 'src/app/_shared/store/category/selectors/category.selectors';
import { FeedbackComponent } from 'src/app/_shared/components/feedback/feedback.component';
import { Ad } from '../../../_shared/models/Ad/Ad';
import { Category } from '../../../_shared/models/Category';
import { User } from '../../../_shared/models/User';
import { PlatformService } from '../../../_shared/services/platform.service';
import { authUser } from '../../../auth/auth.selectors';
import { AppState } from '../../../reducers';
import { ClassfieldEntityService } from '../classfield-entity.service';
import { ClassfieldService } from '../classfield.service';
import { TitleAndDescriptionService } from '../../../_shared/services/title-and-description.service';
import { FileService } from '../../../_shared/services/file.service';
import { Image } from '../../../_shared/models/Image';
import { ChatService } from '../../../_shared/services/chat.service';
import { StripService } from '../../../_shared/services/strip.service';
import { Share } from '@capacitor/share';
import { environment } from '../../../../environments/environment';

@Component({
  selector:    'app-classfield-detail',
  templateUrl: './classfield-detail.page.html',
  styleUrls:   ['./classfield-detail.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClassfieldDetailPage implements OnInit {

  @ViewChild('pageTop') pageTop: IonContent;

  subscriptions: Subscription[] = [];
  breadcrumbs                   = [];

  ad: Ad;
  similarAds: Ad[];
  authUser: User = null;
  isLoading      = 0;
  options;

  feedbackModal: any;

  categories: Category[] = [];
  imageObject: Array<{ image: string, thumbImage: string, alt: string, title: string }> = [];

  innerWidth;
  showAds = false;

  //TODO: REPLACE ION SLISDES WITH SWIPER.JS
  @ViewChild('slides') slides: any;
  @ViewChild('thumbSlides') thumbSlides: any;

  constructor(public platformService: PlatformService,
              public modalController: ModalController,
              private classfieldService: ClassfieldService,
              private adEntityService: ClassfieldEntityService,
              private route: ActivatedRoute,
              private router: Router,
              private popoverController: PopoverController,
              private fileService: FileService,
              private clipboardApi: ClipboardService,
              private toastController: ToastController,
              private store: Store<AppState>,
              private platform: Platform,
              private routerOutlet: IonRouterOutlet,
              private titleAndDescriptionService: TitleAndDescriptionService,
              private categoryService: CategoryService,
              private feedbackService: FeedbackService,
              private toastService: ToastService,
              private categoryTreeService: CategoryTreeService,
              private chatService: ChatService,
              private stripService: StripService,
              private navCtrl: NavController) {
  }


  ngOnInit() {
    this.fetchAuthUserData();
    this.checkUrlForAdId();
    this.innerWidth = window.innerWidth;
    this.prepareImageObject();
  }

    prepareImageObject() {
      if (this.ad) {
        this.ad.images.forEach((image: Image) => {
          this.imageObject.push({
            image: this.readPic(image),
            thumbImage: this.readPic(image),
            alt: '',
            title: ''
          });
        });
      }
    }


  ionViewWillEnter() {
    this.subscribeOnBackButton();
  }

  ionViewDidEnter() {
    this.showAds = true;
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.code === 'ArrowUp') {
      this.pageTop.scrollByPoint(0, -200, 500);
    } else if (event.code === 'ArrowDown') {
      this.pageTop.scrollByPoint(0, 200, 500);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  subscribeOnBackButton() {

    this.subscriptions.push(
      this.platform.backButton.subscribeWithPriority(-1, () => {

        if (!this.routerOutlet.canGoBack()) {
          this.router.navigate(['/home/profil']);
        } else {
          this.routerOutlet.pop();
        }
      })
    );
  }

  fetchAuthUserData(): void {

    this.subscriptions.push(
      this.store
        .pipe(select(authUser))
        .pipe(filter(user => !!user))
        .subscribe(response => {
          this.authUser = response;
        })
    );
  }

  /**
   * Check for ad id in route.
   *
   * @return void
   */
  checkUrlForAdId(): void {

    if (this.route.snapshot.paramMap.has('slug')) {
      this.fetchAd();
    } else {
      this.router.navigate([`/`]);
    }
  }

  fetchAd() {

    this.subscriptions.push(
      this.route.data
        .subscribe(async (data: Data) => {
          data.ad.subscribe( (classfield: Ad) => {
            this.ad = classfield;
            this.fetchCategories(classfield);
          });
        }, error => {
          this.router.navigate(['/']);
        })
    );
  }

  fetchCategories(classfield: Ad): void {

    this.store
      .pipe(select( loadedCategories ))
      .subscribe(categories => {
        this.categories = categories;
        this.setAdData(classfield);
      }).unsubscribe();
  }



  setAdData(ad: Ad): void {

    this.options     = {
      url:          environment.baseUrl + '/oglas/' + ad.slug,
      chooserTitle: 'Odaberite aplikaciju',
    };
    //this.fetchSimilarAds();
  }

  fetchSimilarAds(): void {

    this.isLoading++;
    // TODO check this.ad.category.slug
    this.subscriptions.push(
      this.classfieldService.index(0, 10, null, null, null, this.ad.category.slug)
        .pipe(finalize(() => this.isLoading--))
        .subscribe(response => {
          this.similarAds = response.items.filter(responseAd => responseAd.id !== this.ad.id);
        }, error => console.log(error))
    );
  }

  next(slides) {

    slides.slideNext();
  }

  prev(slides) {

    slides.slidePrev();
  }

  showImage(index) {

    this.slides.slideTo(index + 1);
  }

  async openImage() {

    const modal = await this.modalController.create({
                                                      component:      FullscreenImagePage,
                                                      cssClass:       'image-modal',
                                                      componentProps: {
                                                        current: this.slides.getActiveIndex(),
                                                        image:   this.ad.images,
                                                        youtubeLink: this.ad.youtubeLink
                                                      }
                                                    });
    return await modal.present();
  }

  async share() {

    await Share.share(this.options);
  }

  async copyUrl() {

    this.clipboardApi.copy(window.location.href);
    const toast = await this.toastController.create({
                                                      message:       'Link je kopiran u privremenu memoriju.',
                                                      duration:      2000,
                                                      position:      'top',
                                                      color:         'success',
                                                      keyboardClose: true,
                                                      animated:      true
                                                    });
    toast.present();
  }

  readPic(image: Image) {

    return this.fileService.readPicture(image, false, this.checkDate());
  }

  checkDate(): boolean {


    const detailDate = new Date( environment.detailDate );
    const adDate = new Date( this.ad.createdAt );

    return adDate > detailDate;
  }

  // callOwner() {
  //
  //   this.callNumber.callNumber(this.ad.phoneOverride, true);
  // }

  async showMenu(ev: any) {

    const popover = await this.popoverController.create({
                                                          component:      ClassfieldMenuComponent,
                                                          componentProps: {['adId']: this.ad.id, ['authUser']: this.authUser, ['classfieldTypeId']: this.ad.typeId, ['adAdTypes']: this.ad.adAdTypes},
                                                          animated:       true,
                                                          mode:           'ios',
                                                          event:          ev,
                                                          translucent:    true
                                                        });

    popover.onDidDismiss().then(value => {

      if (value.role === 'RenewClassfield') {
        this.ad.time = 'danas';
      }
    });

    await popover.present();
  }

  async showNumber(ev: any) {

    const popover = await this.popoverController.create({
      component:      NumberPopoverComponent,
      // componentProps: {['phone']: this.ad.phoneOverride},
      componentProps: {['adId']: this.ad.id},
      animated:       true,
      mode:           'ios',
      event:          ev,
      translucent:    true
    });
    await popover.present();
  }

  openConversationWithOwner(): void {

    this.checkIfConversationExist();
  }

  checkIfConversationExist(): void {

    this.subscriptions.push(
      this.chatService.checkIfChatExist(this.ad.ownerId, this.ad.id).subscribe(response => {
        if (response) {
          this.router.navigate([`home/inbox/${response}`], {replaceUrl: true});
        } else {
          this.router.navigate(['home/inbox/novi-chat'], {queryParams: {slug: this.ad.slug}, replaceUrl: true});
        }
      })
    );
  }

  async openFeedbackModal() {
    this.feedbackModal = await this.modalController.create({
      component: FeedbackComponent,
      cssClass: 'feedback-modal-class',
      componentProps: {
        title: 'Prijava oglasa',
        text: 'Ako ste primetili da je oglas nevalidan ili sadrži neki neprimereni sadržaj molimo vas da nas obavestite ovde.',
        isAdReport: true,
        adId: this.ad.id,
        adTitle: this.ad.title
      }
    });

    return await this.feedbackModal.present();
  }

  goToOwnerProfile() {

    if (this.authUser?.id === this.ad.ownerId) {
      this.router.navigate(['home/profil']);
    } else {
      this.router.navigate(['home/oglasi/oglasi-korisnika/' + this.ad.ownerId]);
    }
  }

  onCategoryChose(category: Category) {

    this.router.navigate([`/${category.slug}`], {replaceUrl: true});
  }

  onSearchWithKeyword(keyword: string) {

    this.router.navigate(['/'], {queryParams: {keyword}});
  }

  checkPrice(sellingWay: string): number {
    return this.stripService.removeCharactersFromPrice(sellingWay);
  }

  navigateBack(): void {
    this.navCtrl.back();
  }

  ionViewWillLeave() {

    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }


  getUrl(ownerWebSiteLink: string): string {

    if ( ownerWebSiteLink.includes('https') || ownerWebSiteLink.includes('http')  ) {
      return ownerWebSiteLink;
    } else if ( ownerWebSiteLink.includes('www') ) {
      return 'https://'.concat(ownerWebSiteLink);
    } else {
      return 'https://www.'.concat(ownerWebSiteLink);
    }
  }

  @HostListener('window:keyup', ['$event'])
  async handleKeyUp(event) {

    if (event?.code === 'ArrowRight') {
      this.slides.slideNext();
    } else if (event?.code === 'ArrowLeft') {
      this.slides.slidePrev();
    }
  }
}
