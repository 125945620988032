import { createAction, props } from '@ngrx/store';

import { Location } from '../../../models/Location';

export const locationInit = createAction(
  '[Location] Location Init'
);

export const locationLoad = createAction(
  '[Location] Location Load'
);

export const locationSuccess = createAction(
  '[Location] Location Success',
  props<{ locations: Location[] }>()
);

export const locationFailure = createAction(
  '[Location] Location Failure',
  props<{ error: any }>()
);
