import { createFeatureSelector, createSelector } from '@ngrx/store';

import { locationFeatureKey } from '../reducers/location.reducers';

import { LocationState } from '../reducers/location.reducers';

export const selectLocationState = createFeatureSelector<LocationState>(locationFeatureKey);

export const locationsLoading = createSelector(
  selectLocationState,
  ( state: LocationState ) => state.loading
);

export const locations = createSelector(
  selectLocationState,
  ( state: LocationState ) => state.locations
);
