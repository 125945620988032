import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SideMenuCategory } from '../../../models/SideMenuCategory';
import { categoryFeatureKey } from '../reducers/category.reducers';

export const selectCategoryState = createFeatureSelector<SideMenuCategory>(categoryFeatureKey);

export const isLoaded = createSelector(
  selectCategoryState,
  ( state: SideMenuCategory ) => state.isLoaded
);

export const loadedCategories = createSelector(
  selectCategoryState,
  ( state: SideMenuCategory ) => state.categories
);

export const breadCrumbs = createSelector(
  selectCategoryState,
  ( state: SideMenuCategory ) => state.breadCrumbs
);
