import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GuestGuard } from '../_shared/guards/guest.guard';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ConfirmPhoneNumberComponent } from './confirm-phone-number/confirm-phone-number.component';

const routes: Routes = [
  {
    path:         'auth',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canActivate:  [GuestGuard]
  },
  {
    path:         'registracija',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule),
    canActivate:  [GuestGuard]
  },
  {
    path:      'verifikacija-emaila',
    component: ConfirmEmailComponent
  },
  {
    path:      'verifikacija-broja',
    component: ConfirmPhoneNumberComponent
  },
  {
    path:         'oporavak-lozinke',
    loadChildren: () => import('./password-reset/password-reset.module').then(m => m.PasswordResetPageModule)
  },
];

@NgModule({
            imports: [
              RouterModule.forChild(routes)
            ],
            exports: [RouterModule]
          })
export class AuthRoutingModule {
}
