import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
              providedIn: 'root'
            })

export class RecaptchaService extends BaseApiService {

  public recaptcha( token: string ): Observable<any> {

    return this.http.post(`${this.apiUrl}/api/recaptcha`, { token });
  }
}
