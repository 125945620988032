import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import { HomePage } from './home.page';

import { VerificationGuard } from '../_shared/guards/verification.guard';

import { ClassfieldDetailResolver } from './classfield/resolvers/classfield-detail.resolver';

const routes: Routes = [
  {
    path:      'home',
    component: HomePage,
    children:  [
      {
        path:         'oglasi',
        loadChildren: () => import('./classfield/classfield.module').then(m => m.ClassfieldPageModule)
      },
      {
        path:          'transakcije',
        loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule),
        canActivate:  [VerificationGuard]
      },
      {
        path:         'profil',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
        canActivate:  [VerificationGuard]
      },
      {
        path:         'inbox',
        loadChildren: () => import('./inbox/inbox.module').then( m => m.InboxModule),
        canActivate:  [VerificationGuard]
      }
    ]
  }
];

@NgModule({
            imports: [RouterModule.forChild(routes)],
            exports: [RouterModule]
          })
export class HomePageRoutingModule {
}
