import { Component, Input, HostListener, AfterViewInit, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { FileService } from '../../services/file.service';

import { Image } from '../../models/Image';

@Component({
             selector:    'app-fullscreen-image',
             templateUrl: './fullscreen-image.page.html',
             styleUrls:   ['./fullscreen-image.page.scss'],
           })
export class FullscreenImagePage implements OnInit, AfterViewInit, OnDestroy {

  @Input() current;
  @Input() image;
  @Input() youtubeLink: string;

  currentImg = null;
  initialSlide = 0;
  currentImageIndex = 0;
  totalImageCount   = 0;
  canChangeImageIndex = false;

  @ViewChild('slides') slides;
  @ViewChild('slides', {read: ElementRef})slider: ElementRef;

  showImages = false;

  images = [];

  constructor(private modalController: ModalController,
              private fileService: FileService) {
  }


  ngOnInit(): void {
    this.initialSlide = this.current.__zone_symbol__value - 1;
    const modalState = {
      modal : true,
      desc : 'fake state for our modal'
    };
    history.pushState(modalState, null);
  }


  ngAfterViewInit(): void {
    this.slides.getSwiper().then(swiper => {  swiper.params.zoom.maxRatio = 1; });
    this.readImages();
  }

  readImages(): void {

    this.image.forEach((image: Image) => {
      this.images.push(this.fileService.readPicture(image));
    });

    setTimeout(() => {
      this.showImages = false;
    }, 1);

    setTimeout(() => {

      this.currentImageIndex = this.current.__zone_symbol__value;

      this.totalImageCount = this.images.length;
      if ( this.youtubeLink && this.youtubeLink.length > 0) {
        this.totalImageCount++;
      }

    }, 0);

    this.slides.update();
  }


  next(): void {
    this.slides.slideNext();
  }

  prev(): void {
    this.slides.slidePrev();
  }

  zoom(zoomIn: boolean): void {

    if (zoomIn) {
      this.slides.getSwiper().then(swiper => {

        if (swiper.params.zoom.maxRatio < 3) {
          swiper.params.zoom.maxRatio += 0.2;
          swiper.zoom.in();
        }
      });
    } else {
      this.slides.getSwiper().then(swiper => {

        if ( swiper.params.zoom.maxRatio > 1) {
          swiper.params.zoom.maxRatio -= 0.2;
          swiper.zoom.in();
        }
      });
    }
  }

  handleDoubleTap(): void {
    this.slides.getSwiper().then(swiper => {

      if (swiper.params.zoom.maxRatio > 1) {
        swiper.params.zoom.maxRatio = 1;
      } else {
        swiper.params.zoom.maxRatio = 3;
      }
      swiper.zoom.in();
    });
  }


  changeIconSrc( htmlImageTag: HTMLImageElement, imageSrc: string ): void {
    htmlImageTag.src = imageSrc;
  }

  incrementImageIndex(): void {
    if ( this.canChangeImageIndex ) {
      if (this.currentImageIndex === this.totalImageCount) {
        this.currentImageIndex = 1;
      } else {
        this.currentImageIndex++;
      }
    }
  }

  decrementImageIndex(): void {
     if ( this.canChangeImageIndex ) {
       if (this.currentImageIndex === 1){
         this.currentImageIndex = this.totalImageCount;
       } else {
         this.currentImageIndex--;
       }
     }
  }

  @HostListener('window:keyup', ['$event']) handleKeyUp(event) {
    if (event?.code === 'ArrowRight'){
      this.next();
    } else if (event?.code === 'ArrowLeft') {
      this.prev();
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dismiss();
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
  }


  ngOnDestroy(): void {
    if (window.history.state.modal) {
      history.back();
    }
  }
}
