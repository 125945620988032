import { createReducer, on } from '@ngrx/store';

import { LocationActions } from '../action-types';

import { Location } from '../../../models/Location';
import { Loading } from '../../../models/Loading';

export const locationFeatureKey = 'location';

export interface LocationState {
  locations: Location[];
  loading: Loading;
}

export const initialLocationState: LocationState = {
  loading: Loading.NOT_LOADED,
  locations: []
};

export const locationReducer = createReducer(

  initialLocationState,

  on(LocationActions.locationInit, (state, _action) => {
    return {
      ...state
    };
  }),

  on(LocationActions.locationLoad, (state, _action) => {
    return {
      ...state,
      loading: Loading.LOADING
    };
  }),

  on(LocationActions.locationSuccess, (state, _action) => {
    return {
      ...state,
      loading: Loading.SUCCESS,
      locations: _action.locations
    };
  }),

  on(LocationActions.locationFailure, (state, _action) => {
    return {
      ...state,
      loading: Loading.FAILURE,
      locations: []
    };
  })
);
