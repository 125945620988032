import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
              providedIn: 'root'
            })
export class LanguageInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addLanguage(request))
      .pipe(
        catchError(error => {

          return throwError(error);
        }));
  }

  private addLanguage(request: HttpRequest<any>) {

    return request.clone({
                           setHeaders: {'Accept-Language': 'sr-RS'}
                         });
  }
}
