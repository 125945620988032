import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { authUser } from '../../auth/auth.selectors';
import { AppState } from '../../reducers';
import { User } from '../models/User';

@Injectable({
  providedIn: 'root'
})
export class VerificationGuard implements CanActivate {

  user: User;

  constructor(private store: Store<AppState>,
              private router: Router,
              private route: ActivatedRoute) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      this.store.pipe(select(authUser)).subscribe( response => {
        this.user = response;
      }).unsubscribe();


      if (this.user && !this.user.userAccountConfirmed && this.user.verificationType === 'phoneNumber'){

          if (this.route.snapshot.queryParamMap.has('navigate_to')) {
            return this.router.navigate(['/verifikacija-broja'], { queryParams: { phone_number: this.user.userName, navigate_to: '/home/oglasi/dodaj-oglas'}, replaceUrl: true });
          } else {
            return this.router.navigate(['/verifikacija-broja'], { queryParams: { phone_number: this.user.userName }, replaceUrl: true });
          }

      } else if (this.user && !this.user.userAccountConfirmed && this.user.verificationType === 'email') {
        if (this.route.snapshot.queryParamMap.has('navigate_to')) {
          return this.router.navigate(['/verifikacija-emaila'], { queryParams: { email: this.user.userName, navigate_to: '/home/oglasi/dodaj-oglas'}, replaceUrl: true });
        } else {
          return this.router.navigate(['/verifikacija-emaila'], { queryParams: { email: this.user.userName }, replaceUrl: true });
        }
      } else {
        return true;
      }
  }
}
