import { animate, style, transition, trigger } from '@angular/animations';
import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IonSearchbar, MenuController, ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/reducers';
import { Location } from '../../models/Location';
import { PlatformService } from '../../services/platform.service';
import {locations, locationsLoading} from '../../store/location/selectors/location.selectors';
import {Loading} from "../../models/Loading";
import {locationLoad} from "../../store/location/actions/location.actions";
import {Subscription} from "rxjs";
import {CustomField} from '../../models/CustomField/CustomField';
import {CustomFieldFilter} from '../../models/CustomField/CustomFieldFilter';
import { SearchService } from '../../services/search.service';
import { LogService } from '../../services/log.service';

@Component({
             selector:    'app-filter-picker',
             templateUrl: './filter-picker.component.html',
             styleUrls:   ['./filter-picker.component.scss'],
             animations:  [
               trigger('slideDownUpFilter', [
                 transition(':enter', [
                   style({height: 0}),
                   animate(350, style({height: 78}))
                 ]),
                 transition(':leave', [animate(250, style({height: 0}))]),
               ]),
               trigger('slideDownUpPrice', [
                 transition(':enter', [
                   style({height: 0}),
                   animate(350, style({height: 120}))
                 ]),
                 transition(':leave', [animate(250, style({height: 0}))]),
               ]),
               trigger('slideDownUpPlace', [
                 transition(':enter', [
                   style({height: 0}),
                   animate(350, style({height: 290}))
                 ]),
                 transition(':leave', [animate(250, style({height: 0}))]),
               ]),
             ]
           })
export class FilterPickerComponent implements OnInit, OnDestroy {

  @Input() chosenPriceFrom: number     = null;
  @Input() chosenPriceTo: number       = null;
  @Input() chosenCurrency              = null;
  @Input() chosenLocations: Location[] = [];
  @Input() chosenCustomFields: string[] = [];

  @Input() categorySlug: string        = null;

  clickedLocations: Location[]         = [];
  @Input() reset                       = new UntypedFormControl(false);
  @Input() resetPriceFrom              = new UntypedFormControl(false);
  @Input() resetPriceTo                = new UntypedFormControl(false);
  @Input() resetCurrency               = new UntypedFormControl(false);
  @Input() resetLocations              = new UntypedFormControl([]);
  @Input() resetCustomFieldFiltersPipe = new UntypedFormControl(null);

  @Input() chosenCustomFieldFilters: string[] = [];

  resetCustomFieldFilters = new UntypedFormControl(null);

  @Output() selectedCustomFieldFilters: EventEmitter<CustomFieldFilter[]> = new EventEmitter<CustomFieldFilter[]>();

  @Output() desktopFilters: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('ionSearchbar') ionSearchbar: IonSearchbar;

  filterForm: UntypedFormGroup;
  allLocations        = [];
  locationsForDisplay = [];
  expanded            = true;
  showPrice           = false;
  showPlace           = false;
  subscriptions: Subscription[] = [];

  constructor(public modalController: ModalController,
              private menu: MenuController,
              public platformService: PlatformService,
              private formBuilder: UntypedFormBuilder,
              private store: Store<AppState>,
              private searchService: SearchService,
              private logService: LogService) {
  }

  ngOnInit() {

    this.clickedLocations = Object.assign([], this.chosenLocations);
    // this.menu.enable(true, 'first');
    // this.menu.open('first');
    this.fetchLocations();
    this.buildFilterForm();
    this.subscribeOnReset();

    const modalState = {
      modal : true,
      desc : 'fake state for our modal'
    };
    history.pushState(modalState, null);
  }

  fetchLocations() {
    // TODO update if filter used
    this.store
      .pipe(select( locationsLoading ))
      .subscribe( loading => {

        if (loading === Loading.NOT_LOADED) {
          this.store.dispatch(locationLoad());
        }

        this.subscriptions.push(
          this.store
            .pipe(select(locations))
            .subscribe(locations => {
              this.allLocations        = locations;
              this.locationsForDisplay = locations;
            }));
      }).unsubscribe();
  }

  buildFilterForm() {

    this.filterForm = this.formBuilder.group({
                                               priceFrom:          [this.chosenPriceFrom],
                                               priceTo:            [this.chosenPriceTo],
                                               currency:           [this.chosenCurrency],
                                               locations:          [this.clickedLocations],
                                               customFieldFilters: [null]
                                             });
  }

  subscribeOnReset() {

    this.reset.valueChanges.subscribe(value => {
      if (value) {
        this.filterForm.controls.priceFrom.setValue(null);
        this.filterForm.controls.priceTo.setValue(null);
        this.filterForm.controls.currency.setValue(null);
        this.filterForm.controls.locations.setValue([]);
        this.clickedLocations = [];
      }
    });
    this.resetPriceFrom.valueChanges.subscribe(value => {
      if (value) {
        this.filterForm.controls.priceFrom.setValue(null);
        if (this.filterForm.controls.priceTo.value === null) {
          this.filterForm.controls.currency.setValue(null);
        }
      }
    });
    this.resetPriceTo.valueChanges.subscribe(value => {
      if (value) {
        this.filterForm.controls.priceTo.setValue(null);
        if (this.filterForm.controls.priceFrom.value) {
          this.filterForm.controls.currency.setValue(null);
        }
      }
    });
    this.resetCurrency.valueChanges.subscribe(value => {
      if (value) {
        this.filterForm.controls.currency.setValue(null);
      }
    });
    this.resetLocations.valueChanges.subscribe(value => {
      if (value) {
        this.clickedLocations = Object.assign([], value);
        this.filterForm.controls.locations.setValue(this.clickedLocations);
      }
    });
    this.resetCustomFieldFiltersPipe.valueChanges.subscribe(value => {
      if (value) {
        this.resetCustomFieldFilters.setValue(value);
      }
    });
  }

  // TODO(SSR) - This method is split into two
  // submitFilters() {
  //
  //   if (this.platformService.isDesktop()) {
  //     this.desktopFilters.emit(this.filterForm.value);
  //   } else {
  //     this.modalController.dismiss({data: this.filterForm.value});
  //   }
  //   this.toggleFilters();
  // }

  submitFiltersDesktop() {
    this.desktopFilters.emit(this.filterForm.value);
    this.toggleFilters();
  }

  submitFiltersMobile() {

    const locationsFromForm = this.filterForm.controls.locations.value;

    if ( locationsFromForm ) {
      const fingerprint = localStorage.getItem('fingerprint');
      this.logService.locationPicked({locationId: locationsFromForm[0].id, fingerprint}).subscribe();
    }

    this.modalController.dismiss({data: this.filterForm.value});
    this.toggleFilters();
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.closeModal();
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if ( event.key === 'Enter' ) {
      const dummyButton = document.getElementById('dummyButton');
      dummyButton.focus();
    }
  }

  closeModal() {

    this.modalController.dismiss({data: null});
    this.toggleFilters();
  }

  searchPlaces(event) {

    this.locationsForDisplay = [];
    const lowerCaseTerm = event.target.value.toLowerCase();

    const termsArray: string[] = [];
    this.searchService.findAllPermutations(lowerCaseTerm, termsArray, 0);

    if (termsArray.length === 0) {
      this.locationsForDisplay = this.allLocations;

    } else {
      const filteredArray = [];

      termsArray.forEach((termFromArray: string) => {
        const filtered = this.allLocations.filter(location => {
          return location.name.toLowerCase().includes(termFromArray);
        });

        filtered.forEach(category => filteredArray.push(category));
      });

      this.locationsForDisplay = this.searchService.removeDuplicates(filteredArray);
      this.searchService.displayFirstEntitesThatSubstringAtTheBeginingIsEqualToTerm(this.locationsForDisplay, termsArray, false);
      this.locationsForDisplay = this.searchService.boldSubstringOfEntitesThatAreEqualToTerms(this.locationsForDisplay, termsArray);
    }
  }

  pickPlace(location) {

    const includedLocations = this.clickedLocations?.map(
      chosenLocation => chosenLocation?.name === location?.name);
    if (includedLocations?.includes(true)) {
      this.clickedLocations = this.clickedLocations.filter(
        chosenLocation => !(chosenLocation?.name === location?.name));
    } else {
      this.clickedLocations = [];
      this.clickedLocations.push(location);
    }
    this.filterForm.controls.locations.setValue(this.clickedLocations);
    this.ionSearchbar.value  = '';
    this.locationsForDisplay = this.allLocations;

  }

  toggleFilters() {

    this.expanded = !this.expanded;
    if (!this.expanded) {
      this.showPrice = false;
      this.showPlace = false;
    }
  }

  isLocationChosen(location): boolean {

    const includedLocations = this.clickedLocations?.map(
      chosenLocation => chosenLocation?.name === location?.name);
    return includedLocations?.includes(true);
  }

  handleEmmitedCustomFieldFiltersData(event): void {

    this.filterForm.controls.customFieldFilters.setValue(event);
    this.filterForm.controls.customFieldFilters.updateValueAndValidity();
  }

  cancelFilters() {

    this.reset.setValue(true);
    this.toggleFilters();
    this.modalController.dismiss({data: this.filterForm.value});
  }


  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
    this.subscriptions = [];

    if (window.history.state.modal) {
      history.back();
    }
  }
}
