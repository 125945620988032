import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { PopoverController } from '@ionic/angular';

import { filter} from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { User } from '../../models/User';
import { AdType } from '../../models/Ad/AdType';

import { ToastService } from '../../services/toast.service';
import { ClassfieldService } from '../../../home/classfield/classfield.service';
import { ClassfieldTypeService } from '../../services/classfield-type.service';

import { AppState } from '../../../reducers';
import { authUser } from '../../../auth/auth.selectors';
import { updateCredit } from '../../../auth/auth.actions';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-classfield-type-popover',
  templateUrl: './classfield-type-popover.component.html',
  styleUrls: ['./classfield-type-popover.component.scss'],
})
export class ClassfieldTypePopoverComponent implements OnInit {

  @Input() classfieldId: number = undefined;
  @Input() popover: boolean = undefined;
  @Input() classfieldClassfieldTypes: any[] = [];

  classfieldTypes: any[] = [];
  maxAllowedDebt = 0;
  userCanGoInDebt = false;
  authUser: User = undefined;
  totalSum = 0;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onToggleChangeEmitter: EventEmitter<{ event: any, adType: AdType }>
    = new EventEmitter<{ event: any, adType: AdType }>();

  constructor( private store: Store<AppState>,
               private toastService: ToastService,
               private settingsService: SettingsService,
               private dialogController: PopoverController,
               private classfieldService: ClassfieldService,
               private classfieldTypeService: ClassfieldTypeService ) { }

  ngOnInit(): void {
    this.fetchClassfieldTypes();
    this.getAuthenticatedUser();
    this.fetchMaxAllowedDebt();
  }

  getAuthenticatedUser(): void {

    this.store
      .pipe(select(authUser))
      .pipe(filter(user => !!user))
      .subscribe(response => {
        this.authUser = response;
      });
  }

  fetchClassfieldTypes(): void {
    this.classfieldTypeService.getAdTypes().subscribe(response => {
      this.classfieldTypes = response;

      this.classfieldTypes = this.classfieldTypes.filter(i => i.name.toLowerCase() !== 'standard');

      this.classfieldTypes = this.classfieldTypes.map(adtype => {
        const index = this.classfieldClassfieldTypes.findIndex(x => (this.popover ? x.adTypeId : x.id) === adtype.id);
        if ( index === -1 ){
          return {
            ...adtype,
            active: false,
            expiration: null
          };
        } else {
          return {
            ...adtype,
            active: this.classfieldClassfieldTypes.length > 0 ?
              this.classfieldClassfieldTypes[index].active : false,
            expiration: this.classfieldClassfieldTypes[index].expiration
          };
        }

      });

      this.fillMissingClassfieldTypes();
    });
  }

  fillMissingClassfieldTypes(): void {

    this.classfieldTypes.forEach( classfieldType => {

      if (this.classfieldClassfieldTypes.length === 0){
        this.classfieldClassfieldTypes.push({
          active: classfieldType.active,
          expiration: classfieldType.expiration,
          adTypeId: classfieldType.id
        });
      } else if (this.classfieldClassfieldTypes.findIndex(x => x.adTypeId === classfieldType.id) === -1) {
        this.classfieldClassfieldTypes.push({
          active: false,
          expiration: null,
          adTypeId: classfieldType.id
        });
      }
    });
  }

  fetchMaxAllowedDebt(): void {
    this.settingsService.getMaxAllowedDebt().subscribe( response => {
      this.maxAllowedDebt = response.value;
    });
  }

  onToggleChange($event: any, adtype) {
    this.onToggleChangeEmitter.emit({ event: $event, adType: adtype });
    if (this.popover ) {
      this.classfieldClassfieldTypes[this.classfieldClassfieldTypes.findIndex(x => x.adTypeId === adtype.id)].active
        = $event.detail.checked;
    } else {
      adtype.active = $event.detail.checked;
    }

    this.calculcateCredit();
    this.userCanGoInDebt = this.checkIfUserCanGoInDebt();
  }

  calculcateCredit(): void {
    this.totalSum = 0;

    if ( this.popover ) {
      this.classfieldClassfieldTypes.forEach(classfieldType => {
        if ( classfieldType?.active && !classfieldType?.expiration ) {
          this.totalSum += this.classfieldTypes[this.classfieldTypes.findIndex(x => x.id === classfieldType.adTypeId)].price;
        }
      });
    } else {
      this.classfieldTypes.forEach(classfieldType => {
        if ( classfieldType.active ) {
          this.totalSum +=  classfieldType.price;
        }
      });
    }
  }

  checkIfUserCanGoInDebt(): boolean {
    let flag = true;

    if ( this.popover ) {
      this.classfieldClassfieldTypes.forEach(adType => {
        if ( adType.active &&
          !adType.expiration &&
          !this.classfieldTypes.find(x => x.id === adType.adTypeId).debtAllowed  &&
          this.totalSum > this.authUser.credit ) {
          flag = false;
        }
      });
    } else {
      this.classfieldTypes.forEach(adType => {
        if ( adType.active &&
          !adType.debtAllowed ) {
          flag = false;
        }
      });
    }

    return flag;
  }

  submit(): void {

    this.classfieldService.updateAdType(
        this.classfieldId,
        this.classfieldTypes
          .map(classfieldType => { return {
              ...classfieldType,
              active: this.classfieldClassfieldTypes
                .find(classfieldClassfieldType => classfieldClassfieldType.adTypeId === classfieldType.id).active
            }; })
          .filter(classfieldType => classfieldType.active && !classfieldType.expiration)
          .map(classfieldType => classfieldType.id)
      ).subscribe( () => {

        this.toastService.showSuccess('Promene su uspešno dodate!');
        this.store.dispatch(updateCredit({ credit: this.authUser.credit - this.totalSum }));
        this.classfieldClassfieldTypes.forEach(adType => {
          if ( adType.active && !adType.expiration ) {
            adType.expiration = new Date();
          }
        });

        this.dialogController.dismiss(null, 'submit');

      },
      error => this.toastService.showError(error.error.message));
  }

  closePopover(): void {
    this.dialogController.dismiss();
  }
}
