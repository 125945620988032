import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Keyboard } from '@capacitor/keyboard';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs/operators';
import { logout } from 'src/app/auth/auth.actions';
import { ClassfieldService } from '../../../home/classfield/classfield.service';
import { AppState } from '../../../reducers';
import { Ad } from '../../models/Ad/Ad';
import { PlatformService } from '../../services/platform.service';
import {Rating} from '../../models/Rating/Rating';
import {RatingService} from '../../services/rating.service';
import {PositiveAndNegativeUserRating} from '../../models/Rating/PositiveAndNegativeUserRating';
import {YesNoDialogComponent} from '../yes-no-dialog/yes-no-dialog.component';
import {PopoverController} from '@ionic/angular';
import { SortService } from '../../services/sort.service';
import SortEnum from '../../enums/sort.enum';

@Component({
             selector:    'app-user-detail',
             templateUrl: './user-detail.component.html',
             styleUrls:   ['./user-detail.component.scss'],
             animations:  [
               trigger('slideDownUp', [
                 transition(':enter', [
                   style({height: 0}),
                   animate(350, style({height: 100}))
                 ]),
                 transition(':leave', [animate(250, style({height: 0}))]),
               ]),
             ]
           })
export class UserDetailComponent implements OnInit {

  @Input() isAuthUser   = false;
  @Input() user;
  @Input() refresh      = new UntypedFormControl(false);
  @Input() refreshEvent = null;

  @Output() finishRefresh: EventEmitter<any> = new EventEmitter<any>();

  ads: Ad[]          = [];
  ratings: Rating[]  = [];

  menu               = false;
  activeAds: UntypedFormControl;
  nextPage           = 0;
  searchWord: string = null;
  sort: SortEnum;
  isLoading          = 0;
  firstLoad: boolean = true;
  listEnd:   boolean = false;

  fetchClassfieldOnly = true;
  positiveAndNegativeRating: PositiveAndNegativeUserRating;
  classfieldCount = 0;

  yesNoDialog: any;

  showData = false;

  @Input() reloadClassfields = new UntypedFormControl(null );

  constructor(public platformService: PlatformService,
              private store: Store<AppState>,
              private classfieldService: ClassfieldService,
              private ratingService: RatingService,
              private popoverController: PopoverController,
              private sortService: SortService) {
  }

  ngOnInit() {
    this.activeAds = new UntypedFormControl(true);
    this.subscribeOnActiveAdsChange();
    this.subscribeOnRefresh();
    this.fetchUserAds();
    this.subscribeOnReloadClassfields();
  }

  subscribeOnReloadClassfields() {

    this.reloadClassfields.valueChanges.subscribe(value => {
      if (value && this.ads.length > 0) {
        this.fetchUserAds();
      }
    });
  }

  ionViewWillEnter() {
    this.menu = false;
  }

  fetchUserAds(loadMore  = false,
               userId    = this.user.id,
               pageIndex = this.nextPage,
               pageSize  = 10,
               active    = this.activeAds.value,
               keyword   = this.searchWord,
               orderBy   = this.sortService.getSort(this.sort).orderBy,
               direction = this.sortService.getSort(this.sort).direction): void {

    if (!loadMore) {
      this.nextPage = 0;
    }

    if(this.firstLoad){
      this.isLoading++;
    }

    if (this.isAuthUser) {
      this.fetchAuthUserAds(loadMore, userId, pageIndex, pageSize, active, keyword, orderBy, direction);
    }
    else {
      this.fetchAnotherUserAds(loadMore, userId, pageIndex, pageSize, active, keyword, orderBy, direction);
    }

    this.fetchPositiveAndNegativeUserRating();
  }

  fetchAuthUserAds(loadMore  = false,
                   userId    = this.user.id,
                   pageIndex = this.nextPage,
                   pageSize  = 10,
                   active    = this.activeAds.value,
                   keyword   = this.searchWord,
                   orderBy   = this.sortService.getSort(this.sort).orderBy,
                   direction = this.sortService.getSort(this.sort).direction) {


    this.classfieldService.userAllAds(userId, this.nextPage, pageSize, active, keyword, orderBy, direction)
      .pipe(finalize(() => {

        if(this.firstLoad) {
          this.firstLoad = false;
          this.isLoading--;
        }
      }))
      .subscribe(response => {

        response.items.length === 0 ? this.listEnd = true : this.listEnd = false;
        this.classfieldCount = response.total;
        if (response.items.length === 0 && loadMore && this.nextPage > 0) {

          this.nextPage--;
        } else {

          if( loadMore ){
            this.ads.push(...response.items);
          } else{
            this.ads = response.items;
          }
        }

      }, error => {
        console.log(error);
      });
  }

  fetchAnotherUserAds(loadMore  = false,
                      userId    = this.user.id,
                      pageIndex = this.nextPage,
                      pageSize  = 10,
                      active    = this.activeAds.value,
                      keyword   = this.searchWord,
                      orderBy   = this.sortService.getSort(this.sort).orderBy,
                      direction = this.sortService.getSort(this.sort).direction) {

    this.classfieldService.userAds(userId, this.nextPage, pageSize, active, keyword, orderBy, direction)
      .pipe(finalize(() => {

        if (this.firstLoad) {
          this.firstLoad = false;
          this.isLoading--;
        }
      }))
      .subscribe(response => {
        this.classfieldCount = response.total;
        response.items.length === 0 ? this.listEnd = true : this.listEnd = false;

        if (response.items.length === 0 && loadMore && this.nextPage > 0) {
          this.nextPage--;
        } else {
          if( loadMore ) {
            this.ads.push(...response.items);
          } else {
            this.ads = response.items;
          }
        }

      }, error => {
        console.log(error);
      });
  }

  fetchUserRatings( loadMore  = false,
                    pageIndex = this.nextPage,
                    pageSize  = 10 ): void {

    if (!loadMore) {
      this.nextPage = 0;
    }

    if (this.firstLoad) {
      this.isLoading++;

    }

    this.ratingService.ratings(this.nextPage, pageSize, this.user.id)
      .pipe(finalize(() => {

        if (this.firstLoad) {
          this.firstLoad = false;
          this.isLoading--;
        }
      }))
      .subscribe(response => {

        this.classfieldCount = response.total;

        response.items.length === 0 ? this.listEnd = true : this.listEnd = false;

        if (response.items.length === 0 && loadMore && this.nextPage > 0) {

          this.nextPage--;
        } else {

          if ( loadMore ) {
            this.ratings.push(...response.items);
          } else {
            this.ratings = response.items;
          }
        }

      }, error => {
        console.log(error);
      });
  }

  fetchPositiveAndNegativeUserRating(): void {

    this.ratingService.getPositiveAndNegativeUserRating(this.user.id).subscribe(
      response => {
        this.positiveAndNegativeRating = response;
        this.showData = true;

      },
      error => {
        console.log(error);
      }
    );
  }

  changeDataToFetch(fetchClassfield: boolean) {

    this.firstLoad = true;
    this.isLoading = 0;
    if( fetchClassfield ){

      this.fetchClassfieldOnly = true;
      this.fetchUserAds();
    } else {

      this.fetchClassfieldOnly = false;
      this.fetchUserRatings();
    }
  }

  subscribeOnActiveAdsChange(): void {

    this.activeAds.valueChanges.subscribe(value => {
      this.fetchUserAds();
    });
  }

  subscribeOnRefresh() {

    this.refresh.valueChanges.subscribe(value => {
      if (value) {
        this.refreshAds();
      }
    });
  }

  refreshAds() {

    this.nextPage = 0;
    this.fetchUserAds(false);
    this.finishRefresh.emit(this.refreshEvent);
  }

  search(event: any) {

    this.searchWord = event.detail?.value ? event.detail.value : null;
    Keyboard.hide();
    this.fetchUserAds();
  }

  async openYesNoDialog() {
    this.yesNoDialog = await this.popoverController.create({
      component: YesNoDialogComponent,

      componentProps: {dialogText: `Da li ste sigurni?`, buttonText: ['Da', 'Ne']}
    });

    this.yesNoDialog.onDidDismiss().then((response: any) => {
      if (response.role === 'decision' && response.data) {
        this.logout();
      }
    });

    return await this.yesNoDialog.present();
  }

  logout() {

    this.store.dispatch(logout(this.user));
  }

  showMenu() {

    this.menu = !this.menu;
  }

  onSortData(event) {

    this.sort = event;
    this.fetchUserAds();
  }

  onLoadData() {

    this.nextPage++;
    this.fetchUserAds(true);
  }
}
