import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';

import {IonInput, ModalController, ToastController} from '@ionic/angular';

import { finalize } from 'rxjs/operators';

import { AuthService } from '../../../../auth/auth.service';
import { InvisibleReCaptchaComponent } from '../../invisible-recaptcha/invisible-recaptcha.component';
import { IpAddressService } from '../../../services/ip-address.service';
import { ForgotPassword } from '../../../models/Auth/ForgotPassword';

@Component({
  selector:    'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls:   ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  @Input() verifier: string;

  @ViewChild(InvisibleReCaptchaComponent) invisibleReCaptcha: InvisibleReCaptchaComponent;

  @ViewChild('slides') slides;

  selectedIonInput: IonInput;

  forgotPasswordForm: UntypedFormControl;
  resetPasswordForm: UntypedFormGroup;
  isLoading = 0;

  formActive = false;
  submitted = false;

  sliderOption = {
    slidesPerView: 1,
    loop: false,
    initialSlide: 0,
    speed: 400,
    allowTouchMove: false,
    centeredSlides: false,
  };

  ipAddress: string;

  phoneNumberRegex = /^(06){1}[0-9]{1}(-|\/)?[0-9]{1}((-)?[0-9](-)?){4,5}[0-9]{1}$/;

  constructor(private modalController: ModalController,
              private authService: AuthService,
              private toastController: ToastController,
              // private ipAddressService: IpAddressService,
              private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
   this.initialize();
  }

  initialize(): void {
    this.buildForgotPasswordForm();
  }

  buildForgotPasswordForm(): void {
    this.forgotPasswordForm = new UntypedFormControl(this.verifier, [
      Validators.required,
      this.checkVerifier
    ]);
  }

  buildResetPasswordForm(): void {

    this.resetPasswordForm = this.formBuilder.group({
        token:             [ '' , [ Validators.required,  Validators.minLength(6), Validators.maxLength(6) ] ],
        password:          [ '' , [ Validators.required,  Validators.minLength(8) ] ],
        confirmedPassword: [ '' , [ Validators.required] ]
      },
      {
        validators: [
          this.authService.passwordsMatch
        ]
      });

    this.formActive = true;
  }

  onSendForgotPasswordRequestClick(): void {

    this.invisibleReCaptcha.executeReCaptcha();
  }

  setCurrentInput(selectedIonInput: IonInput): void {

    this.selectedIonInput = selectedIonInput;
  }

  handleReCaptchaResponse( captchaResponse: boolean ): void {

    if (captchaResponse) {
      this.sendForgotPasswordRequest();
    } else {
      this.notifyUser('Došlo je do greške prlikom oporavka lozinke.', 'danger');
    }
  }

  sendForgotPasswordRequest() {

    if (this.phoneNumberRegex.test(this.forgotPasswordForm.value)) {
      this.removeSpecialCharacters();
    }

    // this.ipAddressService.getIp().subscribe(result => {
    //   this.ipAddress = result;
    //   this.forgotPasswordRequest();
    // });
    this.forgotPasswordRequest();
  }

  forgotPasswordRequest() {
    this.isLoading++;


    this.authService.forgotPassword({verifier: this.forgotPasswordForm.value} as ForgotPassword)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(async ( response ) => {
        if (response.verificationType === 'phoneNumber') {
          this.buildResetPasswordForm();
          await this.slides.slideNext();
        } else {
          await this.notifyUser('Link za oporavak lozinke je poslat.', 'success');
          this.closeModal();
          this.resetForms();
        }
      }, async error => {

        await this.notifyUser(error.error.message, 'danger');
      });
  }

  sendPasswordResetData(): void {

    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }

    const resetPassword = this.resetPasswordForm.value;
    resetPassword.phoneNumber = this.forgotPasswordForm.value;

    this.isLoading++;
    this.authService.resetPassword(resetPassword)
      .pipe(finalize(() => this.isLoading--))
      .subscribe(async ( ) => {

          await this.notifyUser('Lozinka je uspešno izmenjena.', 'success');
          this.closeModal();
          this.resetForms();
      }, async error => {

        const errorMessage = JSON.parse(error.error).message;
        await this.notifyUser(errorMessage, 'danger');
      });
  }

  removeSpecialCharacters(): void {
    const phoneNumber = this.forgotPasswordForm.value.toString().replace(/[\/,-]/g, '');
    this.forgotPasswordForm.setValue(phoneNumber);
    this.forgotPasswordForm.updateValueAndValidity();
  }

  checkVerifier(form: UntypedFormControl): ValidationErrors | null {

    const verifier = form.value;
    // if (verifier && verifier === '' ||  /^(06){1}[0-9]{1}(-|\/)?[0-9]{1}((-)?[0-9](-)?){4,5}[0-9]{1}$/.test(verifier)) {
    //   return;
    // }

    if (verifier && verifier.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      return;
    }

    return { emailNotValid: true };
  }

  showPassword(password) {

    password.type = password.type === 'password' ? 'text' : 'password';
  }

  allowNumbersOnly(event) {

    const inp = String.fromCharCode(event.keyCode);

    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  resetForms() {
    this.forgotPasswordForm.reset();
    if (this.resetPasswordForm) {
      this.resetPasswordForm.reset();
    }
  }

  closeModal() {

    this.modalController.dismiss({
      dismissed: true
    });
  }

  private async notifyUser(message: string, color: string) {

    const toast = await this.toastController.create({
      message,
      duration:      2000,
      position:      'top',
      color,
      keyboardClose: true,
      animated:      true
    });

    await toast.present();

    if (color === 'danger'){
      this.selectedIonInput.setFocus();
      toast.onDidDismiss().then(() => {
        this.selectedIonInput.setFocus();
      });
    }
    return toast;
  }
}
