import {Inject, Injectable} from '@angular/core';
import { Platform } from '@ionic/angular';

import { PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Injectable({providedIn: 'root'})

export class PlatformService {

  isServer = true;

  // tslint:disable-next-line:ban-types
  constructor(private plt: Platform, @Inject(PLATFORM_ID) platformId: Object) {
    this.isServer = isPlatformServer(platformId);
  }

  get platform() {
    return;
    // return this.plt;
  }

  isDesktop() {
    // return true;
    return this.plt.is('desktop');
  }

  isMobile() {
    // return false;
    return this.plt.is('mobile');
  }
}
