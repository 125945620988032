import { createAction, props } from '@ngrx/store';
import { User } from '../_shared/models/User';
import {IonInput} from "@ionic/angular";
import {Register} from "../_shared/models/Auth/Register";

export const authInit = createAction(
  '[Auth] User Init'
);

export const getCurrentUser = createAction(
  '[Auth] After user login or on init'
);

export const login = createAction(
  '[Login Page] User Login',
  props<{ user: { verifier: string, password: string } }>()
);

export const loginFacebook = createAction(
  '[Login Page] User Facebook Login',
  props<{ accessToken: string }>()
);

export const loginSuccess = createAction(
  '[Auth] User Login Success',
  props<{ user: User }>()
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: any }>()
);

export const register = createAction(
  '[Register Page] User Register',
  props<{ user: Register, isLegal: boolean }>()
);

export const registerSuccess = createAction(
  '[Register] User Register Success'
);

export const registerFailure = createAction(
  '[Register] Register Failure',
  props<{ error: any }>()
);

export const resetPassword = createAction(
  '[Reset Password Page] User Reset Password',
  props<{ user: { token: string, email: string, password: string, passwordConfirmation: string } }>()
);

export const resetPasswordSuccess = createAction(
  '[Reset Password] User Reset Password Success',
  props<{ user: User }>()
);

export const resetPasswordFailure = createAction(
  '[Reset Password] Reset Password Failure',
  props<{ error: any }>()
);

export const logout = createAction(
  '[Auth] Logout',
  props<{ user: User }>()
);

export const logoutSuccess = createAction(
  '[Auth] Logout Success'
);

export const updateSuccess = createAction(
  '[Auth] User Edit Success',
  props<{ user: User }>()
);

export const activateAccountByEmail = createAction(
  '[Auth] Account Activate By Email'
);

export const activateAccountByPhoneNumber = createAction(
  '[Auth] Account Activate By Phone'
);

export const clearAuth = createAction(
  '[Auth] Clear Auth'
);

export const getNotifications = createAction(
  '[Notification] Get Notifications'
);

export const getNotificationsSuccess = createAction(
  '[Notifications] Get Notifications Success',
    props<{ chatIds: number[] }>()
);

export const getNotificationsFailure = createAction(
  '[Notifications] Get Notifications Failure',
    props<{ error: any }>()
);

export const notificationsMarkChatAsRead = createAction(
  '[Notification] Mark Chat As Read',
  props<{ chatId: number }>()
);

export const updateCredit = createAction(
  '[User] Update Credit',
  props<{ credit: number }>()
);
