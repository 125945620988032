import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {loginFacebook, register, registerFailure} from 'src/app/auth/auth.actions';
import { AppState } from '../../../../reducers';
import { PlatformService } from '../../../services/platform.service';
import { ToastService } from '../../../services/toast.service';
import { InvisibleReCaptchaComponent } from '../../invisible-recaptcha/invisible-recaptcha.component';
import { Location } from '../../../models/Location';
import {locations, locationsLoading} from '../../../store/location/selectors/location.selectors';
import { AuthService } from '../../../../auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { IonInput } from '@ionic/angular';
import { IpAddressService } from '../../../services/ip-address.service';
import { Register } from '../../../models/Auth/Register';
import {FacebookLogin} from "../../../models/Auth/FacebookLogin";
import {FacebookService} from "../../../services/facebook.service";
import {Loading} from "../../../models/Loading";
import {locationLoad} from "../../../store/location/actions/location.actions";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-register-component',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {

  @ViewChild(InvisibleReCaptchaComponent) invisibleReCaptcha: InvisibleReCaptchaComponent;

  physicallyPersonForm: UntypedFormGroup;
  legalPersonForm: UntypedFormGroup;
  buildPhysicallyPerson = false;
  buildLegalPerson = false;
  submittedPhysicallyPerson = false;
  submittedLegalPerson = false;


  locations: Location[];

  isAuthenticatingIndicatorPhysicallyPerson = false;
  isAuthenticatingIndicatorLegalPerson = false;

  phoneNumberRegex = /^(06){1}[0-9]{1}(-|\/)?[0-9]{1}((-)?[0-9](-)?){4,5}[0-9]{1}$/;

  subscriptions: Subscription[] = [];

  @ViewChild('verifier') verifier: IonInput;

  constructor(public platformService: PlatformService,
              private formBuilder: UntypedFormBuilder,
              private store: Store<AppState>,
              private authService: AuthService,
              private facebookService: FacebookService,
              private toastService: ToastService,
              private router: Router,
              private route: ActivatedRoute,
              private actionsSubject: ActionsSubject) { }

  /**
   * Start component.
   *
   * @return void
   */
  ngOnInit(): void {

    this.buildPhysicallyPersonRegisterForm();
    this.buildLegalPersonRegisterForm();
    this.fetchLocations();
    this.subscribeToRegisterFailure();
  }

  /**
   * Build register form.
   *
   * @return void
   */
  buildPhysicallyPersonRegisterForm(): void {

    this.physicallyPersonForm = this.formBuilder.group({
      verifier:          [ '', [ Validators.required]],
      password:          [ '', [ Validators.required, Validators.minLength(8) ]],
      confirmedPassword: [ '', [ Validators.required ]],
      fullName:          [ '', [ Validators.required ]],
      locationId:        [ '', [ Validators.required ]],
      legalRights: [ false ]
    }, {
      validators: [
        this.checkVerifier,
        this.authService.passwordsMatch,
        this.acceptLegalRights
      ]
    });

    this.buildPhysicallyPerson = true;
  }

  buildLegalPersonRegisterForm(): void {
    this.legalPersonForm = this.formBuilder.group({
      verifier:          [ '', [ Validators.required]],
      password:          [ '', [ Validators.required, Validators.minLength(8) ]],
      confirmedPassword: [ '', [ Validators.required ]],
      companyName:          [ '', [ Validators.required ]],
      companyNumber: ['', [Validators.required]],
      pib: ['', [Validators.required]],
      locationId:        [ '', [ Validators.required ]],
      legalRights: [ false ]
    }, {
      validators: [
        this.checkVerifier,
        this.authService.passwordsMatch,
        this.acceptLegalRights
      ]
    });

    this.buildLegalPerson = true;
  }

  /**
   * Fetch locations
   *
   * @return Void
   */

  fetchLocations(): void {

    this.subscriptions.push(this.store
      .pipe(select( locationsLoading ))
      .subscribe( loading => {

        if (loading === Loading.NOT_LOADED) {
          this.store.dispatch(locationLoad());
        }

        if (loading === Loading.SUCCESS) {
          this.subscriptions.push(
            this.store
              .pipe(select(locations))
              .subscribe(locations => {
                this.locations = locations;
              })
          );
        }
      }));
  }

  /**
   * Check if verifier is valid
   *
   * @param registerForm Register form data
   *
   * @return ValidationErrors | null
   */

  checkVerifier(form: UntypedFormGroup): ValidationErrors | null {

    const verifier = form.controls.verifier.value;
    // if (verifier && verifier === '' ||  /^(06){1}[0-9]{1}(-|\/)?[0-9]{1}((-)?[0-9](-)?){4,5}[0-9]{1}$/.test(verifier)) {
    //   return;
    // }

    if (verifier && verifier.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      return;
    }

    return { verifierNotValid: true };
  }

  /**
   * Check if legal rights are accepted.
   *
   * @param registerForm Register form data
   *
   * @return ValidationErrors | null
   */
  acceptLegalRights(registerForm: UntypedFormGroup): ValidationErrors | null {

    const legalRights = registerForm.controls.legalRights.value;

    return (legalRights === true ? null : {legalRightsNotAccepted: true});
  }

  /**
   * Change input type to display password.
   *
   * @param password Input field
   */
  showPassword(password) {

    password.type = password.type === 'password' ? 'text' : 'password';
  }

  /**
   * If register button is clicked, reCaptcha service will be called.
   *
   * @return void
   */
  registerPhysicallyPerson(): void {

    this.submittedPhysicallyPerson = true;
    if (!this.physicallyPersonForm.valid) {
      return;
    }

    this.invisibleReCaptcha.executeReCaptcha();
  }

  registerLegalPerson(): void {

    this.submittedLegalPerson = true;
    if (!this.legalPersonForm.valid) {
      return;
    }

    this.invisibleReCaptcha.executeReCaptcha();
  }

  /**
   * When reCaptcha emit response, this function will handle it
   *
   * @param captchaResponse
   *
   * @return void
   */

  handleReCaptchaResponse(captchaResponse: boolean) {
    if (captchaResponse) {
      if (this.submittedPhysicallyPerson){
        this.registerNewPhysicallyUser();
      }
      else {
        this.registerNewLegalUser();
      }
    }
    else
    {
      this.toastService.showError('Greška prilikom registrovanja!');
    }
  }

  /**
   * If register form is valid and reCaptcha emit positive value, dispatch register.
   *
   * @return void
   */

  registerNewPhysicallyUser(): void {

    // if (this.phoneNumberRegex.test(this.legalPersonForm.controls.verifier.value)) {
    //   this.removeLegalPersonSpecialCharacters();
    // }

    this.isAuthenticatingIndicatorPhysicallyPerson  = true;



    // this.ipAddressService.getIp().subscribe(result => {
    //   this.dispatchRegister(result);
    // });
    this.dispatchPhysicallyPersonRegister();
  }


  registerNewLegalUser(): void {

    // if (this.phoneNumberRegex.test(this.legalPersonForm.controls.verifier.value)) {
    //   this.removeLegalPersonSpecialCharacters();
    // }

    this.isAuthenticatingIndicatorLegalPerson = true;



    // this.ipAddressService.getIp().subscribe(result => {
    //   this.dispatchRegister(result);
    // });
    this.dispatchLegalPersonRegister();
  }



  dispatchPhysicallyPersonRegister() {
    const user = this.physicallyPersonForm.value as Register;
    // user.ip = ip;

    this.store.dispatch(register({ user, isLegal: false }));
  }


  dispatchLegalPersonRegister() {
    const user = this.legalPersonForm.value as Register;
    // user.ip = ip;

    this.store.dispatch(register({ user, isLegal: true }));
  }

  /**
   * Removes slash and lines from phone number.
   *
   * @return void
   */

  removePhysicallyPersonSpecialCharacters(): void {

    const phoneNumber = this.physicallyPersonForm.controls.verifier.value.toString().replace(/[\/,-]/g, '');
    this.physicallyPersonForm.controls.verifier.setValue( phoneNumber );
    this.physicallyPersonForm.controls.verifier.updateValueAndValidity();
  }

  removeLegalPersonSpecialCharacters(): void {

    const phoneNumber = this.legalPersonForm.controls.verifier.value.toString().replace(/[\/,-]/g, '');
    this.legalPersonForm.controls.verifier.setValue( phoneNumber );
    this.legalPersonForm.controls.verifier.updateValueAndValidity();
  }

  /**
   * Navigate to login page.
   *
   * @return void
   */

  navigateToLoginPage(): void {
    if (this.route.snapshot.queryParamMap.has('navigate_to')){
      this.router.navigate(['/auth/prijava'], {queryParams: {navigate_to: this.route.snapshot.queryParamMap.get('navigate_to')}});
    } else {
      this.router.navigate(['/auth/prijava'], {replaceUrl: true});
    }
  }

  subscribeToRegisterFailure() {
    this.actionsSubject.pipe(ofType(registerFailure.type))
      .subscribe(async (data) => {
        const toast = await this.toastService.showError(data['error']['message']);
        this.isAuthenticatingIndicatorPhysicallyPerson  = false;
        this.isAuthenticatingIndicatorLegalPerson  = false;
        this.verifier.setFocus();
        toast.onDidDismiss().then(() => {
          this.verifier.setFocus();
        });
      });
  }

  /**
   * End component.
   *
   * @return void
   */

  ngOnDestroy(): void {

    this.physicallyPersonForm.reset();
    this.legalPersonForm.reset();
    this.subscriptions.map(s => s.unsubscribe());
    this.subscriptions = [];
  }

  registerWithFacebook() {
    this.facebookService.showLoginModal(this.dispatchFacebookLoginAction());
  }

  dispatchFacebookLoginAction() {
    return (response) => {
      this.store.dispatch(loginFacebook({ accessToken: response.authToken }));
    };
  }

  physicallyPersonOnLocationPicked(locationId: number) {
    this.physicallyPersonForm.controls.locationId.setValue(locationId);
  }

  legalPersonOnLocationPicked(locationId: number) {
    this.legalPersonForm.controls.locationId.setValue(locationId);
  }
}
