import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent} from './landing-page.component';
import {GuestGuard} from '../_shared/guards/guest.guard';

const routes: Routes = [
  { path: 'landing-page', component: LandingPageComponent, canActivate: [GuestGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LandingPageRoutingModule{

}
