import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { Image } from '../models/Image';

@Injectable({
              providedIn: 'root'
            })
export class FileService extends BaseApiService {

  public send(file): Observable<Image> {

    return this.http.post<Image>(`${this.apiUrl}/api/files/picture`, file);
  }

  public delete(files: number[]): Observable<any> {

    const options = {
      body: {
        pictureIds: files
      },
    };
    return this.http.delete(`${this.apiUrl}/api/files/picture`, options);
  }

  public readPicture(image: Image, thumbnail = false, detail = false) {

    const path = thumbnail && image.thumbnailPath ?
      image.thumbnailPath : detail ? image.detailPath : image.path;

    return this.apiUrl + '/' + path;
  }

}
