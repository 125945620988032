import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthEffects } from './auth-effects.service';
import { AuthResolver } from './auth.resolver';
import * as fromAuth from './reducers';
import { authReducer } from './reducers';
import { AuthRoutingModule } from './auth-routing.module';
import { IonicModule } from '@ionic/angular';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ConfirmPhoneNumberComponent } from './confirm-phone-number/confirm-phone-number.component';
import { SharedModule } from '../_shared/shared.module';

@NgModule({
            declarations: [
              ConfirmEmailComponent,
              ConfirmPhoneNumberComponent
            ],
            imports:      [
              CommonModule,
              IonicModule,
              AuthRoutingModule,
              SharedModule,
              StoreModule.forFeature(fromAuth.authFeatureKey, authReducer),
              EffectsModule.forFeature([AuthEffects]),
              ReactiveFormsModule
            ],
            providers:    [
              AuthResolver
            ]
          })
export class AuthModule {
}
