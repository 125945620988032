import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { PaginationResponse } from '../models/PaginationResponse';
import { Category } from '../models/Category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends BaseApiService {

  public index(pageIndex: number, pageSize: number, keyword: string = '', orderBy: string = 'id', direction: string = 'ASC'): Observable<PaginationResponse<Category>> {

    let params = new HttpParams();
    params     = (pageIndex ? params.set('pageIndex', JSON.stringify(pageIndex)) : params);
    params     = (pageSize ? params.set('pageSize', JSON.stringify(pageSize)) : params);
    params     = (keyword ? params.set('keyword', JSON.stringify(keyword)) : params);
    params     = (orderBy ? params.set('orderBy', JSON.stringify(orderBy)) : params);
    params     = (direction ? params.set('direction', JSON.stringify(direction)) : params);

    return this.http.get<PaginationResponse<Category>>(`${this.apiUrl}/api/categories`, {params});
  }

  public categoryTree(parameter, isSlug: boolean): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/categories/category-tree?${ isSlug ? 'childSlug' : 'childId' }=${ parameter }`);
  }

  public categories( categoryId?: number ): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/categories/all-children?parentId=${categoryId ? categoryId : 0}`);
  }

  public showSlug(categorySlug: string): Observable<Category> {
    return this.http.get<Category>(`${this.apiUrl}/api/categories/slug/${categorySlug}`);
  }

  public customFields( categoryId: number | string): Observable<any> {
    return  this.http.get(`${this.apiUrl}/api/categories/${categoryId}/custom-fields`);
  }

  public customFieldsByCategorySlug( categorySlug: string): Observable<any> {
    return  this.http.get(`${this.apiUrl}/api/categories/slug/${categorySlug}/custom-fields`);
  }
}
