import { Component, OnInit } from '@angular/core';
import { PlatformService } from '../../../services/platform.service';

@Component({
  selector: 'app-classfield-skeleton-loading',
  templateUrl: './classfield-skeleton-loading.component.html',
  styleUrls: ['./classfield-skeleton-loading.component.scss'],
})
export class ClassfieldSkeletonLoadingComponent implements OnInit {

  constructor(public platformService: PlatformService) { }

  ngOnInit() {}

}
