import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IonInput, IonItem, PopoverController} from '@ionic/angular';
import { SearchableSelectPopoverComponent } from '../searchable-select-popover/searchable-select-popover.component';
import { Category } from '../../../models/Category';
import { UntypedFormControl } from '@angular/forms';
import {fromEvent} from 'rxjs';

@Component({
  selector: 'app-company-searchable-select',
  templateUrl: './company-searchable-select.component.html',
  styleUrls: ['./company-searchable-select.component.scss'],
})
export class CompanySearchableSelectComponent implements AfterViewInit {

  @Input() isRoot: boolean;
  @Input() categories: Category[];
  @Input() showCategoryValidationError = new UntypedFormControl(false);
  @Output() categorySelected: EventEmitter<number> = new EventEmitter<number>();

  selectedCategory: Category = undefined;
  showValidationMessage = false;

  @ViewChild('ionItemContainer',  {static: false}) ionItemContainer: any;

  isMobile = false;

  constructor(private popoverController: PopoverController) { }

  ngAfterViewInit(): void {

    this.isMobile = window.innerWidth < 1200 ? true : false;

    if ( !this.isRoot && !this.valueSelected() ) {
      if ( this.isMobile ) {
        this.openSelect();
      } else {
        this.ionItemContainer.nativeElement.click();
      }
    }

    this.subscribeOnReset();
  }

  subscribeOnReset(): void {

    this.showCategoryValidationError.valueChanges.subscribe(value => {
      if (value) {
         if (this.getSelectedCategoryName() === 'Izaberi') {
           this.showValidationMessage = true;
         }
      }
    });
  }

  async openSelect($event?: any) {

    const modal = await this.popoverController.create({
      component: SearchableSelectPopoverComponent,
      componentProps: {
        entities: this.categories
      },
      cssClass: 'searchable-select',
      mode: 'ios',
      event: $event
    });

    await modal.present();

    modal.onDidDismiss().then((data) => {

      const category = this.getSelectedCategory();

      if (data.data?.entity && category?.id === data.data.entity.id) {
        return;
      }

      if ( data.data?.entity ) {

        if ( this.isRoot ) {
          this.showValidationMessage = false;
        }

        this.selectedCategory = data.data.entity;
        this.categorySelected.emit(this.selectedCategory.id);
      }

      if (data.role === 'backdrop' && this.getSelectedCategoryName() === 'Izaberi') {
        this.showValidationMessage = true;
      }
    });
  }

  onSelectCategoryPipeline( selectedCategoryId ) {
    this.categorySelected.emit( selectedCategoryId );
  }

  getSelectedCategoryName(): string {
    const category = this.getSelectedCategory();
    return category ? category.name : 'Izaberi';
  }

  getSelectedCategory(): Category|null {
    return this.categories.find(c => c.isSelected === true);
  }

  valueSelected(): boolean {
    return !!this.categories.find(c => c.isSelected === true);
  }
}
