import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Chat } from '../../../models/Chat';

@Component({
  selector: 'app-chat-list-holder',
  templateUrl: './chat-list-holder.component.html',
  styleUrls: ['./chat-list-holder.component.scss'],
})

export class ChatListHolderComponent implements OnInit{

  @Input()  chats: Chat[]                  = [];
  @Input()  authUserId: number             = undefined;
  @Input()  owned                          = false;
  @Output() loadData: EventEmitter<void>   = new EventEmitter<void>();
  @Output() sortData: EventEmitter<string> = new EventEmitter<string>();
  @Input()  isLoading;
  @Input()  listEnd                        = false;

  sortControl: UntypedFormControl;

  ngOnInit(): void {

    this.sortControl = new UntypedFormControl('id');
    this.sortControl.valueChanges.subscribe(value => {
      this.sortData.emit(value);
    });
  }

  onLoadData(event) {

    this.loadData.emit();
    event.target.complete();
  }
}
