import { Component, OnInit } from '@angular/core';
import {isLoggedIn} from '../auth/auth.selectors';
import {AuthService} from '../auth/auth.service';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  protected  isLoggedIn = false;


  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.authService.isLoggedIn()
      .subscribe((res: any) => {
        this.isLoggedIn = !!res;
      });
  }

}
