import { Component, Input } from '@angular/core';

import { Message } from '../../../models/Message';

import { DateTime } from 'luxon';
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  @Input() message: Message = undefined;
  @Input() messageOwner: boolean = undefined;

  constructor() { }

  getTimeFromDate(createdAt) {

    return DateTime.fromISO(createdAt).toFormat('h.mm a');
  }
}
