import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PopoverController } from '@ionic/angular';

import {filter} from 'rxjs/operators';

import {select, Store} from '@ngrx/store';

import { logout } from 'src/app/auth/auth.actions';

import {authUser, authUserAndUnreadChats, unreadChatsIds} from 'src/app/auth/auth.selectors';
import { AppState } from '../../../../reducers';

import { User } from '../../../models/User';

import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-desktop-side-menu',
  templateUrl: './desktop-side-menu.component.html',
  styleUrls: ['./desktop-side-menu.component.scss'],
})
export class DesktopSideMenuComponent implements OnInit {

  authUser: User;
  unreadChats = [];
  yesNoDialog: any;
  admin = false;

  constructor(private store: Store<AppState>,
              private router: Router,
              private popoverController: PopoverController) { }

  ngOnInit() {

    this.admin = localStorage.getItem('role')?.includes('admin') ? true : false;
    this.getAuthenticatedUser();
  }

  getAuthenticatedUser(): void {

    this.store
      .pipe(select(authUserAndUnreadChats))
      .subscribe(response => {
        this.authUser = response.authUser;
        this.unreadChats = response.unreadChats;
      });
  }


  goTo( route: string ): void {
    this.router.navigate([route], { replaceUrl: true });
  }

  async openYesNoDialog() {
    this.yesNoDialog = await this.popoverController.create({
      component: YesNoDialogComponent,

      componentProps: {dialogText: `Da li ste sigurni?`, buttonText: ['Da', 'Ne']}
    });

    this.yesNoDialog.onDidDismiss().then((response: any) => {
      if (response.role === 'decision' && response.data) {
        this.logout();
      }
    });

    return await this.yesNoDialog.present();
  }

  logout(): void {

    this.store.dispatch(logout({user: this.authUser}));
    this.router.navigate(['/auth/prijava'],{replaceUrl: true});
  }
}
