import { CategoryService } from './../../../services/category.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CategoryActions } from '../action-types';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LogService } from '../../../services/log.service';
import { CategoryTreeService } from '../../../services/category-tree.service';
import {ClassfieldService} from '../../../../home/classfield/classfield.service';
import { TITLE_SUFFIX } from '../../../constants';
import { TitleAndDescriptionService } from '../../../services/title-and-description.service';

@Injectable()
export class CategoryEffects {

  getTopCategories$ = createEffect(() => this.actions$
    .pipe(
      ofType(CategoryActions.topCategories),
      mergeMap(() => {
        return  this.categoryService.categories(null)
          .pipe(
            map(response => {
              return CategoryActions.topCategoriesSuccess({categories: response});
            }),
            catchError((err) => {
              return of(CategoryActions.topCategoriesFailure({error: err.error}));
            })
          );
      })
    )
  );

  getSubcategories$ = createEffect(() => this.actions$
    .pipe(
      ofType(CategoryActions.categories),
      mergeMap((actions) => {
        return this.categoryService.categoryTree(actions.parameter, actions.isSlug)
          .pipe(
            map(response => {
              if ( actions.isSlug ){
                const category = this.categoryTreeService.findCategoryBySlug(response, actions.parameter);
                this.titleAndDescriptionService.setTitle(category.title);
                this.titleAndDescriptionService.setDescription(category.description);
                return CategoryActions.categoryLog({
                  categories: response,
                  slug: actions.parameter
                });
              } else {
                return CategoryActions.categoriesSuccess(
                  {categories: response, openCategories: true, slug: actions.isSlug ? actions.parameter : null});
              }
            }),
            catchError((err) => {
              return of(CategoryActions.categoriesFailure({error: err.error}));
            })
          );
      })
    )
  );

  createCetegoryLog$ = createEffect(() => this.actions$.pipe(
    ofType(CategoryActions.categoryLog),
    mergeMap( (actions) => {
      return this.logService.categoryPicked(
        {categoryId: this.categoryTreeService.findCategoryBySlug(actions.categories, actions.slug).id, fingerprint: localStorage.getItem('fingerprint')})
        .pipe(
          map( response => {
            return CategoryActions.categoriesSuccess({categories: actions.categories, openCategories: true, slug: actions.slug});
          }),
          catchError((err) => {
            return of(CategoryActions.categoriesFailure({error: err.error}));
          })
        );
    })
  ));

  constructor(private actions$: Actions,
              private categoryService: CategoryService,
              private classfieldService: ClassfieldService,
              private categoryTreeService: CategoryTreeService,
              private titleAndDescriptionService: TitleAndDescriptionService,
              private logService: LogService) {}

}


