import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { IonContent } from '@ionic/angular';

import { Message } from '../../../models/Message';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss'],
})
export class MessageListComponent {

  @Input() messages: Message[] = [];
  @Input() userId: number      = undefined;
  @Input() listEnd             = false;

  @Output() loadData: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('ionContent') ionContent: IonContent;

  scrollbarScss = '  @media(pointer: fine) {\n' +
    '    ::-webkit-scrollbar {\n' +
    '      width: 6px;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-track {\n' +
    '      background: #fff;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-track:hover {\n' +
    '      background: #f7f7f7;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-thumb {\n' +
    '      background: #ccc;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-thumb:hover {\n' +
    '      background: #888\n' +
    '    }\n' +
    '    .inner-scroll {\n' +
    '      scrollbar-width: thin\n' +
    '    }\n' +
    '  }';

  constructor() { }


  loadNewDataWhenTopIsReached(event){

    this.ionContent.getScrollElement().then(el => {

      if (( el.scrollHeight - el.offsetHeight ) - (-1) * event.detail.scrollTop < el.scrollHeight * 0.1 && !this.listEnd ){
        this.loadData.emit();
      }

    });

  }

  scrollBottomOnSendMessage(): void {

     this.ionContent.scrollToTop(200);
  }
}
