import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import CollectionEnum from '../../_shared/enums/collection.enum';
import { Ad } from '../../_shared/models/Ad/Ad';

@Injectable()
export class ClassfieldEntityService extends EntityCollectionServiceBase<Ad> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {

    super(CollectionEnum.Ad, serviceElementsFactory);
  }

}
