import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {App} from '@capacitor/app';
import {Keyboard} from '@capacitor/keyboard';
import {IonContent, ModalController, NavController, Platform, ToastController} from '@ionic/angular';
import {EntityCacheDispatcher} from '@ngrx/data';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {filter, finalize, map, tap} from 'rxjs/operators';
import {
  CategoryPickerMobileComponent
} from 'src/app/_shared/components/category/category-picker-mobile/category-picker-mobile.component';
import {FilterPickerComponent} from '../../../_shared/components/filter-picker/filter-picker.component';
import CollectionEnum from '../../../_shared/enums/collection.enum';
import {Ad} from '../../../_shared/models/Ad/Ad';
import {AdFilter} from '../../../_shared/models/AdFilter';
import {Category} from '../../../_shared/models/Category';
import {Location} from '../../../_shared/models/Location';
import {PlatformService} from '../../../_shared/services/platform.service';
import {ClassfieldEntityService} from '../classfield-entity.service';
import {ClassfieldService} from '../classfield.service';
import {TitleAndDescriptionService} from '../../../_shared/services/title-and-description.service';
import {TITLE_GENERIC, TITLE_SUFFIX} from '../../../_shared/constants';

import {AppState} from 'src/app/reducers';

import {loadedCategories} from 'src/app/_shared/store/category/selectors/category.selectors';
import {CategoryTreeService} from '../../../_shared/services/category-tree.service';
import {categoriesSuccess} from '../../../_shared/store/category/actions/category.actions';
import {CategoryService} from '../../../_shared/services/category.service';
import {locations, locationsLoading} from 'src/app/_shared/store/location/selectors/location.selectors';
import {isLoggedIn} from '../../../auth/auth.selectors';
import {locationLoad} from "../../../_shared/store/location/actions/location.actions";
import {Loading} from "../../../_shared/models/Loading";
import {CustomFieldFilter} from '../../../_shared/models/CustomField/CustomFieldFilter';
import {CustomFieldService} from '../../../_shared/services/custom-field.service';
import {environment} from '../../../../environments/environment';
import { SortService } from '../../../_shared/services/sort.service';
import SortEnum from '../../../_shared/enums/sort.enum';

@Component({
  selector: 'app-classfield-list',
  templateUrl: './classfield-list.page.html',
  styleUrls: ['./classfield-list.page.scss'],
})
export class ClassfieldListPage implements OnInit {

  @ViewChild('pageTop') pageTop: IonContent;

  subscriptions: Subscription[] = [];

  public filters: Partial<AdFilter> = {
    category: null,
    priceFrom: null,
    priceTo: null,
    currency: null,
    locations: [],
    keyword: null,
    customFieldFilters: []
  };

  breadcrumbs = [
    {
      name: 'Početna',
      route: '/'
    }
  ];

  isLoading = 0;
  isFilterDataLoading = 0;

  innerWidth;
  isAuthUser = false;

  categorySlug: string | undefined = undefined;

  queryParams;
  nextPage = 0;
  sort: SortEnum;
  listEnd = false;
  lastPage = false;
  modal = null;

  tappedOnBack = 0;

  ads$: Observable<Ad[]>;


  categories: Category[];
  locations: Location[];

  reset = new UntypedFormControl(false);
  resetSearchBar = new UntypedFormControl(false);
  resetPriceFrom = new UntypedFormControl(false);
  resetPriceTo = new UntypedFormControl(false);
  resetCurrency = new UntypedFormControl(false);
  resetLocations = new UntypedFormControl([]);
  resetCustomFieldFilters = new UntypedFormControl(null);

  @ViewChild('ionContent') ionContent: IonContent;
  showFixedButton = false;

  showPagination = true;
  admin = false;

  constructor(public  platformService: PlatformService,
              public  modalController: ModalController,
              private classfieldService: ClassfieldService,
              private adEntityService: ClassfieldEntityService,
              private entityCacheDispatcher: EntityCacheDispatcher,
              private router: Router,
              private route: ActivatedRoute,
              private store: Store<AppState>,
              private platform: Platform,
              private toastController: ToastController,
              private titleAndDescriptionService: TitleAndDescriptionService,
              private categoryService: CategoryService,
              private categoryTreeService: CategoryTreeService,
              private customFieldService: CustomFieldService,
              private navControl: NavController,
              private sortService: SortService) { }

  ngOnInit() {
    this.ads$ = this.adEntityService.entities$;

    this.isLoading++;
    this.innerWidth = window.innerWidth;
  }

  ionViewWillEnter() {

    this.titleAndDescriptionService.setTitle('Eventum');
    this.subscribeOnBackButton();

    if (this.navControl.consumeTransition().direction !== 'back') {


        this.pageTop.scrollToTop(0);

        this.fetchCategories();
        this.checkForLoginUser();
    }

    this.resetSearchBar.setValue(true);
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.code === 'ArrowUp') {
      this.pageTop.scrollByPoint(0, -200, 500);
    } else if (event.code === 'ArrowDown') {
      this.pageTop.scrollByPoint(0, 200, 500);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  subscribeOnBackButton() {
    this.subscriptions.push(
      this.platform.backButton.subscribeWithPriority(1, () => {
        if (this.modal) {
          this.modal.dismiss();
          this.modal = null;
        } else {
          if (this.tappedOnBack > 0) {
            App.exitApp();
          } else {
            this.tappedOnBack++;
            this.notifyUser();
            setTimeout(() => {
              this.tappedOnBack = 0;
            }, 1000);
          }
        }
      })
    );
  }

  checkForLoginUser(): void {

    this.store
      .pipe(select(isLoggedIn))
      .subscribe(response => {
        this.isAuthUser = response;
      });
  }

  fetchCategories(): void {

    this.store
      .pipe(select( loadedCategories ))
      .subscribe(categories => {

        this.categories = categories;
      }).unsubscribe();

    if (this.route.snapshot.paramMap.has('categorySlug')) {

      if (this.categoryTreeService.findCategoryBySlug(this.categories, this.route.snapshot.paramMap.get('categorySlug'))) {
        this.fetchLocations();
      } else {

        this.categoryService.categoryTree(this.route.snapshot.paramMap.get('categorySlug'), true).subscribe(
          response => {
            this.categories = this.categoryTreeService.mapCategories(response);
            this.fetchLocations();
          }
        );
      }
    } else {
      this.fetchLocations();
    }
  }

  fetchLocations(): void {
    this.subscriptions.push(this.store
      .pipe(select( locationsLoading ))
      .subscribe( loading => {

        if (loading === Loading.NOT_LOADED) {
          this.store.dispatch(locationLoad());
        }

        if (loading === Loading.SUCCESS) {
          this.subscriptions.push(
            this.store
              .pipe(select(locations))
              .subscribe(locations => {
                this.locations = locations;
                this.checkUrlForParams();
              })
          );
        }
      }));
  }

  checkUrlForParams(): void {

    this.subscriptions.push(
      combineLatest(this.route.params, this.route.queryParams)
        .pipe(map(results => ({params: results[0], queryParams: results[1]})))
        .subscribe(result => {

          this.nextPage = result.queryParams?.page ? result.queryParams.page : 0;

          this.queryParams = {...result.queryParams};
          this.categorySlug = result.params.categorySlug;


          const queryParamsObject = {
            priceFrom: this.queryParams.priceFrom ? +this.queryParams?.priceFrom : null,
            priceTo: this.queryParams.priceTo ? +this.queryParams.priceTo : null,
            currency: this.queryParams.currency ? this.queryParams.priceFrom : null,
            locations: [this.queryParams.locations],
            keyword: this.queryParams.keyword ? this.queryParams.keyword : null,
            customFieldFilters: this.queryParams.customFieldFilters ? this.queryParams.customFieldFilters : null
          };

          const category =  this.categorySlug ? this.categoryTreeService.findCategoryBySlug(this.categories, this.categorySlug) : null;

          if (category !== null) {
            this.titleAndDescriptionService.setTitle(category.title);
            this.titleAndDescriptionService.setDescription(category.description);
          } else {
            this.titleAndDescriptionService.setTitle(TITLE_GENERIC);
            this.titleAndDescriptionService.setDescription(TITLE_GENERIC);
          }

          this.breadcrumbs = [
            {
              name: 'Početna',
              route: '/'
            }
          ];

          this.resetFilters();

          if (this.categorySlug !== undefined) {

            this.filters.category = this.categoryTreeService.findCategoryBySlug(this.categories, this.categorySlug);

            this.categoryTreeService.getBreadCrumbs(this.categories, this.categorySlug).forEach(selectedCategory => {

              this.breadcrumbs.push({
                name: selectedCategory.name,
                route: `/${selectedCategory.slug}`
              });
            });
          }
          if (this.queryParams.priceFrom) {
            this.filters.priceFrom = +this.queryParams.priceFrom;
          }
          if (this.queryParams.priceTo) {
            this.filters.priceTo = +this.queryParams.priceTo;
          }
          if (this.queryParams.currency) {
            this.filters.currency = this.queryParams.currency;
          }
          if (this.queryParams.locations) {

            this.filters.locations =
              this.locations.filter(location => {
                if (this.queryParams.locations instanceof Array && this.queryParams.locations.length > 1) {
                  return this.queryParams.locations.find(filterLocation => +filterLocation === location.id);
                } else {
                  return location.id === +this.queryParams.locations;
                }
              });
          }
          if (this.queryParams.keyword) {
            this.filters.keyword = this.queryParams.keyword;
          }

          if ( this.queryParams.customFieldFilters ) {
            this.filters.customFieldFilters = this.queryParams.customFieldFilters;

          }
          this.isFilterDataLoading++;
          this.fetchAds();

        }));
  }

  refreshMainPage(): void {
    if ( this.router.url === '/' ) {
      this.isLoading = 0;
      this.reset.setValue(true);
      this.filters.category = null;
      this.nextPage = 0;
      this.scrollToTop();
      this.fetchAds(false, '0');
    }
  }

  refreshAds(event) {

    setTimeout(() => {
      this.nextPage = 0;
      this.resetFilters();
      this.fetchAds(false, '0');
      event.target.complete();
    }, 1500);
  }

  submitFilters() {
    if (this.queryParams.locations && this.queryParams.locations.length > 0 &&  typeof this.queryParams.locations[0] === 'object' ) {
      const tempArray: number[] = [];
      tempArray.push(this.queryParams.locations[0].id);
      this.queryParams.locations = tempArray;
    }

    if (this.categorySlug) {

      this.router.navigate([`/${this.categorySlug}`], {
        replaceUrl: true,
        queryParams: {
          ...this.queryParams
        }
      });
    } else {

      this.queryParams.customFieldFilters = [];
      this.queryParams.customField = null;

      this.router.navigate(['/'], {
        replaceUrl: true,
        queryParams: {
          ...this.queryParams
        }
      });
    }
  }

  onCategoryChose(data: any, filterBase: boolean) {

    if (filterBase){
      this.updateFilters(data.currentFilterState);
    }

    if (filterBase ? data.category : data) {
      this.categorySlug = filterBase ? data.category.slug : data.slug;
    } else {
      this.categorySlug = undefined;
    }

    this.submitFilters();
  }

  onSearchWithKeyword(keyword: string) {

    this.queryParams.keyword = keyword;
    this.submitFilters();
  }

  searchFromMobile(event) {

    this.onSearchWithKeyword(event.detail?.value ? event.detail.value : null);
    Keyboard.hide();
  }

  onFiltersSubmit(event) {

    this.updateFilters(event);
    //const locations = event.locations.map(location => location.id);
    //this.queryParams.priceFrom = event.priceFrom;
    //this.queryParams.priceTo = event.priceTo;
    //this.queryParams.currency = event.currency;
    //this.queryParams.locations = [...locations];


    //const stringCustomFieldFilsters: string[] = [];

    // if (event.customFieldFilters){
    //   event.customFieldFilters.forEach(customFieldFilter => {
    //     stringCustomFieldFilsters.push(JSON.stringify(customFieldFilter));
    //   });
    // }
    //
    //
    // this.queryParams.customFieldFilters =  stringCustomFieldFilsters;


    this.submitFilters();
  }

  updateFilters(event): void {
    const locationArray: number[] = [];


    if (event?.location){
      locationArray.push(event.location);
      this.queryParams.locations = event?.location ? locationArray  : null;
    } else if (event?.locations){
      event.locations.forEach(location => {
        locationArray.push(location);
      });
      this.queryParams.locations = event?.locations ? locationArray  : null;

    }

    this.queryParams.priceFrom = event?.priceFrom;
    this.queryParams.priceTo = event?.priceTo;
    this.queryParams.currency = event?.currency;
  }

  cancelAllFilters() {
    this.reset.setValue(true);
    this.queryParams = {
      keyword: this.filters?.keyword
    };
    this.categorySlug = null;
    this.submitFilters();
  }

  resetFilters(): void {

    this.filters.category = null;
    this.filters.priceFrom = null;
    this.filters.priceTo = null;
    this.filters.currency = null;
    this.filters.locations = [];
    this.filters.keyword = null;
    this.filters.customFieldFilters = [];
  }

  cancelFilter(cancelData) {
    switch (cancelData.type) {
      case 'category': {
        this.categorySlug = null;
        this.queryParams.customFieldFilters = [];
        break;
      }
      case 'priceFrom': {
        this.queryParams.priceFrom = null;
        this.queryParams.currency = this.queryParams.priceTo ?
          this.queryParams.currency :
          null;
        this.resetPriceFrom.setValue(true);
        break;
      }
      case 'priceTo': {
        this.queryParams.priceTo = null;
        this.queryParams.currency = this.queryParams.priceFrom ?
          this.queryParams.currency :
          null;
        this.resetPriceTo.setValue(true);
        break;
      }
      case 'currency': {
        this.queryParams.currency = null;
        this.resetCurrency.setValue(true);
        break;
      }
      case 'location': {
        this.filters.locations = this.filters.locations.filter(
          location => !(location?.name === cancelData.selectedFilter?.name));
        this.queryParams.locations = this.filters.locations.length > 0 ? this.filters.locations[0].id : null; // this.filters.locations.map(location => location.id);
        this.resetLocations.setValue(this.filters.locations);
        break;
      }
      case 'customFieldFilter': {

        this.queryParams.customFieldFilters = this.removeCustomFieldFilterInQueryParams( cancelData );
        this.resetCustomFieldFilters.setValue( cancelData.selectedFilter );
        break;
      }
    }
    this.submitFilters();
  }

  fetchAds(loadMore = false,
           pageNumber = this.nextPage.toString(),
           pageSize = '10',
           priceFrom = this.filters.priceFrom ? this.filters.priceFrom.toString() : null,
           priceTo = this.filters.priceTo ? this.filters.priceTo.toString() : null,
           currency = this.filters.currency ? this.filters.currency : null,
           category = this.filters.category ? this.filters.category.slug : null,
           locations = this.filters.locations ? this.filters.locations.map(location => location.id)
             .toString() : null,
           keyword = this.filters.keyword ? this.filters.keyword : null,
           orderBy = this.sortService.getSort(this.sort).orderBy.toString(),
           direction = this.sortService.getSort(this.sort).direction.toString(),
           customFieldFilters = this.filters.customFieldFilters ? this.filters.customFieldFilters  : []
          ): void {

    if (!loadMore) {
      this.entityCacheDispatcher.clearCollections([CollectionEnum.Ad]);
    }

    this.isLoading++;

    this.adEntityService.getWithQuery({
        pageNumber: this.nextPage.toString(),
        pageSize,
        priceFrom,
        priceTo,
        currency,
        category,
        locations,
        keyword,
        orderBy,
        direction,
        customFieldFilters
      })
        .pipe(finalize(() =>
        {
          this.isLoading = 0;
        }))
        .subscribe(

          response =>{
            if(response.length === 0 || response.length < 10){
              this.lastPage = true;
            }

            if (response.length === 0 && loadMore) {
              if (this.nextPage > 0) {
                this.nextPage--;
              }
              this.listEnd = true;
            } else {
              this.listEnd = false;
            }
          },
          error => {
            console.log(error);
          }
        );
  }

  onLoadData() {

    this.showPagination = false;
    this.nextPage++;
    this.fetchAds(true);
  }

  onSortData(event) {

    this.sort = event;
    this.nextPage = 0;
    this.fetchAds();
  }

  async pickCategory() {

    this.modal = await this.modalController.create({
      component: CategoryPickerMobileComponent,
      cssClass: 'classfield-list-modal-picker',
      componentProps: {}
    });
    await this.modal.present();
    const {data} = await this.modal.onWillDismiss();

    const {category} = data || {};
    this.modal = null;

    this.onCategoryChose(category, false);
  }

  async pickFilters() {

    this.modal = await this.modalController.create({
      component: FilterPickerComponent,
      cssClass: 'classfield-list-modal-picker',
      componentProps: {
        reset: this.reset,
        resetPriceFrom: this.resetPriceFrom,
        resetPriceTo: this.resetPriceTo,
        resetCurrency: this.resetCurrency,
        resetLocations: this.resetLocations,
        chosenPriceFrom: this.filters.priceFrom,
        chosenPriceTo: this.filters.priceTo,
        chosenCurrency: this.filters.currency,
        chosenLocations: this.filters.locations,
        chosenCustomFields: this.filters.customFieldFilters,
        categorySlug: this.route.snapshot.paramMap.get('categorySlug')
      }
    });

    await this.modal.present();

    const {data} = await this.modal.onWillDismiss();
    const filters = data?.data;
    this.modal = null;

    if (filters) {
      this.onFiltersSubmit(filters);
    }
  }

  removeCustomFieldFilterInQueryParams( cancelData: any ): string[] {

    const customFieldFiltersHelper: CustomFieldFilter[] =
      this.customFieldService.parseStringToCustomFieldFilter( this.queryParams.customFieldFilters );

    customFieldFiltersHelper
      .splice(this.findCustomFieldIndexInCustomFieldArray( customFieldFiltersHelper, cancelData ), 1);

    return this.customFieldService.parseCustomFieldFilterToString( customFieldFiltersHelper );
  }

  findCustomFieldIndexInCustomFieldArray( customFieldFilters: CustomFieldFilter[], cancelData: any ): number {

    return customFieldFilters.findIndex(customFieldFilter =>
      cancelData.selectedFilter.type === 'multiselect' ?
        customFieldFilter.value === cancelData.selectedFilter.value &&
        customFieldFilter.customFieldId === cancelData.selectedFilter.customFieldId :
        customFieldFilter.customFieldId === cancelData.selectedFilter.customFieldId
    );
  }

  private async notifyUser() {

    const toast = await this.toastController.create({
      message: 'Ponovite akciju još jednom da izađete iz aplikacije.',
      duration: 1000,
      position: 'bottom',
      color: 'light',
      keyboardClose: true,
      animated: true
    });
    await toast.present();
  }

  scrollToTop(){

    this.pageTop.scrollToTop(800);
  }

  ionViewWillLeave() {

    this.tappedOnBack = 0;
    this.subscriptions.map(s => s.unsubscribe());
    this.subscriptions = [];
  }

  onScroll(event: any): void {

    this.pageTop.getScrollElement().then(el => {

      this.showFixedButton = event.detail.scrollTop > el.offsetHeight ? true : false;
    });
  }

  createPagedUrl(isNext: boolean): string {

    let baseUrl = environment.baseAddress;

    if (this.route.snapshot.paramMap.has('categorySlug')){
      baseUrl = baseUrl.concat(`/${this.route.snapshot.paramMap.get('categorySlug')}`);
    }

    baseUrl = baseUrl.concat(`?page=${ isNext ? +this.nextPage + 1 : +this.nextPage - 1 }`);

    if (this.filters.locations && this.filters.locations.length > 0){
      baseUrl = baseUrl.concat(`&locations=${this.filters.locations[0].id}`);
    }
    if (this.filters.priceFrom){
      baseUrl = baseUrl.concat(`&priceFrom=${this.filters.priceFrom}`);
    }
    if (this.filters.priceFrom){
      baseUrl = baseUrl.concat(`&priceTo=${this.filters.priceTo}`);
    }
    if (this.filters.priceFrom){
      baseUrl = baseUrl.concat(`&currency=${this.filters.currency}`);
    }
    if (this.filters.keyword){
      baseUrl = baseUrl.concat(`&keyword=${this.filters.keyword}`);
    }
    return baseUrl;
  }
}


