import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-menu-button-notifications',
  templateUrl: './menu-button-notifications.component.html',
  styleUrls: ['./menu-button-notifications.component.scss'],
})
export class MenuButtonNotificationsComponent {

  @Input() notificationsCount = 0;
}
