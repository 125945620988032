import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {User} from '../../../models/User';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../reducers';
import {Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {authUser, unreadChatsIds} from 'src/app/auth/auth.selectors';
import { logout } from 'src/app/auth/auth.actions';
import {MenuController, PopoverController} from '@ionic/angular';
import {YesNoDialogComponent} from '../../yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-side-menu',
  templateUrl: './mobile-side-menu.component.html',
  styleUrls: ['./mobile-side-menu.component.scss'],
})
export class MobileSideMenuComponent implements OnInit {

  authUser: User;
  unreadChats = [];
  yesNoDialog: any;
  admin;

  constructor(private store: Store<AppState>,
              private router: Router,
              private menuController: MenuController,
              private popoverController: PopoverController) { }

  ngOnInit() {

    this.admin = localStorage.getItem('role')?.includes('admin') ? true : false;
    this.getAuthenticatedUser();
  }

  getAuthenticatedUser(): void {

    this.store
      .pipe(select(authUser))
      .pipe(filter(user => !!user))
      .subscribe(response => {
        this.authUser = response;
        this.fetchUnreadChats();
      });
  }

  fetchUnreadChats(): void {

    this.store
      .pipe(select(unreadChatsIds))
      .subscribe(unreadChats => {
        this.unreadChats = unreadChats;
      });
  }

  goTo( route: string ): void {
    this.router.navigate([route], { replaceUrl: true });
    this.hideSideMenu();
  }

  async openYesNoDialog() {
    this.yesNoDialog = await this.popoverController.create({
      component: YesNoDialogComponent,

      componentProps: {dialogText: `Da li ste sigurni?`, buttonText: ['Da', 'Ne']}
    });

    this.yesNoDialog.onDidDismiss().then((response: any) => {
      if (response.role === 'decision' && response.data) {
        this.logout();
      }
    });

    return await this.yesNoDialog.present();
  }

  logout(): void {

    this.store.dispatch(logout({user: this.authUser}));
    this.router.navigate(['/auth/prijava'],{replaceUrl: true});
    this.hideSideMenu();
  }

  hideSideMenu() {
    this.menuController.close().then(r => {});
  }

  goToInbox() {
    this.router.navigate(['/home/inbox'], { replaceUrl: true });
    this.hideSideMenu();
  }

  goToRank(){
    this.router.navigate(['/home/profil/ocene'], { replaceUrl: true });
    this.hideSideMenu();
  }
}
