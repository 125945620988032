import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';

import { LocationActions } from '../action-types';

import { LocationService } from './../../../services/location.service';

@Injectable()
export class LocationEffects implements OnInitEffects {

  load$ = createEffect(() => this.actions$
    .pipe(
      ofType(LocationActions.locationLoad),
      mergeMap(() => {
        return this.locationService.all()
          .pipe(
            map(response => {
              return LocationActions.locationSuccess({ locations: response });
            }),
            catchError((err) => {
              return of(LocationActions.locationFailure({ error: err.error }));
            })
          );
      })
    )
  );

  constructor(private actions$: Actions,
              private locationService: LocationService
              ) { }

  ngrxOnInitEffects(): Action {

    return LocationActions.locationInit();
  }
}


