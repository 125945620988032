import { Injectable } from '@angular/core';

import { CustomField } from '../models/CustomField/CustomField';
import {CustomFieldFilter} from '../models/CustomField/CustomFieldFilter';
import {AdsCustomFieldsData} from '../models/CustomField/AdsCustomFieldsData';
import {CustomFieldOption} from '../models/CustomField/CustomFieldOption';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldService {

  constructor() { }

  /**
   * Map data custom field with custom customField properties.
   *
   * @return CustomField[]
   */

  public mapCustomField( customFields: CustomField[] ): CustomField[] {

    const customFieldHelper: CustomField[] = [];

    customFields.forEach(( customField: CustomField ) => {

      customFieldHelper.push({
        id: customField.id,
        name: customField.name,
        categoryId: customField.categoryId,
        type: customField.type,
        min: customField.min,
        max: customField.max,
        required: customField.required,
        customFieldOptions: customField.customFieldOptions,

        active: customField.active,
        minLength: customField.minLength,
        maxLength: customField.maxLength,
        placeholder: customField.placeholder,
        sufix: customField.sufix,

        value: customField.type === 'multiselect' ? [] : null,
        checkedFieldOptions: [],
        checkedFieldOptionsId: [],
      } as CustomField );
    });

    return customFieldHelper;
  }

  /**
   * Parse string to CustomFieldFilter.
   *
   * @return CustomFieldFilter[]
   */

  public parseStringToCustomFieldFilter( customFieldFiltersAsStringArray: string[] | string ): CustomFieldFilter[] {

    const customFieldFiltershelper: CustomFieldFilter[] = [];
    if ( Array.isArray(customFieldFiltersAsStringArray)){
      customFieldFiltersAsStringArray.forEach( customFieldFilter => {
        customFieldFiltershelper.push( JSON.parse( customFieldFilter ) as CustomFieldFilter );
      });
    } else  {
      customFieldFiltershelper.push( JSON.parse( customFieldFiltersAsStringArray ) as CustomFieldFilter );
    }
    return customFieldFiltershelper;
  }

  /**
   * Parse CustomFieldFilter[] to string[].
   *
   * @return CustomFieldFilter[]
   */

  public parseCustomFieldFilterToString( customFieldFilters: CustomFieldFilter[] ): string[] {

    const customFieldFiltersHelperAsStringArray: string[] = [];

    customFieldFilters.forEach( customFieldFilterHelper => {
      customFieldFiltersHelperAsStringArray.push(JSON.stringify(customFieldFilterHelper));
    });

    return customFieldFiltersHelperAsStringArray;
  }

  /**
   * Fill custom field with selected data when editing classfield.
   *
   * @return CustomField[]
   */

  fillCustomFieldWithSelectedData(customFields: CustomField[], adsCustomFieldsData: AdsCustomFieldsData[]): CustomField[] {

    const customFieldHelper: CustomField[] =  this.mapCustomField(customFields);

    if ( adsCustomFieldsData ) {

      adsCustomFieldsData.forEach(( adsCustomFieldData: AdsCustomFieldsData ) => {

        const index = customFieldHelper.findIndex(( customfield: CustomField ) => customfield.id === adsCustomFieldData.customFieldId );

        if ( customFieldHelper[index].type === 'number' ) {
          customFieldHelper[index].value = adsCustomFieldData.numberValue;
        } else if ( customFieldHelper[index].type === 'string') {
          customFieldHelper[index].value = adsCustomFieldData.stringValue;
        } else if ( customFieldHelper[index].type === 'select' ) {

          customFieldHelper[index].value = adsCustomFieldData.stringValue;
          // @ts-ignore
          customFieldHelper[index].checkedFieldOptions = adsCustomFieldData.customFieldOptionId;
          // @ts-ignore
          customFieldHelper[index].checkedFieldOptionsId =  adsCustomFieldData.customFieldOptionId;

        } else if ( customFieldHelper[index].type === 'multiselect' ) {

          (customFieldHelper[index].value as string[]).push( adsCustomFieldData.stringValue as string);

          (customFieldHelper[index].checkedFieldOptions as CustomFieldOption[]).push(
            customFieldHelper[index].customFieldOptions
              .find( (customFieldOption: CustomFieldOption) => customFieldOption.id === adsCustomFieldData.customFieldOptionId)
          );

          (customFieldHelper[index].checkedFieldOptionsId as number[]).push(adsCustomFieldData.customFieldOptionId as number);
        }
      });
    }

    return customFieldHelper;
  }
}
