import { EventEmitter, Component, Input, Output } from '@angular/core';

import { CustomField } from '../../../models/CustomField/CustomField';
import { CustomFieldOption } from '../../../models/CustomField/CustomFieldOption';
import { CustomFieldFilter } from '../../../models/CustomField/CustomFieldFilter';
import { AdsCustomFieldsData } from '../../../models/CustomField/AdsCustomFieldsData';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-custom-field-select',
  templateUrl: './custom-field-select.component.html',
  styleUrls: ['./custom-field-select.component.scss'],
})
export class CustomFieldSelectComponent {

  @Input() type: string = undefined;
  @Input() classfieldId: number = undefined;
  @Input() customField: CustomField = undefined;
  @Input() selectedFieldOptionId: any = undefined;
  @Input() customFieldOptions: any[] = undefined;

  @Input() index: number = null;
  @Input() formSubmitted = false;
  @Input() formG: any;

  @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();

  onSelectCustomFieldOption( event: any ): void {

    const customFieldOption = this.customFieldOptions.find(cf => cf.id === event);
    if ( this.type === 'filter' ){
      this.emmitCustomFieldFilter( customFieldOption );
    } else {
      this.setDataToFromControl( customFieldOption );
    }
  }

  findSelectedOption(): CustomFieldOption {

    if ( this.selectedFieldOptionId ) {
      return this.customFieldOptions.find( customFieldOption => customFieldOption.id === this.selectedFieldOptionId);
    } else {
      return  null;
    }
  }

  setDataToFromControl( customFieldOption: CustomFieldOption ): void {

    ((this.formG.get('customFields') as UntypedFormArray).at(this.index) as UntypedFormGroup)
      .get('value').patchValue(customFieldOption ? customFieldOption.value : null);

    ((this.formG.get('customFields') as UntypedFormArray).at(this.index) as UntypedFormGroup)
      .get('checkedOptionId').patchValue(customFieldOption ? customFieldOption.id : null);
  }

  emmitCustomFieldFilter( customFieldOption: CustomFieldOption ): void {

    this.selectedValue.emit({
      customFieldId: this.customField.id,
      type: this.customField.type,
      value: customFieldOption ? customFieldOption.value : null,
      min: 0,
      max: 0
    } as unknown as CustomFieldFilter );
  }
}
