import { Component, OnInit } from '@angular/core';
import {User} from '../../models/User';
import {PlatformService} from '../../services/platform.service';
import {NavigationStart, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {isLoggedIn} from '../../../auth/auth.selectors';

@Component({
  selector: 'app-home-tabs',
  templateUrl: './home-tabs.component.html',
  styleUrls: ['./home-tabs.component.scss'],
})
export class HomeTabsComponent implements OnInit {

  route     = '/';
  postRoute = '/prijava';
  authUser: User;

  loaded = false;

  constructor(public platformService: PlatformService,
              private router: Router,
              private store: Store<AppState>) {

    this.subscribeOnRouteChanges();
  }

  ionViewWillEnter() {
    this.route = this.router.url;
    this.loaded = true;
  }


  ngOnInit() {

    this.checkForLoginUser();
  }

  subscribeOnRouteChanges() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.route = event.url;
      }
    });
  }

  checkForLoginUser(): void {

    this.store
      .pipe(select(isLoggedIn))
      .subscribe(response => {
        if (response) {
          this.postRoute = '/home/oglasi/dodaj-oglas';
        } else {
          this.postRoute = 'prijava?navigate_to=%2Fhome%2Foglasi%2Fdodaj-oglas';
        }
      });
  }

  navigateHome() {
    this.route = '/';
    this.router.navigateByUrl('/');
  }

  navigateAddNewClassfield(): void {
    this.router.navigateByUrl(this.postRoute);
  }
}
