import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { IonInput, ModalController } from '@ionic/angular';

import { filter } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { InvisibleReCaptchaComponent } from '../invisible-recaptcha/invisible-recaptcha.component';

import { authUser } from 'src/app/auth/auth.selectors';
import { AppState } from 'src/app/reducers';

import { User } from '../../models/User';

import { FeedbackService } from '../../services/feedback.service';
import { ToastService } from '../../services/toast.service';

import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit{

  @Input() title                = '';
  @Input() text                 = '';
  @Input() alertMessage: string = undefined;
  @Input() isAdReport           = false;
  @Input() adId: number         = undefined;
  @Input() adTitle: string      = undefined;
  @Input() payload: string      = undefined;

  @ViewChild(InvisibleReCaptchaComponent) invisibleReCaptcha: InvisibleReCaptchaComponent;

  seletedDOMElement: QuillEditorComponent | IonInput;

  form: UntypedFormGroup = undefined;

  submitted  = false;
  formActive = false;

  richTextEditorStyle: any = {
    minHeight: '170px',
    borderRadius: '15px',
    background: '#F9F9F9',
    border: '1px solid #DBDDE0'
  };

  scrollbarScss = '  @media(pointer: fine) {\n' +
    '    ::-webkit-scrollbar {\n' +
    '      width: 6px;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-track {\n' +
    '      background: #fff;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-track:hover {\n' +
    '      background: #f7f7f7;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-thumb {\n' +
    '      background: #ccc;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-thumb:hover {\n' +
    '      background: #888\n' +
    '    }\n' +
    '    .inner-scroll {\n' +
    '      scrollbar-width: thin\n' +
    '    }\n' +
    '  }';

  constructor(private modalController: ModalController,
              private formBuilder: UntypedFormBuilder,
              private feedbackService: FeedbackService,
              private toastService: ToastService,
              private store: Store<AppState>) { }

  ngOnInit() {
    this.initializeComponent();
  }

  initializeComponent(): void {
    this.buildForm();
  }

  setSelectedDOMElement(event: QuillEditorComponent | IonInput): void {

    this.seletedDOMElement = event;
  }

  focusSelectedDOMElement(): void {

    if (this.seletedDOMElement instanceof QuillEditorComponent){
      this.seletedDOMElement?.quillEditor.focus();
    } else {
      this.seletedDOMElement?.setFocus();
    }
  }

  buildForm() {
    if (this.isAdReport) {
      this.buildReportAdForm();
    } else {
      this.buildFeedbackForm();
    }
  }

  buildReportAdForm() {

    this.form = this.formBuilder.group({

      name:           [null],
      email:          [null],
      phoneNumber:    [null],
      message:        ['',           [Validators.required]],
      adId:           [this.adId,    [Validators.required]],
      adTitle:        [this.adTitle, [Validators.required]]
    });

    this.formActive = true;
  }

  buildFeedbackForm() {

    this.form = this.formBuilder.group({

      name:           [null],
      email:          [null],
      phoneNumber:    [null],
      message:        ['',   [Validators.required]],
      payload:        [this.payload],
      loggedUserName: [null],
      loggeduserId:   [null],
    });

    this.getAuthenticatedUser();

    this.formActive = true;
  }

  getAuthenticatedUser(): void {

    this.store
      .pipe(select(authUser))
      .pipe(filter(user => !!user))
      .subscribe(( user: User ) => {
        if (user){

          this.form.controls.loggedUserName.setValue(user.fullName);
          this.form.controls.loggedUserName.updateValueAndValidity();

          this.form.controls.loggeduserId.setValue(user.id);
          this.form.controls.loggeduserId.updateValueAndValidity();
        }

      });
  }

  onSubmit(): void {

    if (this.form.invalid) {
      this.submitted = true;
      return;
    }

    this.invisibleReCaptcha.executeReCaptcha();
  }

  handleReCaptchaResponse( captchaResponse: boolean ): void {
    if ( captchaResponse ) {
      this.sendData();
    } else {
      this.toastService.showError('Greška prilikom slanja!').then((toast) => {
        this.focusSelectedDOMElement();
        toast.onDidDismiss().then(() => {
          this.focusSelectedDOMElement();
        });
      });
    }
  }

  sendData(): void {

    if (this.form.controls.phoneNumber.value){

      let phoneNumber = this.form.controls.phoneNumber.value;
      phoneNumber = phoneNumber.toString();
      this.form.controls.phoneNumber.setValue(phoneNumber);
    }

    this.form.controls.phoneNumber.updateValueAndValidity();

    if (this.isAdReport){
      this.sendAdReport();
    } else {
      this.sendFeedback();
    }
  }

  sendAdReport(): void {

    this.feedbackService.reportAd(this.form.value).subscribe(
      result => {
        this.toastService.showSuccess('Oglas je uspešno prijavljen!');
        this.form.reset();
        this.modalController.dismiss();
      },
      error => {
        this.toastService.showError('Greška prilikom prijavljivanja oglasa!').then((toast) => {
        this.focusSelectedDOMElement();
        toast.onDidDismiss().then(() => {
            this.focusSelectedDOMElement();
          });
        });
      }
    );
  }

  sendFeedback(): void {

    this.feedbackService.seendFeedback(this.form.value).subscribe(
      result => {

        this.toastService.showSuccess('Poruka je uspešno poslata!');
        this.form.reset();
        this.modalController.dismiss();
      },
      error => {
        this.toastService.showError('Poruka nije poslata!').then((toast) => {

          this.focusSelectedDOMElement();
          toast.onDidDismiss().then(() => {
            this.focusSelectedDOMElement();
          });
        });
      }
    );
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
