import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Transaction } from '../../../models/Transaction';

@Component({
  selector: 'app-transaction-list-holder',
  templateUrl: './transaction-list-holder.component.html',
  styleUrls: ['./transaction-list-holder.component.scss'],
})
export class TransactionListHolderComponent {

  @Input()  transactions: Transaction[]    = [];
  @Input()  isLoading;
  @Input()  listEnd                        = false;

  @Output() loadData: EventEmitter<void>   = new EventEmitter<void>();

  constructor() { }

  onLoadData(event) {

    this.loadData.emit();
    event.target.complete();
  }
}
