import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FacebookLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';

@Injectable({
  providedIn: 'root'
})
export class FacebookService {

  public static config =  {
    scope: 'public_profile,email'
  };

  constructor(private store: Store<AppState>, private socialAuthService: SocialAuthService) {}

  showLoginModal(callback) {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data: SocialUser) => {
      callback(data);
    });
  }

  logout() {
    this.socialAuthService.signOut(true);
  }
}
