import { EventEmitter, OnInit, Input, Output, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { CustomField } from '../../../models/CustomField/CustomField';
import { CustomFieldFilter } from '../../../models/CustomField/CustomFieldFilter';
import { AdsCustomFieldsData } from '../../../models/CustomField/AdsCustomFieldsData';

@Component({
  selector: 'app-custom-field-number',
  templateUrl: './custom-field-number.component.html',
  styleUrls: ['./custom-field-number.component.scss'],
})
export class CustomFieldNumberComponent implements OnInit {

  @Input() classfieldId: number = undefined;
  @Input() type: string = null;
  @Input() filterEdit: boolean = null;

  @Input() index: number = null;
  @Input() formSubmitted = false;
  @Input() formG: any;
  @Input() customField: CustomField = undefined;

  form: UntypedFormGroup = undefined;
  formActive = false;

  @Output() selectedValue: EventEmitter<AdsCustomFieldsData | CustomFieldFilter>
    = new EventEmitter<AdsCustomFieldsData | CustomFieldFilter>();

  constructor( private formBuilder: UntypedFormBuilder ) { }

  ngOnInit() {
    if (this.type === 'filter') {
      this.buildFilterForm();
    }
  }

  buildFilterForm(): void {

    this.form = this.formBuilder.group({
      min:           [ this.customField && this.filterEdit ? this.customField.min : null ],
      max:           [ this.customField && this.filterEdit ? this.customField.max : null ],
    });

    this.formActive = true;
  }

  onValueChanges(): void {

    if ( this.type === 'filter' ) {
        this.selectedValue.emit({
          customFieldId: this.customField.id,
          type: this.customField.type,
          value: this.customField.name,
          min: this.form.controls.min.value,
          max: this.form.controls.max.value
        } as unknown as  CustomFieldFilter);
    }
  }
}
