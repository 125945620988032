import { createAction, props } from '@ngrx/store';
import { Category } from '../../../models/Category';

export const topCategories = createAction(
  '[Category]  Top Categories',
);

export const topCategoriesSuccess = createAction(
  '[Category] Top Categories Success',
  props<{ categories: Category[] }>()
);

export const topCategoriesFailure = createAction(
  '[Category] Top Categories Failure',
  props<{ error: any }>()
);

export const categories = createAction(
  '[Category] Categories',
  props<{ parameter, isSlug: boolean }>()
);

export const categoriesSuccess = createAction(
  '[Category] Categories Success',
  props<{ categories: Category[], openCategories: boolean, slug?: string }>()
);

export const categoriesFailure  = createAction(
  '[Category] Categories Failure',
  props<{ error: any }>()
);

export const breadCrumbsSuccess = createAction(
  '[Category] BreadCrumbs Success',
  props<{ breadCrumbs: any[] }>()
);

export const categoryLog = createAction(
  '[Category] Category log',
  props<{categories: any[], slug: any}>()
);
