import {Injectable, Input} from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ClassfieldService } from '../classfield.service';
import {catchError, map, tap} from 'rxjs/operators';
import { Category } from '../../../_shared/models/Category';
import { StripService } from '../../../_shared/services/strip.service';
import { TitleAndDescriptionService } from '../../../_shared/services/title-and-description.service';
import {Ad} from '../../../_shared/models/Ad/Ad';
import {select, Store} from '@ngrx/store';
import {loadedCategories} from '../../../_shared/store/category/selectors/category.selectors';
import {breadCrumbsSuccess, categoriesSuccess} from '../../../_shared/store/category/actions/category.actions';
import {AppState} from '../../../reducers';
import {CategoryTreeService} from '../../../_shared/services/category-tree.service';
import {CategoryService} from '../../../_shared/services/category.service';
import { DateTime } from 'luxon';
import {PlatformService} from '../../../_shared/services/platform.service';


@Injectable({
  providedIn: 'root'
})
export class ClassfieldDetailResolver implements Resolve<Category> {

  constructor(private router: Router,
              private store: Store<AppState>,
              private stripService: StripService,
              public  platformService: PlatformService,
              private categoryService: CategoryService,
              private classfieldService: ClassfieldService,
              private categoryTreeService: CategoryTreeService,
              private titleAndDescriptionService: TitleAndDescriptionService) {
  }

  // @ts-ignore
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Category> | Observable<null> | null | boolean {

     let data;

     // @ts-ignore
     return this.classfieldService.showBySlug(route.paramMap.get('slug'))
      .pipe(
        catchError(() => {
          this.router.navigate(['/greska/stranica-nije-pronadjena'], {replaceUrl: false});
          return of(null);
        }),
        tap( (classfield: Ad) => {
          this.setTitleAndDescription(classfield);
          data = classfield;
        }),
        map( () => {
          this.fetchBreadCrumbs(data);
          data = this.calculateCreationDate(data);
          return of(data);
        })
      );
  }

  setTitleAndDescription( classfield: Ad ): void {
    this.titleAndDescriptionService.setTitle(classfield?.title);
    this.titleAndDescriptionService.setDescription(classfield.description ?
      this.stripService.removeParagraphs(classfield.description.substring(0, 160)) : classfield.title);
  }

  calculateCreationDate(ad): any {

    const today     = DateTime.now();
    const createdAt = DateTime.fromFormat(ad.createdAt, 'YYYY-MM-DD');
    const time      = today.diff(createdAt, 'days').days;
    if (time === 0) {
      ad.time = 'danas';
    } else if (time === 1) {
      ad.time = 'juče';
    } else if (time > 1) {
      ad.time = 'pre ' + time + ' dana';
    }

    return ad;
  }

  fetchBreadCrumbs( classfield: Ad ): void {

    let categories = [];

    this.store
      .pipe(select(loadedCategories))
      .subscribe(categoriesFromStore => {
        categories = categoriesFromStore;
      }).unsubscribe();

    const category = this.categoryTreeService.findCategoryById(categories, classfield.categoryId);

    if ( !category ) {
       this.categoryService.categoryTree(classfield.categoryId, false).subscribe(
        response => {
          categories = this.categoryTreeService.closeCategories(response);
          this.store.dispatch(categoriesSuccess({categories, openCategories: false}));
          this.makeBreadCrumbs(categories, classfield.category);
        }
      );
    } else {
      categories = this.categoryTreeService.closeCategories(categories);
      this.store.dispatch(categoriesSuccess({categories, openCategories: false}));
      this.makeBreadCrumbs(categories, classfield.category);
    }
  }

  makeBreadCrumbs(categories: Category[], category: Category): void {


    const breadcrumbs = [
      {
        name:  'Početna',
        route: '/'
      },

    ];

    this.categoryTreeService.getBreadCrumbs(categories, category.slug).forEach( selectedCategory => {

      breadcrumbs.push({
        name: selectedCategory.name,
        route: `/${selectedCategory.slug}`
      });
    });

    this.store.dispatch(breadCrumbsSuccess({breadCrumbs: breadcrumbs}));
  }
}


