import { EventEmitter, OnInit, Component, Input, Output } from '@angular/core';

import { CustomField } from '../../../models/CustomField/CustomField';
import { CustomFieldOption } from '../../../models/CustomField/CustomFieldOption';
import { CustomFieldOptionDto } from '../../../models/CustomField/CustomFieldOptionDto';

import { CustomFieldFilter } from '../../../models/CustomField/CustomFieldFilter';
import { AdsCustomFieldsData } from '../../../models/CustomField/AdsCustomFieldsData';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-custom-field-multiselect',
  templateUrl: './custom-field-multiselect.component.html',
  styleUrls: ['./custom-field-multiselect.component.scss'],
})

export class CustomFieldMultiselectComponent implements OnInit {

  @Input() type: string = undefined;
  @Input() classfieldId: number = undefined;
  @Input() customField: CustomField = undefined;
  @Input() customFieldOptions: CustomFieldOption[] = undefined;
  @Input() customFieldSelectedOptions: any = undefined;

  @Input() index: number = null;
  @Input() formSubmitted = false;
  @Input() formG: any;

  customFieldOptionsHelper: CustomFieldOptionDto[] = [];

  @Output() selectedValue: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {

    this.customFieldOptions.forEach( customFieldOption => {

      this.customFieldOptionsHelper.push({
        id: customFieldOption.id,
        name: customFieldOption.name,
        value: customFieldOption.value,
        selected: this.customFieldSelectedOptions ? !!this.customFieldSelectedOptions
          .find(customfieldSelectedOption => customfieldSelectedOption.value === customFieldOption.value) : false
      } as unknown as CustomFieldOptionDto );
    });

    this.customFieldOptionsHelper.sort((a, b) => a.name.localeCompare(b.name));
  }

  onSelectCustomFieldOption( customFieldOption: CustomFieldOptionDto ): void {

    if ( this.type === 'edit' ){
      this.setMultiselectData( customFieldOption );
    } else {
      this.emmitCustomFieldFilter( customFieldOption );
    }
  }

  setMultiselectData( customFieldOption: CustomFieldOptionDto ): void {

    const checkedOptionsIdsArray: number [] = (this.formG.controls.customFields as UntypedFormArray)
      .at(this.index).value.checkedOptionId;

    const checkedOptionsValuesArray: string [] = (this.formG.controls.customFields as UntypedFormArray)
      .at(this.index).value.value;

    const checkedOptionIndex = checkedOptionsIdsArray
      .findIndex( checkedOptionId => checkedOptionId === customFieldOption.id as number);

    if ( checkedOptionIndex > -1 ) {

      checkedOptionsIdsArray.splice( checkedOptionIndex, 1 );
      checkedOptionsValuesArray.splice( checkedOptionIndex, 1 );
    } else {

      checkedOptionsIdsArray.push( customFieldOption.id as number );
      checkedOptionsValuesArray.push( customFieldOption.value as string );
    }

    ((this.formG.get('customFields') as UntypedFormArray).at(this.index) as UntypedFormGroup)
      .get('value').patchValue(checkedOptionsValuesArray);

    ((this.formG.get('customFields') as UntypedFormArray).at(this.index) as UntypedFormGroup)
      .get('checkedOptionId').patchValue(checkedOptionsIdsArray);
  }

  emmitCustomFieldFilter( customFieldOption: CustomFieldOptionDto ): void {

    this.selectedValue.emit(
      {
        customFieldId: this.customField.id,
        type: this.customField.type,
        value: customFieldOption.value,
        min: 0,
        max: 0,
      } as unknown as CustomFieldFilter
    );
  }
}
