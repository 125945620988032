import {NgModule} from '@angular/core';
import {LandingPageRoutingModule} from './landing-page-routing.module';
import {LandingPageComponent} from './landing-page.component';
import {IonicModule} from '@ionic/angular';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../_shared/shared.module';
import {AuthService} from '../auth/auth.service';

@NgModule({
    imports: [
        LandingPageRoutingModule,
        IonicModule,
        CommonModule,
        SharedModule
    ],
  providers: [
    AuthService
  ],
  exports: [ LandingPageComponent],
  declarations: [ LandingPageComponent]
})
export class LandingPageModule {}
