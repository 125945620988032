import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { Observable } from 'rxjs';
import CollectionEnum from '../../_shared/enums/collection.enum';
import { Ad } from '../../_shared/models/Ad/Ad';
import { ClassfieldService } from './classfield.service';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { classfieldCountSuccess } from '../../_shared/store/classfield-count/actions/classfield-count.actions';

@Injectable()
export class ClassfieldDataService extends DefaultDataService<Ad> {

  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator,
              private classfieldService: ClassfieldService,  private store: Store<AppState>) {

    super(CollectionEnum.Ad, http, httpUrlGenerator);
  }

  getWithQuery(queryParams: QueryParams | string): Observable<Ad[]> {

    return this.classfieldService.index(+queryParams['pageNumber'],0, +queryParams['priceFrom'],
                                +queryParams['priceTo'], queryParams['currency'], queryParams['category'],
                                queryParams['locations'], queryParams['keyword'], queryParams['orderBy'],
                                queryParams['direction'], queryParams['customFieldFilters']
    ).pipe(
        tap( data => this.store.dispatch(classfieldCountSuccess({count: data.total}))),
        map(data => data.items),
      );
  }

  getById(key: number | string): Observable<Ad> {
    if (typeof(key) === 'string') {
      return this.classfieldService.showBySlug(key.toString());
    }
    else {
      return this.classfieldService.show(+key);
    }
  }

  update(ad): Observable<Ad> {
    return this.classfieldService.update(ad.changes);
  }

  delete(key: number | string): Observable<number | string> {
    return this.classfieldService.destroy(+key);
  }
}
