import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authFeatureKey, AuthState } from './reducers';

export const selectAuthState =
               createFeatureSelector<AuthState>(authFeatureKey);
export const isLoggedIn      = createSelector(
  selectAuthState,
  (state: AuthState) => state.userAuthCheck ? undefined : !!state.user
);

export const authUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.user
);

// export const isAuthUserFilled = createSelector(
//   selectAuthState,
//   (state: AuthState) => !(!state.user.fullName || !state.user.phoneNumber || !state.user.locationId)
// );

export const isNotLoggedIn = createSelector(
  selectAuthState,
  (state: AuthState) => state.userAuthCheck ? undefined : !state.user
);

export const isCheckingAuthUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.userAuthCheck
);

export const unreadChatsIds = createSelector(
  selectAuthState,
  ( state: AuthState ) => state.notifications.unreadChatsIds
);

export const authUserAndUnreadChats = createSelector(
  selectAuthState,
  (state: AuthState) => ({ authUser: state.user,  unreadChats: state.notifications.unreadChatsIds })
);
