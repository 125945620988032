import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {AdType} from '../models/Ad/AdType';

import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ClassfieldTypeService extends BaseApiService {

  getAdTypes(): Observable<AdType[]> {

    return this.http.get<AdType[]>(`${this.apiUrl}/api/classfield-types/all`);
  }
}
