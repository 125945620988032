import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})

export class StatisticsService extends BaseApiService {

  public createDeleteAdStatisctic(value: string): Observable<any> {

    return this.http.post(`${this.apiUrl}/api/statistics/delete-ad-statistics`, { value });
  }
}
