import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector:    'app-icon',
  templateUrl: './icon.component.html',
  styleUrls:   ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() buttonSize = 'large';
  @Input() buttonColor = 'white';
  @Input() icon;
  @Input() iconColor  = 'primary';
  @Input() iconSize   = 'small';
  @Input() label;
  @Input() labelColor = 'dark';

  constructor() {
  }

  ngOnInit() {
  }

}
