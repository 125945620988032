import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitleAndDescriptionService {

  constructor(private titleService: Title,
              private metaService: Meta) { }

  public setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  public setDescription(content: string) {
    const tag = {
      name: 'description',
      content
    } as MetaDefinition;

    if (this.metaService.getTag('name=description') === null) {
      this.metaService.addTag(tag);
    } else {
      this.metaService.updateTag(tag, 'name=description');
    }
  }
}
