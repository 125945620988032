import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StripService {

  private breakHtmlStyle( content: string ): string {
    content = content.split('href=').join('');
    return content.split('style=').join('style');
  }

  public breakHtmlTags( content: string, isStandardInput: boolean ): string {

    content = content.split('<span').join('<p');
    content = content.split('</span>').join('</p>');
    content = content.split('<strong').join('<p');
    content = content.split('</strong>').join('</p>');
    content = content.split('<a').join('<p');
    content = content.split('</a>').join('</p>');

    if ( isStandardInput ) {

      content = content.split('<').join('< ');
      return content.split('< /').join('< /');
    } else {

      // &lt; = <
      // &gt; = >

      content = content.split('&lt;').join('&lt; ');
      return content.split('&gt;/').join('&gt; /');
    }
  }

  private breakLinks( content: string ): string {

    content = content.split('http://').join('');
    content = content.split('https://').join('');
    return content.split('www.').join('www .');
  }

  removeCharactersFromPrice( price: string ): number {
    return price ? +price.replace(/\D/g, '') : 1;
  }

  removeSpanAndStyles(content: string ): string {

    const indexOfFirst = content.indexOf('<span');

    if ( indexOfFirst !== -1 ) {
      const indexOfLast = this.findIndexOfClosingStartTag(content, indexOfFirst);

      const beginning = content.slice(0, indexOfFirst);
      const end = content.slice(indexOfLast, content.length);

      content = beginning + end;

      return content.split('</span>').join('');
    } else {
      return content;
    }
  }

  removeHtmlTags(content: string, tagName: string ): string {

    const indexOfFirst = content.indexOf('<' + tagName);

    if ( indexOfFirst !== -1 ) {
      const indexOfLast = this.findIndexOfClosingStartTag(content, indexOfFirst);

      const beginning = content.slice(0, indexOfFirst);
      const end = content.slice(indexOfLast, content.length);

      content = beginning + end;

      return content.split(`</${tagName}>`).join('');
    } else {
      return content;
    }
  }

  private findIndexOfClosingStartTag( content: string, startIndex: number): number {
    let lastIndex = startIndex;
    while (content.charAt(lastIndex) !== '>') {
      lastIndex++;
    }
    lastIndex++;

    return lastIndex > startIndex ? lastIndex : -1;
  }

  removeParagraphs( content: string ): string {
    content = content.split('<p>').join('');
    return  content.split('</p>').join('');
  }

  removeBold( content: string ): string {
    content = content.split('<b>').join('');
    return  content.split('</b>').join('');
  }

  public breakPotentionalHarmfullText( content: string, isStandardInput: boolean ): string {
    return this.breakLinks( this.breakHtmlTags( this.breakHtmlStyle(content), isStandardInput ));
  }
}
