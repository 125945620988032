import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { IonIcon, ModalController } from '@ionic/angular';

import { Rating } from '../../models/Rating/Rating';
import { Chat } from '../../models/Chat';

import { RatingService } from '../../services/rating.service';
import {RatingDto} from '../../models/Rating/RatingDto';
import {ToastService} from '../../services/toast.service';

@Component({
  selector: 'app-rate-user',
  templateUrl: './rate-user.component.html',
  styleUrls: ['./rate-user.component.scss'],
})
export class RateUserComponent implements OnInit, AfterViewInit {

  @Input() rating: Rating = undefined;
  @Input() chat: Chat     = undefined;

  ratingForm: UntypedFormGroup;
  ratingFormActive = false;
  submitted        = false;

  likeRating    = false;
  dislikeRating = false;

  @ViewChild('dislikeIcon') dislikeIcon: IonIcon;
  @ViewChild('likeIcon') likeIcon: IonIcon;

  scrollbarScss = '  @media(pointer: fine) {\n' +
    '    ::-webkit-scrollbar {\n' +
    '      width: 6px;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-track {\n' +
    '      background: #fff;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-track:hover {\n' +
    '      background: #f7f7f7;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-thumb {\n' +
    '      background: #ccc;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-thumb:hover {\n' +
    '      background: #888\n' +
    '    }\n' +
    '    .inner-scroll {\n' +
    '      scrollbar-width: thin\n' +
    '    }\n' +
    '  }';

  constructor(private modalController: ModalController,
              private formBuilder: UntypedFormBuilder,
              private ratingService: RatingService,
              private toastService: ToastService) { }

  ngOnInit() {

    this.buildRatingForm(this.rating, this.chat);
  }

  buildRatingForm( rating: Rating, chat: Chat ): void {

    this.ratingForm = this.formBuilder.group({
      id:        [rating ? rating.id         : null ],
      comment:   [ rating ? rating.comment   : null ],
      mark:      [ rating ? rating.mark      : null, [ Validators.required ]],
      chatId:    [ chat.id ],
    });

    this.ratingFormActive = true;
  }

  ngAfterViewInit(): void {

    if (this.rating){
      if (this.rating.mark) {
        this.changeRating(true);
      } else {
        this.changeRating(false);
      }
    }
  }

  changeRating(like: boolean): void {

    if ( like ) {

      this.likeRating = !this.likeRating;
      this.likeIcon.name = this.likeRating ? 'heart' : 'heart-outline';
      this.likeIcon.color = this.likeRating ? 'primary' : null;

      this.dislikeRating = false;
      this.dislikeIcon.color = 'null';
      this.dislikeIcon.name = 'heart-dislike-outline';

    } else {

      this.dislikeRating = !this.dislikeRating;
      this.dislikeIcon.name = this.dislikeRating ? 'heart-dislike' : 'heart-dislike-outline';
      this.dislikeIcon.color = this.dislikeRating ? 'primary' : null;

      this.likeRating = false;
      this.likeIcon.color = 'null';
      this.likeIcon.name = 'heart-outline';
    }

    if ( this.ratingFormActive ) {

      this.ratingForm.controls.mark.setValue(like ?
        (this.likeRating    ? 1 : null)
        :
        (this.dislikeRating ? 0 : null)
      );

      this.ratingForm.controls.mark.updateValueAndValidity();
    }
  }

  deleteRating(): void {


    this.ratingService.delete(this.rating.id).subscribe(
      response => {
        this.toastService.showSuccess('Ocena je uspešno obrisana.');
        this.resetAll();
        this.modalController.dismiss({ rating: null }, 'Submit');
    },
      error => {
        this.toastService.showError('Došlo je do greške prilikom brisanja oglasa');
      }
    );
  }

  resetAll(): void {

    this.ratingForm.reset();

    this.likeRating = false;
    this.likeIcon.color = 'null';
    this.likeIcon.name = 'heart-outline';

    this.dislikeRating = false;
    this.dislikeIcon.color = 'null';
    this.dislikeIcon.name = 'heart-dislike-outline';
  }

  onSubmit(): void {

    this.submitted = true;
    if (this.ratingForm.invalid) {
      return;
    }

    if ( !this.rating ) {

      this.ratingService.create( this.ratingForm.value as RatingDto ).subscribe(
        response => {
          this.toastService.showSuccess('Ocena je uspešno dodata.');
          this.modalController.dismiss({ rating: response }, 'Submit');
        },
        error => {
          this.toastService.showError(error.error.message);
        });
    } else {

      this.ratingService.update( this.ratingForm.value as RatingDto ).subscribe( response => {
        this.toastService.showSuccess('Ocena je uspešno izmenjena.');
        this.modalController.dismiss({ rating: response }, 'Submit');
      },
      error => {
        this.toastService.showError(error.error.message);
      });
    }
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true
    }, 'Cancel');
  }
}
