import { Input, Output, Component, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { CustomField } from '../../../models/CustomField/CustomField';
import { CustomFieldFilter } from '../../../models/CustomField/CustomFieldFilter';
import { AdsCustomFieldsData } from '../../../models/CustomField/AdsCustomFieldsData';

@Component({
  selector: 'app-custom-field-string',
  templateUrl: './custom-field-string.component.html',
  styleUrls: ['./custom-field-string.component.scss'],
})
export class CustomFieldStringComponent implements OnInit {

  @Input() classfieldId: number = undefined;
  @Input() type: string = null;
  @Input() filterEdit: boolean = null;
  @Input() customField: CustomField = undefined;

  @Input() index: number = null;
  @Input() formSubmitted = false;
  @Input() formG: any;

  form: UntypedFormGroup = undefined;
  formActive = false;

  @Output() selectedValue: EventEmitter<AdsCustomFieldsData | CustomFieldFilter>
    = new EventEmitter<AdsCustomFieldsData | CustomFieldFilter>();

  constructor( private formBuilder: UntypedFormBuilder ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void
  {
    this.form = this.formBuilder.group({
      value:           [this.customField ? this.customField.value : null ],
    });

    this.formActive = true;
  }

  onValueChanges(): void {
    if ( this.type === 'filter' ) {
      this.emmitCustomFieldFilter();
    }
  }

  emmitCustomFieldFilter(): void {

    this.selectedValue.emit({
      customFieldId: this.customField.id,
      type: this.customField.type,
      value: this.form.value.value,
    } as unknown as  CustomFieldFilter);
  }
}
