import {Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';

import { ClassfieldService } from '../../../home/classfield/classfield.service';

@Component({
             selector:    'app-number-popover',
             templateUrl: './number-popover.component.html',
             styleUrls:   ['./number-popover.component.scss'],
           })
export class NumberPopoverComponent implements OnInit, OnDestroy {

  @Input() adId: number;

  subscriptions: Subscription[] = [];

  phoneNumberImage: any;

  constructor( private classfieldService: ClassfieldService ) { }

  ngOnInit() {
    this.fetchNumber();
  }

  fetchNumber(): void {

    this.subscriptions.push(
      this.classfieldService.getPhoneNumberPicture( this.adId ).subscribe( response => {
        this.phoneNumberImage = response;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
    this.subscriptions = [];
  }

}
