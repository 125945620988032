import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseApiService } from './base-api.service';

import { Rating } from '../models/Rating/Rating';
import { RatingDto } from '../models/Rating/RatingDto';
import { PositiveAndNegativeUserRating } from '../models/Rating/PositiveAndNegativeUserRating';

@Injectable({
  providedIn: 'root'
})
export class RatingService extends BaseApiService {

   public ratings(pageIndex: number, pageSize: number, userId: number): Observable<any> {

    let params = new HttpParams();
    params     = (pageIndex ? params.set('pageIndex', JSON.stringify(pageIndex)) : params);
    params     = (pageSize ? params.set('pageSize', JSON.stringify(pageSize)) : params);

    return  this.http.get<any>(`${this.apiUrl}/api/ratings/users/${userId}`, { params });
  }

  public create( rating: RatingDto ): Observable<Rating> {

    return  this.http.post<Rating>(`${this.apiUrl}/api/ratings`, rating);
  }

  public update( rating: RatingDto ): Observable<Rating> {

    return  this.http.put<Rating>(`${this.apiUrl}/api/ratings/${rating.id}`, rating);
  }

  public delete( ratingId: number ): Observable<Rating> {

    return  this.http.delete<Rating>(`${this.apiUrl}/api/ratings/${ratingId}`);
  }

  public getPositiveAndNegativeUserRating(userId: number): Observable<PositiveAndNegativeUserRating> {

    return  this.http.get<PositiveAndNegativeUserRating>(`${this.apiUrl}/api/ratings/users/${userId}/positive-and-negative`);
  }
}


