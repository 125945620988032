import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { ModalController, IonSearchbar } from '@ionic/angular';

import { Category } from '../../../models/Category';

import { PlatformService } from '../../../services/platform.service';
import { CategoryTreeService } from '../../../services/category-tree.service';

import { FeedbackComponent } from '../../feedback/feedback.component';

import { AppState } from 'src/app/reducers';
import { loadedCategories } from '../../../store/category/selectors/category.selectors';
import { SearchService } from '../../../services/search.service';

@Component({
             selector:    'app-category-picker',
             templateUrl: './category-picker.component.html',
             styleUrls:   ['./category-picker.component.scss'],
           })
export class CategoryPickerComponent implements OnInit, OnDestroy {

  public categoriesForDisplay: Category[];
  public allCategories: Category[];

  feedbackModal: any;

  subscriptions: Subscription[] = [];

  @Input() chosenOne: Category                      = null;
  @Input() reset                                    = new UntypedFormControl(false);
  @Input() resetSearchBar                           = new UntypedFormControl(false);
  @Output() desktopCategory: EventEmitter<Category> = new EventEmitter<Category>();

  @ViewChild('ionSearchbar') ionSearchbar: IonSearchbar;

  constructor(public modalController: ModalController,
              public platformService: PlatformService,
              private store: Store<AppState>,
              private  categoryTreeService: CategoryTreeService,
              private searchService: SearchService
             ) {}

  ngOnInit() {
    this.fetchCategories();
    this.subscribeOnReset();
  }

  fetchCategories() {

    this.store
    .pipe(select(loadedCategories))
    .subscribe(response => {
      this.allCategories        = response;
      this.categoriesForDisplay = response;
    });
  }

  subscribeOnReset() {

    this.reset.valueChanges.subscribe(value => {
      if (value) {
        this.chosenOne = null;
      }
    });

    this.resetSearchBar.valueChanges.subscribe( value => {
      if (value && this.ionSearchbar) {
        this.ionSearchbar.value   = '';
      }
    });
  }

  /**
   * TODO(SSR) This method is split into two for mobile and desktop
   *
   * @param category
   */

  pickCategory(category) {

    this.chosenOne = this.chosenOne ? (this.chosenOne.id === category.id ?
      !category.parentId || category.parentId === 0 ? null :
        this.categoryTreeService.findCategoryById(this.allCategories, category.parentId)
      : category) : category;
  }

  pickCategoryDesktop(category) {

    this.pickCategory(category);
    this.desktopCategory.emit(this.chosenOne);
    this.ionSearchbar.value   = '';
  }

  closeModal() {

    this.modalController.dismiss({category: this.chosenOne});
  }

  searchCategories(event) {
    if (event.target.value.length === 0){
       this.categoriesForDisplay = this.categoryTreeService.closeCategories(this.allCategories);
    } else {
      const termsArray: string[] = [];
      this.searchService.findAllPermutations(event.target.value.toLowerCase(), termsArray, 0);
      this.categoriesForDisplay = this.searchCategoriesRecursive(this.allCategories, termsArray);
      this.categoriesForDisplay = this.openAllCategories(this.categoriesForDisplay);
      this.categoriesForDisplay =
        this.moveCategoriesToTheTopThatNameAtTheBeginningContainTermAsSubstring(this.categoriesForDisplay, termsArray);
      this.categoriesForDisplay = this.boldCategoriesRecursive(this.categoriesForDisplay, termsArray);
    }
  }

  boldCategoriesRecursive( categories: Category[], terms: string[] ): Category[] {

    if ( !categories ) { return; }
    return categories.map( category => {
      return {
        ...category,
        name: category.important ? category.name : this.searchService.boldText(category.name, terms),
        subcategories: this.boldCategoriesRecursive(category.subcategories, terms)
      };
    });
  }

  openAllCategories(categories: Category[]): Category[] {

    if ( !categories ) { return; }
    return categories.map(category => {
      return {
        ...category,
        isOpen: true,
        subcategories: this.openAllCategories(category.subcategories)
      };
    });
  }

  searchCategoriesRecursive(categories: Category[], terms: Array<string>): Category[] {

    if (!categories) { return; }

    return categories.reduce((filtered: Category[], category) => {

      const filteredChildren = this.searchCategoriesRecursive(category.subcategories, terms);

      if ( !filteredChildren || !filtered ) { return; }

      if (filteredChildren.length > 0) {
        filtered.push({
          ...category,
          subcategories: filteredChildren
        });
      }
      else  {
        if ( this.checkIfCategoryNameIsContainedInSomeOfTerms(category.name.toLowerCase(), terms)) {
          filtered.push({
            ...category,
            subcategories: []
          });
        }
      }

      return filtered;
    }, []);
  }

  checkIfCategoryNameIsContainedInSomeOfTerms( categoryName: string, terms: Array<string> ): boolean {

      let flag = false;
      terms.forEach((term: string) => {
        if (categoryName.indexOf(term.toLowerCase()) > -1) {
          flag = true;
        }
      });

      return flag;
  }

  moveCategoriesToTheTopThatNameAtTheBeginningContainTermAsSubstring(categories: Category[], terms: string[]): Category[] {

    if (!categories || categories.length === 0) {
      return;
    }

    this.searchService.displayFirstEntitesThatSubstringAtTheBeginingIsEqualToTerm(categories, terms, false);
    categories = this.searchService.boldSubstringOfEntitesThatAreEqualToTerms(categories, terms, true);

    categories.forEach(( category: Category ) => {
      this.moveCategoriesToTheTopThatNameAtTheBeginningContainTermAsSubstring(category.subcategories, terms);
    });

    return categories;
  }

  async openFeedbackModal() {
    this.feedbackModal = await this.modalController.create({
      component: FeedbackComponent,
      cssClass: 'feedback-modal-class',
      componentProps: {
        title: 'Nedostaje kategorija?',
        text: 'Ako ste primetili da neka kategorija nedostaje molimo vas da nas obavestite ovde.'
      }
    });

    return await this.feedbackModal.present();
  }

  cancelFilter() {
    this.modalController.dismiss({category: null});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
    this.chosenOne = null;
  }
}
