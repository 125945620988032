import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {ClassfieldService} from '../classfield.service';
import { catchError, map } from 'rxjs/operators';
import {Category} from '../../../_shared/models/Category';
import {Ad} from '../../../_shared/models/Ad/Ad';
import { ClassfieldEntityService} from '../classfield-entity.service';

@Injectable({
  providedIn: 'root'
})
export class ClassfieldEditorResolver implements Resolve<Ad> {

  constructor(private router: Router,
              private classfieldService: ClassfieldEntityService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Ad> | Observable<null> | null {

    return this.classfieldService.getByKey(+route.paramMap.get('id'))
      .pipe(
        catchError(() => {
          this.router.navigate(['/greska/stranica-nije-pronadjena'], { replaceUrl: false });
          return of(null);
        }),
        map(result => {

          if (result) {

            return result;
          } else {
            // Handle the case where result is not a Category
            // You might want to return null or throw an error
            return null; // or handle appropriately
          }
        })
      );
  }
}
