import { categoriesSuccess } from '../../../store/category/actions/category.actions';
import { CategoryTreeService } from '../../../services/category-tree.service';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import {IonButton, IonSearchbar, ModalController} from '@ionic/angular';

import { Keyboard } from '@ionic-native/keyboard/ngx';

import { Category } from '../../../models/Category';
import { CategoryService } from '../../../services/category.service';
import { select, Store } from '@ngrx/store';
import { loadedCategories } from '../../../store/category/selectors/category.selectors';
import { AppState } from 'src/app/reducers';
import { SearchService } from '../../../services/search.service';
import {LogService} from '../../../services/log.service';

@Component({
  selector: 'app-category-picker-mobile',
  templateUrl: './category-picker-mobile.component.html',
  styleUrls: ['./category-picker-mobile.component.scss'],
  providers: [ Keyboard ]
})
export class CategoryPickerMobileComponent implements OnInit, OnDestroy {

  allCategories: Category[] = [];
  categoriesForDisplay: Category[] = [];
  categoriesFromStore: any;

  selectedCategory: Category = undefined;

  subscriptions: Subscription[] = [];

  loading = false;

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  @ViewChild('ionSearchbar') ionSearchbar: IonSearchbar;

  constructor(public modalController: ModalController,
              public categoryService: CategoryService,
              public categoryTreeService: CategoryTreeService,
              private store: Store<AppState>,
              private searchService: SearchService,
              private logService: LogService ) { }

  ngOnInit() {
    this.fetchCategories();
    const modalState = {
      modal : true,
      desc : 'fake state for our modal'
    };
    history.pushState(modalState, null);
  }

  fetchCategories(category?: Category): void {

    this.loading = true;

    this.allCategories = [];
    this.categoriesForDisplay = [];

    if ( category ) {
      this.ionSearchbar.value   = '';
    }

    this.store
    .pipe(select( loadedCategories ))
    .subscribe(categories => {

     this.categoriesFromStore = categories;

    }).unsubscribe();

    if ( category ) {

      if ( category.subcategories && category.subcategories.length > 0 ) { //ako ima ucitane podkategorija u izabranu kategoriju
        this.allCategories = category.subcategories;
        this.categoriesForDisplay = category.subcategories;
        this.loading = false;

        this.createCategoryLog( category.id );
      } else { //ako nema ucitane podkategorija u izabranu kategoriju

        this.categoryService.categories(category.id).subscribe(
          response => {

            if ( response.length === 0 && this.selectedCategory) {
              this.modalController.dismiss({category: this.selectedCategory});
            } else {
              this.createCategoryLog( category.id );
            }

            const categoryTree = this.categoryTreeService.addSubcategoriesNoCollapse(category.id, this.categoriesFromStore, response);

            this.store.dispatch(categoriesSuccess({categories: categoryTree, openCategories: false}));

            this.allCategories = response;
            this.categoriesForDisplay = response;

            this.loading = false;


          },
          () => { this.loading = false; }
        );
      }
    } else {
        if (this.categoriesFromStore && this.categoriesFromStore.length === 0) {

          this.categoryService.categories(null).subscribe(
            response => {

              this.store.dispatch(categoriesSuccess({categories: response, openCategories: false}));

              this.allCategories = response;
              this.categoriesForDisplay = response;
              this.loading = false;

              this.createCategoryLog( category.id );
            },
          () => { this.loading = false; }
          );
        } else {

          this.allCategories = this.categoriesFromStore;
          this.categoriesForDisplay = this.categoriesFromStore;
          this.loading = false;

          this.createCategoryLog( category.id );
        }
    }

    this.selectedCategory = category;
  }

  fetchCategory(categoryId: number): void {

    this.loading = true;

    if ( categoryId === 0 ) {
      this.allCategories = this.categoriesFromStore;
      this.categoriesForDisplay = this.categoriesFromStore;
      this.selectedCategory = undefined;
    } else {
      this.fetchCategories(
        this.categoryTreeService.findCategoryById(this.categoriesFromStore, categoryId)
      );
    }

    this.loading = false;
  }

  createCategoryLog( categoryId: number ): void {
    this.logService.categoryPicked({categoryId, fingerprint: localStorage.getItem('fingerprint')}).subscribe();
  }

  searchCategories(event): void {

    this.categoriesForDisplay = [];
    const lowerCaseTerm = event.target.value.toLowerCase();
    const termsArray: string[] = [];
    this.searchService.findAllPermutations(lowerCaseTerm, termsArray, 0);

    if (termsArray.length === 0) {
      this.categoriesForDisplay = this.allCategories;

    } else {

      const filteredArray = [];

      termsArray.forEach((termFromArray: string) => {
        const filtered = this.allCategories.filter(category => {
          return category.name.toLowerCase().includes(termFromArray);
        });

        filtered.forEach(category => filteredArray.push(category));
      });

      this.categoriesForDisplay = this.searchService.removeDuplicates(filteredArray);
      this.searchService.displayFirstEntitesThatSubstringAtTheBeginingIsEqualToTerm(this.categoriesForDisplay, termsArray, false);
      this.categoriesForDisplay =
        this.searchService.boldSubstringOfEntitesThatAreEqualToTerms( this.categoriesForDisplay, termsArray, true );
    }
  }

  onSwipe(e: TouchEvent, when: string, category: Category) {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000 //
        && Math.abs(direction[0]) > 30
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
        const swipe = direction[0] < 0 ? 'next' : 'previous';

        if ( swipe === 'next') {
          this.fetchCategories(category);
        } else {
          if ( this.selectedCategory ) {
            this.fetchCategory(this.selectedCategory.parentId);
          } else {
            this.closeModal();
          }
        }

      }
    }
  }


  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.closeModal();
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if ( event.key === 'Enter' ) {
      const dummyButton = document.getElementById('dummyButton');
      dummyButton.focus();
    }
  }

  closeModal(category?: Category): void {

    this.modalController.dismiss({category});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];

    if (window.history.state.modal) {
      history.back();
    }
  }
}
