import { Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { PopoverController } from '@ionic/angular';

import { Location } from '../../../models/Location';
import { Category } from '../../../models/Category';

import { FilterCategoryComponent } from '../filter-category/filter-category.component';
import {LogService} from '../../../services/log.service';


@Component({
  selector: 'app-filter-base',
  templateUrl: './filter-base.component.html',
  styleUrls: ['./filter-base.component.scss']
})
export class FilterBaseComponent implements OnInit {

  @Input() chosenPriceFrom: number      = null;
  @Input() chosenPriceTo: number        = null;
  @Input() chosenCurrency: number       = null;
  @Input() chosenLocation: Location;
  @Input() chosenCategories: Category[] = [];

  @Input() locations: Location[];
  @Input() categoryHaveSubcategories    = true;
  @Input() reset                        = new UntypedFormControl(false);
  @Input() resetPriceFrom               = new UntypedFormControl(false);
  @Input() resetPriceTo                 = new UntypedFormControl(false);
  @Input() resetCurrency                = new UntypedFormControl(false);
  @Input() resetLocations               = new UntypedFormControl(false);

  filterForm: UntypedFormGroup;
  filterCategoryPopover: any;
  resetLocation = new UntypedFormControl(false);

  @Output() categoryEmmiter: EventEmitter<{ previousCategory: Category, currentCategory: Category, currentFilterState: any }>
    = new EventEmitter<{ previousCategory: Category, currentCategory: Category, currentFilterState: any }>();
  @Output() submitEmmiter: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelAllFilters: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private popoverControler: PopoverController,
    private logService: LogService
  ) { }

  ngOnInit() {
    this.buildFilterForm();
    this.subscribeOnReset();
  }

  buildFilterForm() {
    this.filterForm = this.formBuilder.group({
      priceFrom: [this.chosenPriceFrom],
      priceTo:   [this.chosenPriceTo],
      location:  [this.chosenLocation],
      currency:  [this.chosenCategories.length > 0 ? this.chosenCategories[this.chosenCategories.length - 1].preferredCurrency : 'rsd']
    });
  }

  subscribeOnReset(): void {

    this.reset.valueChanges.subscribe(value => {
      if (value) {
        this.resetFilterForm();
      }
    });

    this.resetPriceFrom.valueChanges.subscribe( value => {
      if ( value ){
        this.filterForm.controls.priceFrom.setValue(null);
      }
    });

    this.resetPriceFrom.valueChanges.subscribe( value => {
      if ( value ){
        this.filterForm.controls.priceTo.setValue(null);
      }
    });

    this.resetPriceFrom.valueChanges.subscribe( value => {
      if ( value ){
        this.resetLocation.setValue(true);
      }
    });

    this.resetPriceFrom.valueChanges.subscribe( value => {
      if ( value ){
        this.filterForm.controls.currency.setValue(null);
      }
    });
  }

  resetFilterForm(): void {
    this.filterForm.controls.priceFrom.setValue(null);
    this.filterForm.controls.priceTo.setValue(null);
    this.filterForm.controls.location.setValue(null);
    this.filterForm.controls.currency.setValue(null);
    this.resetLocation.setValue(true);
  }


  /**
   * Locations
   **/

  onLocationPicked(locationId: number): void {
    this.filterForm.controls.location.setValue(locationId);

    this.createLocationLog( locationId );
  }

  createLocationLog( locationId: number ): void {

    const fingerprint = localStorage.getItem('fingerprint');
    this.logService.locationPicked({ locationId, fingerprint }).subscribe();
  }

  /**
   * Categories
   **/

  async openCategoryFilterModal(category?: Category, ev?: any) {
    this.filterCategoryPopover = await this.popoverControler.create({
      component: FilterCategoryComponent,
      cssClass: 'searchable-select',
      mode: 'ios',
      event: ev,
      componentProps: {
       selectedCategory: category
      }
    });

    this.filterCategoryPopover.onDidDismiss().then(value => {
      if (value.role === 'category-selected'){
        this.categoryHaveSubcategories = value.data.categoryHaveSubcategories;
        this.categoryEmmiter.emit(
          { previousCategory: category, currentCategory: value.data.category, currentFilterState: this.filterForm.value });
      }
    });

    return await this.filterCategoryPopover.present();
  }

  removeBoldHtmlTagFromCategoryName( categoryName: string ): string {
    categoryName = categoryName.split('<b>').join('');
    return categoryName.split('</b>').join('');
  }

  /**
   * Events
   * */

  onSubmit() {
    this.submitEmmiter.emit(this.filterForm.value);
  }

  onCancel(): void {
    this.cancelAllFilters.emit();
  }
}
