import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, filter, first, tap} from 'rxjs/operators';
import { ClassfieldEntityService } from '../classfield-entity.service';
import {Ad} from '../../../_shared/models/Ad/Ad';
import {Category} from '../../../_shared/models/Category';
import {ClassfieldService} from '../classfield.service';

@Injectable()
export class ClassfieldResolver implements Resolve<any> {

  constructor( private classfieldEntityService: ClassfieldEntityService) {
  }

  // @ts-ignore
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any | Observable<any> | Observable<boolean> | null | boolean  {

      return this.classfieldEntityService.getWithQuery({
          pageNumber: route.queryParamMap.has('page') ? route.queryParamMap.get('page') : '0',
          pageSize:   '10',
          category: route.paramMap.get('categorySlug')
        }).subscribe();
  }
}
