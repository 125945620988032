import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EntityDataService, EntityDefinitionService } from '@ngrx/data';
import { ClassfieldListCardComponent } from './components/classfield/classfield-list-card/classfield-list-card.component';
import { ClassfieldListHolderComponent } from './components/classfield/classfield-list-holder/classfield-list-holder.component';
import { ClassfieldMenuComponent } from './components/classfield/classfield-menu/classfield-menu.component';
import { ClassfieldSkeletonLoadingComponent } from './components/classfield/classfield-skeleton-loading/classfield-skeleton-loading.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { IconComponent } from './components/icon/icon.component';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './components/header/header.component';
import { CategoryPickerComponent } from './components/category/category-picker/category-picker.component';
import { LoginComponent } from './components/auth/login/login.component';
import { LogoComponent } from './components/logo/logo.component';
import { FilterPickerComponent } from './components/filter-picker/filter-picker.component';
import { NumberPopoverComponent } from './components/number-popover/number-popover.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { ClassfieldsSkeletonLoadingComponent } from './components/classfield/classfields-skeleton-loading/classfields-skeleton-loading.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ClassfieldListFilterInfoComponent } from './components/classfield/classfield-list-filter-info/classfield-list-filter-info.component';
import { ClassfieldCategorySelectorComponent } from './components/classfield/classfield-category-selector/classfield-category-selector.component';
import { CategoryPickerItemComponent } from './components/category/category-picker-item/category-picker-item.component';
import { CategoryPickerMobileComponent } from './components/category/category-picker-mobile/category-picker-mobile.component';
import { InvisibleReCaptchaComponent } from './components/invisible-recaptcha/invisible-recaptcha.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { LoaderComponent } from './components/loader/loader.component';
import { QuillModule } from 'ngx-quill';
import { IonCustomScrollbarModule } from 'ion-custom-scrollbar';
import { SearchableSelectPopoverComponent } from './components/searchable-select/searchable-select-popover/searchable-select-popover.component';
import { ChatListHolderComponent } from './components/chat/chat-list-holder/chat-list-holder.component';
import { ChatCardComponent } from './components/chat/chat-card/chat-card.component';
import { ChatHeaderComponent } from './components/chat/chat-header/chat-header.component';
import { MessageComponent } from './components/message/message/message.component';
import { MessageListComponent } from './components/message/message-list/message-list.component';
import { DeleteConfirmationComponent } from './components/delete-confirmation/delete-confirmation.component';
import { InboxIconComponent } from './components/inbox-icon/inbox-icon.component';
import {
  MenuButtonNotificationsComponent
} from './components/menu-button-notifications/menu-button-notifications.component';
import {DesktopSideMenuComponent } from './components/side-menu/desktop-side-menu/desktop-side-menu.component';
import {MobileSideMenuComponent } from './components/side-menu/mobile-side-menu/mobile-side-menu.component';
import {
  CompanySearchableSelectComponent
} from './components/searchable-select/company-searchable-select/company-searchable-select.component';
import {SearchableSelectComponent} from "./components/searchable-select/searchable-select/searchable-select.component";

import { RateUserComponent } from './components/rate-user/rate-user.component';
import { RatingListHolderComponent } from './components/rating-list-holder/rating-list-holder.component';
import { RatingCardComponent } from './components/rating-card/rating-card.component';
import { CustomFieldSelectComponent } from './components/custom-field/custom-field-select/custom-field-select.component';
import { CustomFieldMultiselectComponent } from './components/custom-field/custom-field-multiselect/custom-field-multiselect.component';
import { CustomFieldNumberComponent } from './components/custom-field/custom-field-number/custom-field-number.component';
import {
  CustomFieldDataContainerComponent
} from './components/custom-field/custom-field-data-container/custom-field-data-container.component';
// tslint:disable-next-line:max-line-length
import { CustomFieldFilterPickerComponent } from './components/custom-field/custom-field-filter-picker/custom-field-filter-picker.component';
import { YesNoDialogComponent } from './components/yes-no-dialog/yes-no-dialog.component';
import { ClassfieldTypePopoverComponent } from './components/classfield-type-popover/classfield-type-popover.component';
import { DeleteFeedbackComponent } from './components/delete-feedback/delete-feedback.component';
import { CustomFieldStringComponent } from './components/custom-field/custom-field-string/custom-field-string.component';

import { FilterContainerComponent } from './components/filters/filter-container/filter-container.component';
import { FilterBaseComponent } from './components/filters/filter-base/filter-base.component';
import { FilterCategoryComponent } from './components/filters/filter-category/filter-category.component';
import { TransactionListHolderComponent } from './components/transaction/transaction-list-holder/transaction-list-holder.component';

import { SafePipe } from './pipes/safe.pipe';
import { BannerComponent } from './components/banner/banner.component';
import { AdsenseModule } from 'ng2-adsense';
import {HomeTabsComponent} from './components/home-tabs/home-tabs.component';

@NgModule({
            declarations: [
              ClassfieldListCardComponent,
              ClassfieldListFilterInfoComponent,
              ClassfieldCategorySelectorComponent,
              BreadcrumbComponent,
              CategoryPickerMobileComponent,
              CategoryPickerComponent,
              CategoryPickerItemComponent,
              FilterPickerComponent,
              FooterComponent,
              HeaderComponent,
              MobileSideMenuComponent,
              IconComponent,
              LogoComponent,
              LoaderComponent,
              LoginComponent,
              ClassfieldListHolderComponent,
              NumberPopoverComponent,
              ForgotPasswordComponent,
              ClassfieldMenuComponent,
              RegisterComponent,
              UserDetailComponent,
              FeedbackComponent,
              ClassfieldSkeletonLoadingComponent,
              ClassfieldsSkeletonLoadingComponent,
              InvisibleReCaptchaComponent,
              DesktopSideMenuComponent,
              SearchableSelectComponent,
              SearchableSelectPopoverComponent,
              CompanySearchableSelectComponent,
              ChatListHolderComponent,
              ChatCardComponent,
              ChatHeaderComponent,
              MessageComponent,
              MessageListComponent,
              DeleteConfirmationComponent,
              InboxIconComponent,
              MenuButtonNotificationsComponent,
              RateUserComponent,
              RatingListHolderComponent,
              RatingCardComponent,
              CustomFieldNumberComponent,
              CustomFieldStringComponent,
              CustomFieldSelectComponent,
              CustomFieldMultiselectComponent,
              CustomFieldFilterPickerComponent,
              CustomFieldDataContainerComponent,
              YesNoDialogComponent,
              ClassfieldTypePopoverComponent,
              DeleteFeedbackComponent,
              FilterContainerComponent,
              FilterBaseComponent,
              FilterCategoryComponent,
              SafePipe,
              BannerComponent,
              TransactionListHolderComponent,
              HomeTabsComponent
            ],
            exports: [
              ClassfieldListCardComponent,
              ClassfieldListFilterInfoComponent,
              ClassfieldCategorySelectorComponent,
              IconComponent,
              LogoComponent,
              HeaderComponent,
              MobileSideMenuComponent,
              CategoryPickerMobileComponent,
              CategoryPickerComponent,
              CategoryPickerItemComponent,
              FilterPickerComponent,
              FooterComponent,
              BreadcrumbComponent,
              ClassfieldListHolderComponent,
              LoginComponent,
              LoaderComponent,
              RegisterComponent,
              UserDetailComponent,
              FeedbackComponent,
              ClassfieldSkeletonLoadingComponent,
              InvisibleReCaptchaComponent,
              DesktopSideMenuComponent,
              SearchableSelectComponent,
              CompanySearchableSelectComponent,
              ChatListHolderComponent,
              ChatCardComponent,
              ChatHeaderComponent,
              MessageComponent,
              MessageListComponent,
              DeleteConfirmationComponent,
              InboxIconComponent,
              MenuButtonNotificationsComponent,
              RateUserComponent,
              RatingListHolderComponent,
              RatingCardComponent,
              CustomFieldNumberComponent,
              CustomFieldStringComponent,
              CustomFieldSelectComponent,
              CustomFieldMultiselectComponent,
              CustomFieldFilterPickerComponent,
              CustomFieldDataContainerComponent,
              YesNoDialogComponent,
              ClassfieldTypePopoverComponent,
              DeleteFeedbackComponent,
              FilterContainerComponent,
              FilterBaseComponent,
              FilterCategoryComponent,
              SafePipe,
              BannerComponent,
              TransactionListHolderComponent,
              HomeTabsComponent
            ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    QuillModule.forRoot(),
    IonCustomScrollbarModule,
    FormsModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-6682808282586535',
      adSlot: 1574216487,
    }),
  ],
            providers:    [
              {
                provide:  HTTP_INTERCEPTORS,
                useClass: TokenInterceptor,
                multi:    true
              },
              {
                provide:  HTTP_INTERCEPTORS,
                useClass: LanguageInterceptor,
                multi:    true
              },
              AuthGuard,
              GuestGuard
            ]
          })
export class SharedModule {

  constructor(private eds: EntityDefinitionService,
              private entityDataService: EntityDataService) {

  }
}
