import { Injectable } from '@angular/core';
import {HttpParams} from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseApiService } from './base-api.service';

import { CreateMessage } from '../models/CreateMessage';

@Injectable({
  providedIn: 'root'
})

export class ChatService extends BaseApiService {

  public chats(pageIndex: number, pageSize: number, sort: string): Observable<any> {

    let params = new HttpParams();
    params     = (pageIndex ? params.set('pageIndex', JSON.stringify(pageIndex)) : params);
    params     = (pageSize ? params.set('pageSize', JSON.stringify(pageSize)) : params);
    params     = (sort ? params.set('sort', JSON.stringify(sort)) : params);

    return  this.http.get(`${this.apiUrl}/api/chats`, { params });
  }

  public chat(chatId: number): Observable<any> {

    return  this.http.get(`${this.apiUrl}/api/chats/${chatId}`);
  }

  public unreads(): Observable<number[]> {

    return  this.http.get<number[]>(`${this.apiUrl}/api/chats/unreads`);
  }

  public messages(chatId: number, pageIndex: number, pageSize: number): Observable<any> {

    let params = new HttpParams();
    params     = (pageIndex ? params.set('pageIndex', JSON.stringify(pageIndex)) : params);
    params     = (pageSize ? params.set('pageSize', JSON.stringify(pageSize)) : params);

    return  this.http.get(`${this.apiUrl}/api/chats/${chatId}/messages`, { params });
  }

  public readMessage(chatId: number): Observable<any> {

    return  this.http.post(`${this.apiUrl}/api/chats/${chatId}/read-messages`, {});
  }

  public sendMessage(message: CreateMessage): Observable<any> {

    return this.http.post(`${this.apiUrl}/api/chats/create-message`, message );
  }

  public checkIfChatExist(toUserId: number, classfieldId: number): Observable<any> {

    let params = new HttpParams();
    params     = (toUserId ? params.set('toUserId', JSON.stringify(toUserId)) : params);
    params     = (classfieldId ? params.set('classfieldId', JSON.stringify(classfieldId)) : params);

    return this.http.get(`${this.apiUrl}/api/chats/does-chat-exist`, { params } );
  }


}
