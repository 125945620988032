import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';
import { isNotLoggedIn } from '../../auth/auth.selectors';
import { AppState } from '../../reducers';

@Injectable({
              providedIn: 'root'
            })
export class GuestGuard implements CanActivate {

  private isGuest$: Observable<any> = this.store.pipe(select(isNotLoggedIn));

  constructor(private store: Store<AppState>,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.isGuest$
      .pipe(
        filter(res => (res === true || res === false)),
        tap(res => res ? res : this.router.navigateByUrl('/'))
      );
  }
}
