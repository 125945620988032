import { Component, OnInit } from '@angular/core';
import { PlatformService } from '../../../services/platform.service';

@Component({
             selector:    'app-classfields-skeleton-loading',
             templateUrl: './classfields-skeleton-loading.component.html',
             styleUrls:   ['./classfields-skeleton-loading.component.scss'],
           })
export class ClassfieldsSkeletonLoadingComponent implements OnInit {

  constructor(public platformService: PlatformService) {
  }

  ngOnInit() {
  }

}
