import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {

  public apiUrl: string;

  constructor(public http: HttpClient) {

    this.apiUrl = environment.baseUrl;
  }
}
