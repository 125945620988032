import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_shared/guards/auth.guard';
import { ClassfieldEditorResolver } from './resolvers/classfield-editor.resolver';
import {VerificationGuard} from '../../_shared/guards/verification.guard';

const routes: Routes = [
  {
    path:     '',
    children: [
      {
        path:       '',
        redirectTo: '/',
        pathMatch:  'full'
      },
      {
        path:         'dodaj-oglas',
        loadChildren: () => import('./classfield-editor/classfield-editor.module').then(m => m.ClassfieldEditorPageModule),
        canActivate: [AuthGuard, VerificationGuard]
      },
      {
        path:         'dodaj-oglas/uspesno',
        loadChildren:
          () => import('./classfield-editor-success/classfield-editor-success.module').then(m => m.ClassfieldEditorSuccessPageModule),
        canActivate: [AuthGuard, VerificationGuard]
      },
      {
        path:         'omiljeni',
        loadChildren: () => import('./classfield-favorites/classfield-favorites.module').then(m => m.ClassfieldFavoritesPageModule),
        canActivate: [AuthGuard, VerificationGuard]
      },
      {
        path: 'oglasi-korisnika',
        loadChildren: () => import('./classfield-owner/classfield-owner.module').then(m => m.ClassfieldOwnerPageModule)
      },
      {
        path:         ':id/izmeni-oglas',
        loadChildren: () => import('./classfield-editor/classfield-editor.module').then(m => m.ClassfieldEditorPageModule),
        canActivate:  [ AuthGuard, VerificationGuard ],
        resolve:      { ad: ClassfieldEditorResolver }
      }
    ]
  }
];

@NgModule({
            imports: [RouterModule.forChild(routes)],
            exports: [RouterModule],
          })
export class ClassfieldPageRoutingModule {
}
