import { ViewChild, AfterViewInit, OnInit, Component, Input } from '@angular/core';
import { IonSearchbar, PopoverController } from '@ionic/angular';

import { select, Store } from '@ngrx/store';

import { SearchService } from '../../../services/search.service';
import { CategoryService } from '../../../services/category.service';
import { CategoryTreeService } from '../../../services/category-tree.service';

import { Category } from '../../../models/Category';

import { AppState } from '../../../../reducers';
import { loadedCategories } from '../../../store/category/selectors/category.selectors';
import { categoriesSuccess } from '../../../store/category/actions/category.actions';

@Component({
  selector: 'app-filter-category',
  templateUrl: './filter-category.component.html',
  styleUrls: ['./filter-category.component.scss'],
})
export class FilterCategoryComponent implements AfterViewInit, OnInit {

  @Input() selectedCategory: Category = null;

  categoriesFromStore: any;
  allCategories: Category[]        = [];
  categoriesForDisplay: Category[] = [];

  @ViewChild('ionSearchbar') ionSearchbar: IonSearchbar;

  scrollbarScss = '  @media(pointer: fine) {\n' +
    '    ::-webkit-scrollbar {\n' +
    '      width: 6px;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-track {\n' +
    '      background: #fff;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-track:hover {\n' +
    '      background: #f7f7f7;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-thumb {\n' +
    '      background: #ccc;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-thumb:hover {\n' +
    '      background: #888\n' +
    '    }\n' +
    '    .inner-scroll {\n' +
    '      scrollbar-width: thin\n' +
    '    }\n' +
    '  }';

  constructor(
              private store: Store<AppState>,
              private searchService: SearchService,
              public categoryService: CategoryService,
              public popoverController: PopoverController,
              public categoryTreeService: CategoryTreeService
              ) { }

  ngOnInit(): void {
    this.fetchCategories(this.selectedCategory);
    }

  ngAfterViewInit(): void {

    if ( this.selectedCategory ){
      this.ionSearchbar.value   = '';
    }

    setTimeout(() => {
      this.ionSearchbar.setFocus();
    }, 500);
  }

  fetchCategories(category?: Category): void {

    this.allCategories = [];
    this.categoriesForDisplay = [];

    this.loadCategoriesFromStore();

    if ( category ) {

      if ( category.subcategories && category.subcategories.length > 0 ) {
        this.allCategories = category.subcategories;
        this.categoriesForDisplay = category.subcategories;
      } else {

        this.categoryService.categories(category.id).subscribe(
          response => {
            if ( response.length === 0 && this.selectedCategory) {
              this.popoverController.dismiss({category: this.selectedCategory});
            }

            const categoryTree = this.categoryTreeService.addSubcategoriesNoCollapse(category.id, this.categoriesFromStore, response);

            this.store.dispatch(categoriesSuccess({categories: categoryTree, openCategories: false}));

            this.allCategories = response;
            this.categoriesForDisplay = response;
          }
        );
      }
    } else {
      if (this.categoriesFromStore && this.categoriesFromStore.length === 0) {

        this.categoryService.categories(null).subscribe(
          response => {

            this.store.dispatch(categoriesSuccess({categories: response, openCategories: false}));

            this.allCategories = response;
            this.categoriesForDisplay = response;
          }
        );
      } else {
        this.allCategories = this.categoriesFromStore;
        this.categoriesForDisplay = this.categoriesFromStore;
      }
    }

    this.selectedCategory = category;
  }

  loadCategoriesFromStore(): void {
    this.store
      .pipe(select( loadedCategories ))
      .subscribe(categories => {
        this.categoriesFromStore = categories;
      }).unsubscribe();
  }

  searchCategories(event): void {

    this.categoriesForDisplay = [];

    const lowerCaseTerm = event.target.value.toLowerCase();

    const termsArray: string[] = [];

    this.searchService.findAllPermutations(lowerCaseTerm, termsArray, 0);

    if ( termsArray.length === 0) {
      this.categoriesForDisplay = this.allCategories;
    } else {
      const filteredArray = [];

      termsArray.forEach((termFromArray: string) => {
        const filtered = this.allCategories.filter(category => {
          return category.name.toLowerCase().includes(termFromArray);
        });

        filtered.forEach(category => filteredArray.push(category));
      });

      this.categoriesForDisplay = this.searchService.removeDuplicates(filteredArray);
      this.searchService.displayFirstEntitesThatSubstringAtTheBeginingIsEqualToTerm( this.categoriesForDisplay, termsArray, false);
      this.categoriesForDisplay =
        this.searchService.boldSubstringOfEntitesThatAreEqualToTerms( this.categoriesForDisplay, termsArray, true );

    }
  }

  closeModal(category?: Category): void {
    if ( !category ) {
      this.popoverController.dismiss(null, 'cancel');
    } else {
      this.categoryService.categories(category.id).subscribe( response => {
        this.popoverController.dismiss({category, categoryHaveSubcategories: response.length === 0 ? false : true}, 'category-selected');
      });
    }
  }
}
