import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { IonSelect } from '@ionic/angular';

import { Category } from '../../../models/Category';

@Component({
  selector: 'app-classfield-category-selector',
  templateUrl: './classfield-category-selector.component.html',
  styleUrls: ['./classfield-category-selector.component.scss'],
})
export class ClassfieldCategorySelectorComponent implements AfterViewInit {

  @Input()  categories: Category[];

  @Input()  isRoot = true;

  @Output() categorySelected: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('ionSelect') ionSelect: IonSelect;

  ngAfterViewInit(): void {

    if (!this.isRoot && !this.ionSelect.value) {
      this.ionSelect.mode = 'ios';
      this.ionSelect.interface = 'alert';
      this.ionSelect.open();
    }
  }

  getSelectedValue() {
    return this.categories.find(category => category.isSelected === true)?.id;
  }

  onSelectCategory(chosenCategoryEvent): void {

    const categoryId = chosenCategoryEvent.target.value;

    this.categorySelected.emit(categoryId);

  }

  onSelectCategoryPipeline( selectedCategoryId ) {
    this.categorySelected.emit( selectedCategoryId );
  }

  categoryHasSubcategories(categoryId: number): boolean {
    return this.categories.find(category => category.id === categoryId)?.subcategories.length === 0;
  }

  focusTopSelect(): void {

    if ( this.isRoot ) {
      this.ionSelect.open();
    }
  }
}
