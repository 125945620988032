import { OnInit, Component, Input, HostListener } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ModalController } from '@ionic/angular';

import { StatisticsService } from '../../services/statistics.service';
import { ClassfieldService } from '../../../home/classfield/classfield.service';
import { ToastService } from '../../services/toast.service';
import { ClassfieldEntityService } from '../../../home/classfield/classfield-entity.service';

@Component({
  selector: 'app-delete-feedback',
  templateUrl: './delete-feedback.component.html',
  styleUrls: ['./delete-feedback.component.scss'],
})
export class DeleteFeedbackComponent implements OnInit {

  @Input() classfieldId: number = undefined;
  @Input() admin: boolean = undefined;
  @Input() isProfilePage: boolean = undefined;

  public form: UntypedFormGroup;
  public  submitted  = false;
  public  formActive = false;

  constructor( private formBuilder: UntypedFormBuilder,
               private toastService: ToastService,
               private modalControler: ModalController,
               private statisticsService: StatisticsService,
               private classfieldService: ClassfieldService,
               private adEntityService: ClassfieldEntityService,
               private router: Router) { }

  ngOnInit() {
    this.createForm();
  }

  createForm(): void {

    this.form = this.formBuilder.group({
      value: [ '', Validators.required ]
    });

    this.formActive = true;
  }

  onLabelClick( value: string ): void {
    this.form.controls.value.setValue(value);
    this.form.controls.value.updateValueAndValidity();
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if ( event.code === 'Space' && this.admin ) {
      this.onSubmit();
    }
  }

  onSubmit(): void {

    if ( this.form.invalid && !this.admin ) {
      this.submitted = true;
      return;
    }

    this.deleteAd(this.form.controls.value.value);
  }


  deleteAd( reasonForDeletingAd: string ) {

    this.adEntityService.delete(this.classfieldId).subscribe(
      () => {
        if ( !this.admin ){
          this.createDeleteAdStatistics(reasonForDeletingAd, this.isProfilePage);
        }
        this.modalControler.dismiss(null, 'delete-refresh');
        this.router.navigate(['/']);
      },
      error => this.toastService.showError(error.error.message)
    );
  }

  createDeleteAdStatistics(reasonForDeletingAd: string, refresh: boolean): void {

    this.statisticsService.createDeleteAdStatisctic(reasonForDeletingAd).subscribe(
      response => { this.modalControler.dismiss(null, refresh ? 'delete-refresh' : 'refresh' ); },
      error =>   { this.modalControler.dismiss(null, 'delete-failed'); }
    );
  }

  onCancel(): void {
    this.modalControler.dismiss(null, 'cancel' );
  }
}
