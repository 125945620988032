import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss'],
})
export class DeleteConfirmationComponent {

  constructor(private popoverController: PopoverController) { }

  onChoose(value: boolean) {
    this.popoverController.dismiss(value, value ? 'delete' : 'cancel');
  }
}
