import {Ad} from '../../../models/Ad/Ad';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {filter} from 'rxjs/operators';
import {authUser} from '../../../../auth/auth.selectors';
import {AppState} from '../../../../reducers';
import {User} from '../../../models/User';
import {PlatformService} from '../../../services/platform.service';
import {SettingsService} from '../../../services/settings.service';
import {ClassfieldService} from '../../../../home/classfield/classfield.service';
import {updateCredit} from '../../../../auth/auth.actions';
import {ToastService} from '../../../services/toast.service';
import {ClassfieldTypeService} from '../../../services/classfield-type.service';
import {AdType} from '../../../models/Ad/AdType';


import {classfieldCount} from '../../../store/classfield-count/selectors/classfield-count.selectors';

import SortEnum from '../../../enums/sort.enum';

@Component({
             selector:    'app-classfield-list-holder',
             templateUrl: './classfield-list-holder.component.html',
             styleUrls:   ['./classfield-list-holder.component.scss'],
           })
export class ClassfieldListHolderComponent implements OnInit {

  @Input()  ads: Ad[]       = [];
  @Input()  owned           = false;
  @Input()  isLoading;
  @Input()  listEnd         = false;
  @Input()  classfieldCount = undefined;

  loading = true;
  authUser: User = null;
  classfieldTypes: any[] = [];

  sortControl: UntypedFormControl;
  renewClassfieldPrice: number = undefined;

  @Output() loadData: EventEmitter<void> = new EventEmitter<void>();
  @Output() sortData: EventEmitter<SortEnum> = new EventEmitter<SortEnum>();
  @Output() refreshMainPageEmmiter: EventEmitter<void> = new EventEmitter<void>();

  enumKeys: any;
  admin = false;

  constructor(public platformService: PlatformService,
              private store: Store<AppState>,
              private settingService: SettingsService,
              private classfieldService: ClassfieldService,
              private toastService: ToastService,
              private classfieldTypeService: ClassfieldTypeService) {}


  ngOnInit() {
    this.sortControl = new UntypedFormControl(SortEnum.id);
    this.sortControl.valueChanges.subscribe(value => {
      this.sortData.emit(value as SortEnum);
    });
    this.getAuthenticatedUser();
    this.fetchRenewClassfieldPrice();
    this.fetchClassfieldTypes();
    this.getClassfieldCount();

    this.enumKeys = Object.keys(SortEnum).filter(String);

    this.admin = localStorage.getItem('role')?.includes('admin') ? true : false;
  }

  returnEnum(sort: SortEnum): SortEnum {
    if (sort === SortEnum.id){
      return SortEnum.id;
    }
    if (sort === SortEnum.price_low){
      return SortEnum.price_low;
    }
    if (sort === SortEnum.price_high){
      return SortEnum.price_high;
    }
    if (sort === SortEnum.refreshedAt){
      return SortEnum.refreshedAt;
    }
  }

  /**
   * Fetch authenticated user data from store.
   *
   * @return void
   */
  getAuthenticatedUser(): void {

    this.store
      .pipe(select(authUser))
      .pipe(filter(user => !!user))
      .subscribe(response => {
        this.authUser = response;
      });
  }

  fetchRenewClassfieldPrice(): void {
    this.settingService.getRenewClassfieldPrice().subscribe( response => {
      this.renewClassfieldPrice = response.value;
    });
  }

  fetchClassfieldTypes(): void {

    this.classfieldTypes = [];
    this.loading = false;

    this.classfieldTypeService.getAdTypes().subscribe(response => {
      this.classfieldTypes = response;
      this.loading = false;
    });
  }

  getClassfieldCount(): void {
    if ( !this.classfieldCount && !this.owned ) {
      this.store
        .pipe(select( classfieldCount ))
        .subscribe(data => {
          this.classfieldCount = data.count;
        });
    }
  }

  findClassfieldTypeName( classfieldId: number ): string {
    return this.classfieldTypes.find(classfieldType => classfieldType.id === classfieldId)?.name;
  }

  onLoadData(event) {

    this.loadData.emit();
    event.target.complete();
  }

  refreshMainPage(): void {
    this.refreshMainPageEmmiter.emit();
  }

  strikeAd($event): void {
    this.classfieldService.strike($event).subscribe(
      () => { this.refreshMainPage(); },
      () => { this.toastService.showError('Došlo je do greške prilikom dodavanja strajka'); }
    );
  }

  renewClassfield( classfieldId: number ): void {
    this.classfieldService.setClassfieldOnTop(classfieldId).subscribe(response => {
        const newCreditValue = this.authUser.credit - this.renewClassfieldPrice;
        this.store.dispatch(updateCredit({ credit: newCreditValue }));
        this.refreshMainPageEmmiter.emit();
        this.toastService.showSuccess('Uspešno ste obnovili oglas!');
        },
      error => this.toastService.showError(this.authUser.credit < this.renewClassfieldPrice ?
        'Nemate dovoljno poena za ovu uslugu' :
        error.error.message));
  }
}
