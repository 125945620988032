import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

import { ReportAd } from '../models/ReportAd';
import { Feedback } from '../models/Feedback';

@Injectable({
              providedIn: 'root'
            })

export class FeedbackService extends BaseApiService {

  public seendFeedback(feedback: Feedback): Observable<any> {

    return this.http.post(`${this.apiUrl}/api/feedback`,  feedback );
  }

  public reportAd(reportAd: ReportAd): Observable<any> {

    return this.http.post(`${this.apiUrl}/api/feedback/report-classfield`, reportAd );
  }
}
