import { createReducer, on } from '@ngrx/store';

import { ClassfieldCountActions } from '../action-types';

export const classfieldCountFeatureKey = 'classfield-count';

export const initialClassfieldCount = {
  count: 0
};

export const classfieldCountReducer = createReducer(

  initialClassfieldCount,
  
  on(ClassfieldCountActions.classfieldCountSuccess, (state, _action) => {
    return {
      count: _action.count
    };
  })
);
