import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SearchService {

  public findAllPermutations(term: string, termArray: string[], currentPosition: number): void {
    if ( currentPosition >= term.length || term.length === 0 ) { return; }

    if ( term.charAt(currentPosition) ===  's' || term.charAt(currentPosition) === 'š' ) {
      const newTerm = this.setCharAt(term, currentPosition,  term.charAt(currentPosition) ===  's' ? 'š' : 's');

      termArray.push(newTerm);
      this.findAllPermutations(newTerm, termArray, currentPosition + 1);

      if ( termArray.findIndex(str => str === term) === -1 ) {
        termArray.push(term);
      }
      this.findAllPermutations(term, termArray, currentPosition + 1);
    } else if (term.charAt(currentPosition) === 'c' || term.charAt(currentPosition) === 'ć' || term.charAt(currentPosition) === 'č') {

      if (term.charAt(currentPosition) === 'c') {

        let newTerm = this.setCharAt(term, currentPosition, 'ć');
        termArray.push(newTerm);
        this.findAllPermutations(newTerm, termArray, currentPosition + 1);

        newTerm = this.setCharAt(term, currentPosition, 'č');
        termArray.push(newTerm);
        this.findAllPermutations(newTerm, termArray, currentPosition + 1);
      } else if (term.charAt(currentPosition) === 'ć') {
        // tslint:disable-next-line:no-shadowed-variable
        let newTerm = this.setCharAt(term, currentPosition, 'c');
        termArray.push(newTerm);
        this.findAllPermutations(newTerm, termArray, currentPosition + 1);

        newTerm = this.setCharAt(term, currentPosition, 'č');
        termArray.push(newTerm);
        this.findAllPermutations(newTerm, termArray, currentPosition + 1);

      } else {
        // tslint:disable-next-line:no-shadowed-variable
        let newTerm = this.setCharAt(term, currentPosition, 'c');
        termArray.push(newTerm);
        this.findAllPermutations(newTerm, termArray, currentPosition + 1);

        newTerm = this.setCharAt(term, currentPosition, 'ć');
        termArray.push(newTerm);
        this.findAllPermutations(newTerm, termArray, currentPosition + 1);
      }

      if ( termArray.findIndex(str => str === term) === -1 ) {
        termArray.push(term);
      }
      this.findAllPermutations(term, termArray, currentPosition + 1);
    } else if (term.charAt(currentPosition) === 'z' || term.charAt(currentPosition) === 'ž') {

      const newTerm = this.setCharAt(term, currentPosition, term.charAt(currentPosition) === 'z' ? 'ž' : 'z');

      termArray.push(newTerm);
      this.findAllPermutations(newTerm, termArray, currentPosition + 1);

      if ( termArray.findIndex(str => str === term) === -1 ) {
        termArray.push(term);
      }
      this.findAllPermutations(term, termArray, currentPosition + 1);
    } else if ((currentPosition < term.length && term.charAt(currentPosition) === 'd' && term.charAt(currentPosition + 1) === 'j') ||
      term.charAt(currentPosition) === 'đ' ) {

      if (term.charAt(currentPosition) === 'đ') {

        const newTerm = this.setCharAt(term, currentPosition, 'dj');
        termArray.push(newTerm);
        this.findAllPermutations(newTerm, termArray, currentPosition + 1);

      } else if (term.charAt(currentPosition) === 'd' && term.charAt(currentPosition + 1) === 'j') {

        const newTerm = term.substring(0, currentPosition) + 'đ' + term.substring(currentPosition + 2);
        termArray.push(newTerm);
        this.findAllPermutations(newTerm, termArray, currentPosition + 1);
      }

      if ( termArray.findIndex(str => str === term) === -1 ) {
        termArray.push(term);
      }
      this.findAllPermutations(term, termArray, currentPosition + 1);
    } else {

      if ( termArray.findIndex(str => str === term) === -1 ) {
        termArray.push(term);
      }
      this.findAllPermutations(term, termArray, currentPosition + 1);
    }
  }

  public removeDuplicates( entitiesArray: any): any {

    const noDuplicatesEntitiesArray: any = [];

    entitiesArray.forEach(entity => {
      if ( noDuplicatesEntitiesArray.findIndex( nd => nd.id === entity.id) === -1 ){
        noDuplicatesEntitiesArray.push(entity);
      }
    });

    return noDuplicatesEntitiesArray;
  }

  public displayFirstEntitesThatSubstringAtTheBeginingIsEqualToTerm(entities: any, termsArray, sort: boolean): void {

    const tempEntitiesArray = [];

    for (const item of entities) {
      if ( this.checkIfTermIsSubstringAtTheBeginingOfTheEntityName(termsArray, item.name) ) {
        tempEntitiesArray.push(item);
      }
    }

    if ( sort ){
      tempEntitiesArray.sort((a, b) => b.id - a.id);
    } else {
      tempEntitiesArray.reverse();
    }

    tempEntitiesArray.forEach(tempEntity => {
      entities.splice(entities.findIndex(x => x.id === tempEntity.id), 1);
      entities.unshift(tempEntity);
    });
  }

  private checkIfTermIsSubstringAtTheBeginingOfTheEntityName(terms: Array<string>, entityName: string): boolean {
    let flag = false;
    terms.forEach((term: string) => {
      if ( term.toLowerCase() === entityName.substring(0, term.length).toLowerCase() ) {
        flag = true;
      }
    });

    return flag;
  }

  private setCharAt(str, index, chr) {
    if (index > str.length - 1 ) { return str; }
    return str.substring(0, index) + chr + str.substring(index + 1);
  }

  public boldSubstringOfEntitesThatAreEqualToTerms( entities, terms: string[], isCategory = false ): any {

    return this.mapEntity( entities, terms, isCategory );
  }

  private mapEntity( entities, terms: string[], isCategory: boolean): any {
    return entities.map(entity => {
      return {
        ...entity,
        name: isCategory && entity.important ? entity.name : this.boldText(entity.name, terms)
      };
    });
  }


  public boldText( entityName: string, terms: string[] ): string {

    let flag = false;
    let boldEntityName = '';

    terms.forEach(term => {

      const entityNametoLowercase = entityName.toLowerCase();

      if (entityNametoLowercase.includes(term.toLowerCase())) {

        flag = true;
        const indexOfTermInEntityName = entityNametoLowercase.indexOf(term.toLowerCase());
        const beginning = entityName.slice(0, indexOfTermInEntityName);

        const boldedText = '<b>' + entityName.slice(indexOfTermInEntityName, indexOfTermInEntityName + term.length) + '</b>';
        const end = entityName.slice(indexOfTermInEntityName + term.length, entityName.length);

        boldEntityName = beginning + boldedText + end;
      }
    });


    if ( flag ) {
      return boldEntityName;
    } else {
      return entityName;
    }
  }
}
