import { OnInit, OnDestroy, Component, Output, Input, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { select, Store } from '@ngrx/store';

import { Loading } from '../../../models/Loading';
import { Category } from '../../../models/Category';
import { Location } from '../../../models/Location';

import { AppState } from '../../../../reducers';
import { locationLoad } from '../../../store/location/actions/location.actions';
import { locations, locationsLoading } from '../../../store/location/selectors/location.selectors';

import { CategoryTreeService } from '../../../services/category-tree.service';

@Component({
  selector: 'app-filter-container',
  templateUrl: './filter-container.component.html',
  styleUrls: ['./filter-container.component.scss'],
})
export class FilterContainerComponent implements OnInit, OnDestroy {

  @Input() chosenPriceFrom: number      = null;
  @Input() chosenPriceTo: number        = null;
  @Input() chosenCurrency               = null;
  @Input() chosenLocations: Location[]  = [];
  @Input() reset                        = new UntypedFormControl(false);
  @Input() resetPriceFrom               = new UntypedFormControl(false);
  @Input() resetPriceTo                 = new UntypedFormControl(false);
  @Input() resetCurrency                = new UntypedFormControl(false);
  @Input() resetLocations               = new UntypedFormControl(false);

  locations: Location[]         = [];
  chosenCategories: Category[]  = [];
  subscriptions: Subscription[] = [];
  categoryHaveSubcategories     = true;

  @Output() currentData: EventEmitter<{category: Category, currentFilterState: any}>
    = new EventEmitter<{category: Category, currentFilterState: any}>();
  @Output() basicFilters: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelAllFilters: EventEmitter<any> = new EventEmitter<any>();

  constructor( private route: ActivatedRoute,
               private store: Store<AppState>,
               private categoryTreeService: CategoryTreeService) { }

  ngOnInit() {
    this.fetchLocations();
    this.checkUrlForCategorySlug();
  }

  /**
   * Locations
   */

  fetchLocations(): void {

    this.subscriptions.push(this.store
      .pipe(select( locationsLoading ))
      .subscribe( loading => {

        if (loading === Loading.NOT_LOADED) {
          this.store.dispatch(locationLoad());
        }

        if (loading === Loading.SUCCESS) {
          this.subscriptions.push(
            this.store
              .pipe(select(locations))
              .subscribe(locationsFromStore => {
                this.locations = locationsFromStore;
              })
          );
        }
      }));
  }

  /**
   * Categories
   */

  checkUrlForCategorySlug(): void {
    if (this.route.snapshot.paramMap.has('categorySlug')){
      this.subscriptions.push( this.store
        .subscribe((locationsFromStore: any) => {
          const data = locationsFromStore.category.categories;
          this.chosenCategories = this.categoryTreeService.getBreadCrumbs(data, this.route.snapshot.paramMap.get('categorySlug'));

          if (this.chosenCategories.length > 0) {
            this.categoryHaveSubcategories = this.chosenCategories[ this.chosenCategories.length - 1 ]?.subcategories.length > 0 ?
              true : false;
          }
        }));
    }
  }

  onCategoryEmmited( currentData: any ): void {

    if ( currentData.previousCategory ) {
      const index = this.chosenCategories.findIndex(category => category.id === currentData.previousCategory.id);
      this.chosenCategories.splice(index + 1, this.chosenCategories.length - index + 1);
    } else if (this.chosenCategories.length > 0) {
      this.chosenCategories = [];
    }

    this.chosenCategories.push(currentData.currentCategory);

    this.currentData.emit({category: currentData.currentCategory, currentFilterState: currentData.currentFilterState});
  }

  /**
   * Events
   */

  onSubmitEmmited( event: any ): void {
    this.basicFilters.emit(event);
  }

  onCancelEmmited(): void {
    this.cancelAllFilters.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
    this.subscriptions = [];
  }
}
