import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { EntityDataService, EntityDefinitionService, EntityMetadataMap } from '@ngrx/data';
import CollectionEnum from '../../_shared/enums/collection.enum';
import { ClassfieldDataService } from './classfield-data.service';
import { ClassfieldEntityService } from './classfield-entity.service';
import { ClassfieldPageRoutingModule } from './classfield-routing.module';
import { ClassfieldResolver } from './resolvers/classfield.resolver';
import { ClassfieldEditorResolver } from './resolvers/classfield-editor.resolver';

const entityMetadata: EntityMetadataMap = {
  Ad: {
  }
};

@NgModule({
            imports:   [
              CommonModule,
              FormsModule,
              IonicModule,
              ClassfieldPageRoutingModule
            ],
            providers: [
              ClassfieldEntityService,
              ClassfieldResolver,
              ClassfieldEditorResolver,
              ClassfieldDataService
            ]
          })
export class ClassfieldPageModule {

  constructor(private eds: EntityDefinitionService,
              private entityDataService: EntityDataService,
              private classfieldDataService: ClassfieldDataService) {

    eds.registerMetadataMap(entityMetadata);
    entityDataService.registerService(CollectionEnum.Ad, classfieldDataService);
  }
}
