import {AfterViewInit, Component, Input} from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements AfterViewInit {

  @Input() showNgAdSense;
  constructor() { }

  ngAfterViewInit() {

    if ( !this.showNgAdSense ) {
      setTimeout(() => {
        try {
          // @ts-ignore
          (window.adsbygoogle = window.adsbygoogle || []).push({
            overlays: {bottom: true}
          });
        } catch (e) {
          console.error(e);
        }
      }, 500);
    }
  }
}
