import { Injectable } from '@angular/core';

import { Observable} from 'rxjs';

import { BaseApiService } from './base-api.service';

import { AdCreateLog } from '../models/Log/AdCreateLog';
import { LocationPickedLog } from '../models/Log/LocationPickedLog';
import { CategoryPickedLog } from '../models/Log/CategoryPickedLog';
import { CustomFieldCategoryLog } from '../models/Log/CustomFieldCategoryLog';

@Injectable({
  providedIn: 'root'
})
export class LogService extends BaseApiService {


  public customFields( customFieldCategoryLog: CustomFieldCategoryLog ): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/log/custom-fields`, customFieldCategoryLog);
  }

  public classfieldCreated( classfieldCreated: AdCreateLog ): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/log/classfield-created`, classfieldCreated);
  }

  public categoryPicked( categoryPicked: CategoryPickedLog ): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/log/category-picked`, categoryPicked);
  }

  public locationPicked( locationPicked: LocationPickedLog ): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/log/location-picked`, locationPicked);
  }
}
