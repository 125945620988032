import { Component, Input, OnInit } from '@angular/core';
import {select, Store} from '@ngrx/store';
import { breadCrumbs } from '../../store/category/selectors/category.selectors';
import { AppState } from '../../../reducers';
import { Router } from '@angular/router';

@Component({
             selector:    'app-breadcrumb',
             templateUrl: './breadcrumb.component.html',
             styleUrls:   ['./breadcrumb.component.scss'],
           })
export class BreadcrumbComponent implements OnInit {

  @Input() items = null;

  constructor( private store: Store<AppState>,
               private router: Router) {
  }

  ngOnInit() {
    if (!this.items){
      this.fetchCategories();
    }
  }

  fetchCategories() {
    this.store
      .pipe(select(breadCrumbs))
      .subscribe(response => {
          this.items =  response;
      });
  }
}
