import { EventEmitter, OnInit, Component, Output, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { CategoryService } from '../../../services/category.service';
import { CustomFieldService } from '../../../services/custom-field.service';

import { CustomField } from '../../../models/CustomField/CustomField';
import { CustomFieldFilter } from '../../../models/CustomField/CustomFieldFilter';
import { CustomFieldOption } from '../../../models/CustomField/CustomFieldOption';

@Component({
  selector: 'app-custom-field-filter-picker',
  templateUrl: './custom-field-filter-picker.component.html',
  styleUrls: ['./custom-field-filter-picker.component.scss'],
})
export class CustomFieldFilterPickerComponent implements OnInit {

  @Input() categorySlug: string = null;
  @Input() chosenCustomFieldFilters: string[] = [];
  @Input() resetCustomFieldFilters = new UntypedFormControl(null );

  customFields: CustomField[] = [];
  customFieldFilters: CustomFieldFilter[] = [];

  @Output() selectedCustomFieldFilters: EventEmitter<CustomFieldFilter[]> = new EventEmitter<CustomFieldFilter[]>();

  constructor( private route: ActivatedRoute,
               private categoryService: CategoryService,
               private customFieldService: CustomFieldService,
             ) { }

  ngOnInit() {

    this.initialize();
  }

  initialize(): void {

    if ( this.chosenCustomFieldFilters.length > 0 ) {
      this.customFieldFilters = this.customFieldService.parseStringToCustomFieldFilter( this.chosenCustomFieldFilters );
    }

    if ( this.route.snapshot.paramMap.has('categorySlug') ) {
      this.fetchCustomFields( this.route.snapshot.paramMap.get('categorySlug') );
    } else if ( this.categorySlug ) {
      this.fetchCustomFields( this.categorySlug );
    }

    this.subscribeOnReset();
  }

  subscribeOnReset() {

    this.resetCustomFieldFilters.valueChanges.subscribe(value => {
      if (value) {
        this.unfillCustomField(value);
      }
    });
  }

  fetchCustomFields( categorySlug: string ): void {

    this.categoryService.customFieldsByCategorySlug( categorySlug ).subscribe(( customFields: CustomField[] ) => {

      this.customFields = this.chosenCustomFieldFilters.length > 0 ?
        this.fillCustomFields(this.customFieldService.mapCustomField( customFields )) :
        customFields;
    });
  }

  fillCustomFields( customFieldsHelper: CustomField[] ): CustomField[] {

    this.customFieldFilters.forEach(( customFieldFilter: CustomFieldFilter ) => {

      const index: number = customFieldsHelper.findIndex( customField => customField.id === customFieldFilter.customFieldId );
      if ( index >= 0 ) {
        switch ( customFieldFilter.type ) {
          case 'number':
            customFieldsHelper[index].min = customFieldFilter.min;
            customFieldsHelper[index].max = customFieldFilter.max;
            break;
          case 'string':
            customFieldsHelper[index].value = customFieldFilter.value;
            break;
          case 'select':
            customFieldsHelper[index].checkedFieldOptions = customFieldsHelper[index].customFieldOptions
              .find( customFieldOption => customFieldOption.value === customFieldFilter.value  ).id;
            break;
          case 'multiselect':
            (customFieldsHelper[index].checkedFieldOptions as CustomFieldOption[])
              .push(customFieldsHelper[index].customFieldOptions
              .find( customfieldOption => customfieldOption.value === customFieldFilter.value )
            );
            break;
          default:
            break;
        }
      }
    });

    return customFieldsHelper;
  }

  unfillCustomField( customFieldFilter: CustomFieldFilter ): void {

    const  index = this.customFields.findIndex(customField => customField.id === customFieldFilter.customFieldId );

    const customFieldHelper: CustomField[] = this.customFields.map(customField => ({ ...customField }));
    const customFieldFiltersHelper: CustomFieldFilter[] = this.customFieldFilters.map( filter => ({ ...filter }));

    switch ( customFieldFilter.type ) {
      case 'number':
        this.unfillCustomFieldNumber(customFieldFilter, customFieldHelper, customFieldFiltersHelper, index);
        break;
      case 'string':
        this.unfillCustomFieldString(customFieldFilter, customFieldHelper, customFieldFiltersHelper, index);
        break;
      case 'select':
        this.unfillCustomFieldSelect(customFieldFilter, customFieldHelper, customFieldFiltersHelper, index);
        break;
      case 'multiselect':
        this.unfillCustomFieldMultiSelect(customFieldFilter, customFieldHelper, customFieldFiltersHelper, index);
        break;
      default:
        break;
    }

    this.customFields = customFieldHelper;
    this.customFieldFilters = customFieldFiltersHelper;

    this.emmitCustomFieldFilters();
  }

  unfillCustomFieldNumber( customFieldFilter: CustomFieldFilter,
                           customFieldHelper: CustomField[],
                           customFieldFiltersHelper: CustomFieldFilter[],
                           index: number ): void
  {

    customFieldHelper[index].min = null;
    customFieldHelper[index].max = null;

    customFieldFiltersHelper.splice(this.customFieldFilters.findIndex
    ( filter => filter.customFieldId === customFieldFilter.customFieldId) , 1);
  }

  unfillCustomFieldString( customFieldFilter: CustomFieldFilter,
                           customFieldHelper: CustomField[],
                           customFieldFiltersHelper: CustomFieldFilter[],
                           index: number ): void
  {

    customFieldHelper[index].value = null;

    customFieldFiltersHelper.splice(this.customFieldFilters.findIndex
    ( filter => filter.customFieldId === customFieldFilter.customFieldId) , 1);
  }

  unfillCustomFieldSelect( customFieldFilter: CustomFieldFilter,
                           customFieldHelper: CustomField[],
                           customFieldFiltersHelper: CustomFieldFilter[],
                           index: number ): void
  {

    customFieldHelper[index].checkedFieldOptions = null;
    customFieldFiltersHelper.splice(this.customFieldFilters.findIndex
    ( filter => filter.customFieldId === customFieldFilter.customFieldId) , 1);
  }

  unfillCustomFieldMultiSelect( customFieldFilter: CustomFieldFilter,
                                customFieldHelper: CustomField[],
                                customFieldFiltersHelper: CustomFieldFilter[],
                                index: number ): void
  {

    if ( !customFieldHelper[index]?.checkedFieldOptions ) {
      return;
    }

    (customFieldHelper[index].checkedFieldOptions as CustomFieldOption[])
      .splice((customFieldHelper[index].checkedFieldOptions as CustomFieldOption[]).findIndex(
        customFieldOption => customFieldOption.value === customFieldFilter.value
      ), 1);

    customFieldFiltersHelper.splice(this.customFieldFilters.findIndex
    ( filter => filter.customFieldId === customFieldFilter.customFieldId &&
      filter.value === customFieldFilter.value
    ) , 1);
  }

  checkIfCustomFieldNumberDataIsOverwritten( customFieldId: number ): boolean {

    return !!this.customFieldFilters.find(customFieldFilter => customFieldFilter.customFieldId === customFieldId);
  }

  handleCustomFieldFilterDataEmmited( emmitedCustomFieldFilterData: any ): void {

    switch ( emmitedCustomFieldFilterData.type ) {
      case 'number':
        this.handleCustomFieldFilterNumberDataEmmited( emmitedCustomFieldFilterData );
        break;
      case 'string':
        this.handleCustomFieldFilterStringDataEmmited( emmitedCustomFieldFilterData );
        break;
      case 'select':
        this.handleCustomFieldFilterSelectDataEmmited( emmitedCustomFieldFilterData );
        break;
      case 'multiselect':
        this.handleCustomFieldFilterMultiselectDataEmmited( emmitedCustomFieldFilterData );
        break;
      default:
        break;
    }
    this.emmitCustomFieldFilters();
  }

  handleCustomFieldFilterNumberDataEmmited( emmitedCustomFieldFilterData: CustomFieldFilter ): void {

    const index = this.customFieldFilters
      .findIndex( customFieldFilter => customFieldFilter.customFieldId === emmitedCustomFieldFilterData.customFieldId);

    if ( index >= 0 ){
      if ( !emmitedCustomFieldFilterData.min && !emmitedCustomFieldFilterData.max ){
        this.customFieldFilters.splice(index, 1);
      } else {
        this.customFieldFilters[index] = emmitedCustomFieldFilterData;
      }
    } else {
      this.customFieldFilters.push( emmitedCustomFieldFilterData );
    }
  }

  handleCustomFieldFilterStringDataEmmited( emmitedCustomFieldFilterData: CustomFieldFilter ): void {

    const index = this.customFieldFilters
      .findIndex( customFieldFilter => customFieldFilter.customFieldId === emmitedCustomFieldFilterData.customFieldId);

    if ( index >= 0 ){
        if ( emmitedCustomFieldFilterData.value && emmitedCustomFieldFilterData.value.length > 0 ) {
          this.customFieldFilters[index] = emmitedCustomFieldFilterData;
        } else {
          this.customFieldFilters.splice(index, 1);
        }

    } else {
      this.customFieldFilters.push( emmitedCustomFieldFilterData );
    }
  }

  handleCustomFieldFilterSelectDataEmmited( emmitedCustomFieldFilterData: CustomFieldFilter ): void {
    const index = this.customFieldFilters
      .findIndex( customFieldFilter => customFieldFilter.customFieldId === emmitedCustomFieldFilterData.customFieldId);

    if ( index >= 0 ){
      if ( !emmitedCustomFieldFilterData.value ){
        this.customFieldFilters.splice( index, 1);
      } else {
        this.customFieldFilters[index] = emmitedCustomFieldFilterData;
      }
    } else {
      this.customFieldFilters.push( emmitedCustomFieldFilterData );
    }
  }

  handleCustomFieldFilterMultiselectDataEmmited( emmitedCustomFieldFilterData: CustomFieldFilter ): void {

    const index = this.customFieldFilters
      .findIndex( customFieldFilter =>
        customFieldFilter.customFieldId === emmitedCustomFieldFilterData.customFieldId &&
        customFieldFilter.value === emmitedCustomFieldFilterData.value
      );

    if ( index >= 0 ){
      this.customFieldFilters.splice( index, 1);
    } else {
      this.customFieldFilters.push( emmitedCustomFieldFilterData );
    }
  }

  emmitCustomFieldFilters(): void {

    this.selectedCustomFieldFilters.emit( this.customFieldFilters );
  }
}
