import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import { AppState } from '../../../reducers';

import { TITLE_SUFFIX } from '../../../_shared/constants';

import { LogService } from '../../../_shared/services/log.service';
import { CategoryService } from '../../../_shared/services/category.service';
import { CategoryTreeService } from '../../../_shared/services/category-tree.service';
import { TitleAndDescriptionService } from '../../../_shared/services/title-and-description.service';

import { categoriesSuccess } from '../../../_shared/store/category/actions/category.actions';
import { isLoaded, loadedCategories } from '../../../_shared/store/category/selectors/category.selectors';
import { categories, topCategories } from '../../../_shared/store/category/actions/category.actions';

@Injectable({
  providedIn: 'root'
})
export class ClassfieldListResolver implements Resolve<boolean> {

  constructor(private router: Router,
              private categoryService: CategoryService,
              private store: Store<AppState>,
              private categoryTreeService: CategoryTreeService,
              private titleAndDescriptionService: TitleAndDescriptionService,
              private logService: LogService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // Home page
    if (!route.paramMap.has('categorySlug')) {
      this.setTitleAndDescription('Eventum', '');
      this.homePage();
      return true;
    } else {  // Specific category
      return this.categoryExists(route);
    }
  }

  /**
   * Checks if categories are already loaded by testing isLoaded flag
   */
  homePage() {
    this.store
      .pipe(select( isLoaded ))
      .subscribe((categoriesLoaded: boolean) => {
        if (categoriesLoaded) {
          this.categoriesAlreadyLoaded();
        }
        else {
          this.fetchTopCategories();
        }
      });
  }

  categoriesAlreadyLoaded(): void {
    let categoriesHelper = null;
    this.store
      .pipe(select( loadedCategories ))
      .subscribe(categories => {
        categoriesHelper = categories.map(category => ({...category}));
      }).unsubscribe();

    categoriesHelper = this.categoryTreeService.closeCategories(categoriesHelper);
    if ( !categoriesHelper[0]?.sn ) {
      categoriesHelper = this.categoryTreeService.numerateCategories(categoriesHelper);
    }

    categoriesHelper = this.categoryTreeService.sortCategories(categoriesHelper);
    this.store.dispatch(categoriesSuccess({ categories: categoriesHelper, openCategories: false }));
  }

  fetchTopCategories(): void {
    this.store.dispatch(topCategories());
  }

  /**
   * For specific category page ----------------------------------------------------------------------------------------
   */

  private categoryExists(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    let categoriesFromStore = null;
    this.store
      .pipe(select(loadedCategories))
      .subscribe(categories => {
        categoriesFromStore = categories;
      }).unsubscribe();


    // Already loaded in store
    if (this.categoryTreeService.findCategoryBySlug(categoriesFromStore, route.paramMap.get('categorySlug'))) {
      this.fetchCategoriesWithSlug(route);
      return true;
    } else {
      return this.categoryService.showSlug(route.paramMap.get('categorySlug'))
        .pipe(
          catchError(error => {
            this.router.navigate(['/greska/stranica-nije-pronadjena'], { replaceUrl: false });
            return of(false);
          }),
          map(() => {
            this.fetchCategoriesWithSlug(route);
            return true;
          })
        );
    }
  }

  fetchCategoriesWithSlug(route: ActivatedRouteSnapshot){


    let categoriesLoaded = false;
    this.store
      .pipe(select(isLoaded))
      .subscribe(isLoaded => categoriesLoaded = isLoaded).unsubscribe();


    if (categoriesLoaded) { // ako su podaci ucitani u store-u
      let categories = null;
      this.store
        .pipe(select(loadedCategories))
        .subscribe(categoriesLoaded => {
          categories = categoriesLoaded.map( category => ({...category}));
        }).unsubscribe();

      let category = this.categoryTreeService.findCategoryBySlug(categories, route.paramMap.get('categorySlug'));

      // Subcategories already loaded
      if (category && category.subcategories && category.subcategories.length > 0) {
        categories = this.categoryTreeService.closeCategories(categories);


        this.store.dispatch(categoriesSuccess({ categories,  openCategories: true, slug: category.slug }));
        this.createCategoryLog( category.id );
      } else { // Subcategories not already loaded

        this.categoryService.categories(category.id).subscribe(
          response => {
            response = this.categoryTreeService.numerateSubCategories(response);
            categories = this.categoryTreeService.addSubcategories(category.id, categories, response);

            this.store.dispatch(categoriesSuccess({categories, openCategories: true, slug: category.slug}));

            this.createCategoryLog(category.id);
          }
        );
      }
      this.setTitleAndDescription(category.title, category.description);
    } else { // Categories not already loaded in store
      this.store.dispatch(categories({ parameter: route.paramMap.get('categorySlug'), isSlug: true }));
    }

    return of(true);
  }

  createCategoryLog( categoryId: number ): void {
    const fingerprint = localStorage.getItem('fingerprint');
    this.logService.categoryPicked({ categoryId, fingerprint }).subscribe();
  }

  setTitleAndDescription( categoryTitle: string, categoryDescription: string ): void {
    this.titleAndDescriptionService.setTitle(categoryTitle);
    this.titleAndDescriptionService.setDescription(categoryDescription);
  }
}

