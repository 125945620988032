import { AfterViewInit, OnInit, Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IonInput, PopoverController } from '@ionic/angular';
import { ISearchable } from '../../../models/ISearchable';
import { SearchService } from '../../../services/search.service';

@Component({
  selector: 'app-searchable-select-modal',
  templateUrl: './searchable-select-popover.component.html',
  styleUrls: ['./searchable-select-popover.component.scss'],
})
export class SearchableSelectPopoverComponent implements OnInit, AfterViewInit {

  @Input() entities: ISearchable[];
  entitiesFiltered: ISearchable[];
  filter: UntypedFormControl;
  navbarFixed = false;

  @ViewChild('ionInput') ionInput: IonInput;

  scrollbarScss = '  @media(pointer: fine) {\n' +
    '    ::-webkit-scrollbar {\n' +
    '      width: 12px;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-track {\n' +
    '      background: #fff;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-track:hover {\n' +
    '      background: #f7f7f7;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-thumb {\n' +
    '      background: #ccc;\n' +
    '    }\n' +
    '    ::-webkit-scrollbar-thumb:hover {\n' +
    '      background: #888\n' +
    '    }\n' +
    '    .inner-scroll {\n' +
    '      scrollbar-width: thin\n' +
    '    }\n' +
    '  }';

  constructor( private searchService: SearchService,
               private popoverController: PopoverController ) { }

  ngOnInit() {
    this.entitiesFiltered = this.entities;
    this.buildFilter();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ionInput.setFocus();
    }, 500);
  }

  buildFilter() {
    this.filter = new UntypedFormControl('');
    this.filter.valueChanges.subscribe(term => {
      const lowerCaseTerm = term.toLowerCase();

      const termsArray: string[] = [];
      this.searchService.findAllPermutations(lowerCaseTerm, termsArray, 0);

      if (termsArray.length === 0) {
        this.entitiesFiltered = this.entities;
      } else {
        const filteredArray = [];

        termsArray.forEach((termFromArray: string) => {
          const filtered = this.entities.filter(category => {
            return category.name.toLowerCase().includes(termFromArray);
          });

          filtered.forEach(category => filteredArray.push(category));
        });

        this.entitiesFiltered = this.searchService.removeDuplicates(filteredArray);
        this.searchService.displayFirstEntitesThatSubstringAtTheBeginingIsEqualToTerm(this.entitiesFiltered, termsArray, false);
        this.entitiesFiltered = this.searchService.boldSubstringOfEntitesThatAreEqualToTerms( this.entitiesFiltered, termsArray );
      }
    });
  }

  pick(entity: ISearchable) {
    this.popoverController.dismiss({
      entity
    });
  }

  onScroll(event){
    this.navbarFixed = !!(event.detail.scrollTop > 1);
  }
}
