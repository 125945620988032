import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from '@ionic/angular';

import { Store } from '@ngrx/store';

import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';
import { DeleteFeedbackComponent } from '../../delete-feedback/delete-feedback.component';
import { DeleteConfirmationComponent } from '../../delete-confirmation/delete-confirmation.component';
import { ClassfieldTypePopoverComponent } from '../../classfield-type-popover/classfield-type-popover.component';

import { ToastService } from '../../../services/toast.service';
import { SettingsService } from '../../../services/settings.service';
import { ClassfieldService } from '../../../../home/classfield/classfield.service';

import { User } from '../../../models/User';

import { AppState } from '../../../../reducers';
import { updateCredit } from '../../../../auth/auth.actions';
import {StatisticsService} from '../../../services/statistics.service';

@Component({
             selector:    'app-classfield-menu',
             templateUrl: './classfield-menu.component.html',
             styleUrls:   ['./classfield-menu.component.scss'],
           })
export class ClassfieldMenuComponent implements OnInit {

  @Input() adId: number = null;
  @Input() classfieldTypeId: number = null;
  @Input() authUser: User = null;
  @Input() adAdTypes = [];

  admin = false;

  renewClassfieldPrice = undefined;
  yesNoDialog;
  classfieldTypeDialog;
  deleteFeedback;

  constructor(private modalController: ModalController,
              private router: Router,
              private popoverController: PopoverController,
              private classfieldService: ClassfieldService,
              private toastService: ToastService,
              private settingsService: SettingsService,
              private yesNoDialogController: PopoverController,
              private statiscticsService: StatisticsService,
              private store: Store<AppState>) {
  }

  ngOnInit() {
    this.admin = localStorage.getItem('role') === 'admin' ? true : false;
    this.fetchSetClassfieldToTopPice();
  }

  fetchSetClassfieldToTopPice(): void {

    this.settingsService.getRenewClassfieldPrice().subscribe(response => {
      this.renewClassfieldPrice = response.value;
    });
  }

  async editAd() {

    await this.popoverController.dismiss();
    await this.router.navigate(['/home/oglasi/' + this.adId + '/izmeni-oglas']);
  }

  async renewClassfield(): Promise<any> {

    this.yesNoDialog = await this.yesNoDialogController.create({
      component: YesNoDialogComponent,
      componentProps : { dialogText: `Ova usluga će biti naplaćena ${ this.renewClassfieldPrice } poena`, buttonText: ['Ok', 'Odustani'] }
    });

    this.yesNoDialog.onDidDismiss().then((dialogResponse: any) => {
      if (dialogResponse.role === 'decision' && dialogResponse.data) {

          this.classfieldService.setClassfieldOnTop(this.adId).subscribe( response => {

            const newCreditValue = this.authUser.credit - this.renewClassfieldPrice;
            this.store.dispatch(updateCredit({ credit: newCreditValue }));
            this.popoverController.dismiss(null, 'RenewClassfield');
            this.toastService.showSuccess('Uspešno ste obnovili oglas!');
          },
          error => this.toastService.showError(this.authUser.credit < this.renewClassfieldPrice ?
            'Nemate dovoljno poena za ovu uslugu' :
            error.error.message));
      }
    });

    return await this.yesNoDialog.present();
  }

  async updateClassfieldType(): Promise<any> {

    this.classfieldTypeDialog = await this.popoverController.create({
      component: ClassfieldTypePopoverComponent,
      componentProps : { classfieldId: this.adId,
                         classfieldTypeId: this.classfieldTypeId,
                         classfieldClassfieldTypes: this.adAdTypes,
                         popover: true},
      cssClass: 'classfield-type-modal-class'
    });

    return await this.classfieldTypeDialog.present();
  }

  async presentDeleteConfirmationPopover() {
    await this.popoverController.dismiss();

    const popover = await this.popoverController.create({
      component:      DeleteConfirmationComponent,
      // componentProps: {['adId']: this.ad.id},
      animated:       true,
      mode:           'ios',
      translucent:    true
    });

    await popover.present();

    popover.onDidDismiss().then(value => {
      if (value.role === 'delete' && value.data) {
         this.presentDeleteFeedbackModal();
      }
    });
  }


  async presentDeleteFeedbackModal() {
    const modal = await this.modalController.create({
      component: DeleteFeedbackComponent,
      cssClass: 'delete-feedback-modal-class',
      componentProps: {
        classfieldId: this.adId
      }
    });

    await modal.present();

    modal.onDidDismiss().then(async value => {

      if (value.role === 'delete') {
        this.popoverController.dismiss();
        this.router.navigate(['/']);
      }
    });
  }
}
