import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

import {DateTime} from 'luxon';
import {Router} from '@angular/router';

import { Chat } from '../../../models/Chat';
import { Message } from '../../../models/Message';

@Component({
  selector: 'app-chat-card',
  templateUrl: './chat-card.component.html',
  styleUrls: ['./chat-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatCardComponent implements OnInit{

  @Input() chat: Chat = undefined;
  @Input() authUserId: number;

  username = '';

  messageBodyLengthDesktop = 210;
  messageBodyLengthLaptop = 190;
  messageBodyLengthTablet = 165;
  messageBodyLengthMobile = 115;

  constructor( private router: Router ) { }

  ngOnInit(): void {
    this.getUsername();
  }

  getUsername(): void {

    this.username = this.chat.fromUser.id === this.authUserId ?
      ( this.chat.toUser.userType === 0 ? this.chat.toUser.fullName : this.chat.toUser.companyName) :
      ( this.chat.fromUser.userType === 0 ? this.chat.fromUser.fullName : this.chat.fromUser.companyName);
  }

  convertDate( createdAt ) {
    return DateTime.fromFormat(createdAt, 'YYYY-MM-DD').toFormat('MM.DD.YYYY');
  }

  navigateToChat( chatId: number ){
    this.router.navigate(['/home//inbox/' + chatId], {replaceUrl: true});
  }

  getLastMessageBodyForDesktop( message: Message ): string {
    if (message.body.length <= this.messageBodyLengthDesktop) {
      return message.body;
    }
    return message.body.substring(0, this.messageBodyLengthDesktop) + '...';
  }

  getLastMessageBodyForLaptop( message: Message ): string {
    if (message.body.length <= this.messageBodyLengthLaptop) {
      return message.body;
    }
    return message.body.substring(0, this.messageBodyLengthLaptop) + '...';
  }

  getLastMessageBodyForTablet( message: Message ): string {
    if (message.body.length <= this.messageBodyLengthTablet) {
      return message.body;
    }
    return message.body.substring(0, this.messageBodyLengthTablet) + '...';
  }

  getLastMessageBodyForMobile( message: Message ): string {
    if (message.body.length <= this.messageBodyLengthMobile) {
      return message.body;
    }
    return message.body.substring(0, this.messageBodyLengthMobile) + '...';
  }
}
