import { Injectable } from '@angular/core';

import { Category } from '../models/Category';

@Injectable({
  providedIn: 'root'
})
export class CategoryTreeService {

  constructor() { }

  /**
   * Find leaf subcategories and add value of empty array to their property 'subcategories'
   *
   * @param categories array of categories
   *
   * @returns Category[]
   */

  public mapCategories( categories: Category[]): Category[]
  {
    if(!categories) return;
    return categories.map( category => {
      return {
        ...category,
        subcategories: category?.subcategories ? this.mapCategories(category.subcategories) : []
      }
    })
  }

  /**
   * Set all subcategories to be visible in SideMenu at Main page
   *
   * @param categories array of categories
   *
   * @returns void
   */

  public setOpenCategory( categories: Category[] ) {

    return categories.map( category =>{
      return{
        ...category,
        isOpen: true,
        subcategories: category.subcategories ? this.setOpenCategory(category.subcategories) : []
      }
    })
  }

   /**
   * Set all subcategories to be hidden in SideMnu at Main page
   *
   * @param categories array of categories
   *
   * @returns void
   */

  public closeCategories( categories: Category[] )
  {
    return categories.map(category => {
      return {
        ...category,
        isOpen: false,
        subcategories: category.subcategories ? this.closeCategories( category.subcategories ) : []
      };
    });
  }

  /**
   * Find category by her id or slug
   *
   * @param categories array of Categories
   * @param searchParameter can be eather id of category id or her slug
   * @param isSlug define that searchParameter is slug or id
   *
   * @return Category | null
   */

  private findCategory( categories: Category[], searchParameter: string | number, isSlug: boolean ): Category {

    const category = categories.find(category => isSlug ? (category.slug === searchParameter) : (category.id === searchParameter));

    if (category) {
      return category;
    }

    for (let index = 0; index < categories.length; index++) {
      if (categories[index].subcategories) {
        const categoryFound = this.findCategory(categories[index].subcategories, searchParameter, isSlug);
        if (categoryFound) {
          return categoryFound;
        }
      }
    }
  }

  /**
   * Call findCategory method
   *
   * @param categories array of categories
   * @param id id of category that method will try to find in array
   *
   * @returns Category | null
   */

  public findCategoryById(categories: Category[], id: number): Category {
    return this.findCategory(categories, id, false);
  }

  /**
   * Call findCategory method
   *
   * @param categories array of categories
   * @param slug slug of category that method will try to find in array
   *
   * @returns Category | null
   */

  public findCategoryBySlug(categories: Category[], slug: string): Category {
    return this.findCategory(categories, slug, true);
  }

  /**
   * Finds category and paste method parameter 'subcategories' to her subcategories property, make her subcategories visible/hidden in sideMenu at MainPage
   * and hide other subcategories thar are not in same level in CategoryTree
   *
   * @param categoryId id of category that method will try to find in array
   * @param categories array of categories
   * @param subcategories subcategories that will be pasted on founded category
   * @param collapse flag used to tell if subcategories of some categories should be visible
   *
   * @returns Category[]
   */

  public addSubcategories(categoryId, categories: Category[], subcategories: Category[],  collapse = false): Category[] {

    if(!categories) return;

    collapse = collapse || !!categories.find(category => category.id === categoryId);

    return categories.map(category => {
        return {
          ...category,
          isOpen: category.id === categoryId ? true : (collapse ? false : category.isOpen),
          subcategories: category.id === categoryId ?
              subcategories.map( categoryHelper => { return { ...categoryHelper, isOpen: false, subcategories: [] }})
              :
              this.addSubcategories(categoryId,category.subcategories,subcategories,collapse)
        }
    });
  }

  /**
   * Finds category and paste method parameter 'subcategories' to her subcategories property
   *
   * @param categoryId id of category that method will try to find in array
   * @param categories array of categories
   * @param subcategories subcategories that will be pasted on founded category
   *
   * @returns Category[]
   */

  public addSubcategoriesNoCollapse(categoryId, categories: Category[], subcategories: Category[]): Category[] {

    if(!categories) return;

    return categories.map(category => {
        return {
          ...category,
          isOpen: false,
          subcategories: category.id === categoryId ?
              subcategories.map( categoryHelper => { return { ...categoryHelper, isOpen: false, subcategories: [] }})
              :
              this.addSubcategories(categoryId,category.subcategories,subcategories)
        }
    });
  }

  /**
   * Find and show/hide category subcategories in Sidemnu at MainPage
   *
   * @param categoryId id of category that method will try to find in array
   * @param categories array of categories
   * @param collapse flag used to tell if subcategories of some categories should be visible
   *
   * @returns Category[]
   */

  public updateCategoryTreeOpen(categoryId, categories: Category[],  collapse = false): Category[] {

    if (!categories) { return; }

    collapse = collapse || !!categories.find(category => category.id === categoryId);

    return categories.map(category => {
        return {
          ...category,
          isOpen: category.id === categoryId ? true : (collapse ? false : category.isOpen),
          subcategories: this.updateCategoryTreeOpen(categoryId,category.subcategories,collapse)
        }
    });
  }

  /**
   * Finds category and show subcategories in appropriate 'select tag' when creating/updating Ad
   *
   * @param categoryId id of category that method will try to find in array
   * @param categories array of categories
   * @param subcategories subcategories that will be pasted on founded category
   * @param collapse flag used to tell if subcategories of some categories should be visible
   *
   * @returns Category[]
   */

  public updateCategoryTreeSelect(categories: Category[], id: number, subcategories: Category[], collapse = false): Category[] {

    if (!categories) { return; }

    collapse = collapse || !!categories.find(category => category.id === id);
    return categories.map(category => {
      return {
        ...category,
        isSelected: category.id === id ? true : (collapse ? false : category.isSelected),
        subcategories: category.id === id ?
          subcategories.map( categoryHelper => { return { ...categoryHelper, isSelected: false, subcategories: [] }})
          :
          this.updateCategoryTreeSelect(category.subcategories, id, subcategories, collapse)
      };
    });
  }

  /**
   * Remove subcategories to all Top categories which property 'isSelected' is seted to false
   *
   * @param categories array of categories
   *
   * @returns Category[]
   */

  public removeClosedCategories(categories: Category[]): Category[] {
    return categories.map(category => {
      return {
        ...category,
        subcategories: category.isSelected ? category.subcategories : []
      }
    });
  }

   /**
   * When updating Ad, set all selected subcategories to appropriate 'select tag'
   *
   * @param categories array of categories
   * @param id id of leaf category
   *
   * @returns { boolean, Category[] }
   */

  public setSelectedCategory(categories: Category[], id: number): { leafCategoryFound, subcategories } {
    let leafCategoryFound = false;
    if(!categories) return;
    const mapped = categories.map(category => {
      if (leafCategoryFound === true) {
        return category;
      }
      if (category.id === id) {
        leafCategoryFound = true;
        return {
          ...category,
          isSelected: true
        };
      }
      const res = this.setSelectedCategory(category.subcategories, id);
      if (!res) return;
      if (res.leafCategoryFound === true) {
        leafCategoryFound = true;
      }
      return {
        ...category,
        isSelected: res.leafCategoryFound,
        subcategories: res.subcategories
      };
    });

    return {
      subcategories: mapped,
      leafCategoryFound
    };
  }

  /**
   * Set 'isOpen' property value to true in leaf category and all her parent categories
   *
   * @param categories array of categories
   * @param slug slug of leaf category
   *
   * @returns { boolean, Category[] }
   */

  public setOpenedCategory(categories: Category[], slug: string): { leafCategoryFound, subcategories } {
    let leafCategoryFound = false;
    if (!categories) { return; }
    const mapped = categories.map(category => {
      if (leafCategoryFound === true) {
        return category;
      }
      if (category.slug === slug) {
        leafCategoryFound = true;
        return {
          ...category,
          isOpen: true
        };
      }
      const res = this.setOpenedCategory(category.subcategories, slug);
      if (!res) { return; }
      if (res.leafCategoryFound === true) {
        leafCategoryFound = true;
      }
      return {
        ...category,
        isOpen: res.leafCategoryFound,
        subcategories: res.subcategories
      };
    });

    return {
      subcategories: mapped,
      leafCategoryFound
    };
  }

  /**
   * Set 'isSelect' property value to false in all categories and theirs subcategories
   *
   * @param categories array of categories
   *
   * @returns Category[]
   */

  public deselectCategories(categories: Category[]): Category[] {
    return categories.map(category => {
      return {
        ...category,
        isSelected: false,
        subcategories: category.subcategories ? this.deselectCategories(category.subcategories) : []
      };
    });
  }

  /**
   * Get all categories for BreadCrumbs from Category Tree
   *
   * @param categories array of categories
   *
   * @param slug leaf category slug
   *
   * @returns Category[]
   */

  getBreadCrumbs(categories: Category[], slug?: string): Category[] {

    let selectedCategories = [];
    let categoriesHelper = [];

    if (slug) {
      categoriesHelper = this.closeCategories(categories);
      categoriesHelper = this.setOpenedCategory(categoriesHelper, slug).subcategories;
    }

    this.getSelectedCategories(slug ? categoriesHelper : categories, selectedCategories);

    return selectedCategories;
  }

  /**
   * Helper method for 'getBreadCrumbs' method that finds all category for breadCrumbs
   *
   * @param categories array of categories
   *
   * @param selectedCategories empty array where finded category will be stored
   *
   * @returns Category[]
   */

  getSelectedCategories(categories: Category[], selectedCategories: Category[]): void {

    const selectedCategory = categories.find(category => category.isOpen === true);

    if (selectedCategory){
      selectedCategories.push(selectedCategory);

      if (selectedCategory.subcategories && selectedCategory.subcategories.length > 0) {
        this.getSelectedCategories(selectedCategory.subcategories, selectedCategories);
      }
    }
  }

  /**
   * Find all opened category in Category Tree and move it to the front of array
   *
   * @param categories Category Tree
   *
   * @returns Category[]
   */

  moveCategoryToTop(categories: Category[]): Category[] {

    if ( categories && categories.length === 0 ) { return []; }

    const selectedTopCategoryIndex =
      categories.findIndex( (category: Category) => category.isOpen);

    if (selectedTopCategoryIndex === -1) {
      return categories;
    }

    const tempCategory: Category = categories[selectedTopCategoryIndex];

    if ( tempCategory.subcategories?.length > 0 )
    {
      categories = this.moveSubCategoryToTop(categories, tempCategory, selectedTopCategoryIndex);

      categories.forEach((category: Category) => {
        category.subcategories = this.moveCategoryToTop(category.subcategories);
      });

    }
    return categories;
  }

  /**
   * Find opened category in Category Array and move it to the front of array
   *
   * @param categories array of categories
   *
   * @param openedCateogry opened category
   *
   * @param openedCategoryIndex index of opened category
   *
   * @returns Category[]
   */


  moveSubCategoryToTop( subCategories: Category[], openedCateogry: Category, openedCategoryIndex: number ): Category[] {
    subCategories.splice(openedCategoryIndex, 1);
    subCategories.unshift(openedCateogry);
    return subCategories;
  }

  /**
   * Add Serial Number to all categories in Category Tree
   *
   * @param categories category tree
   *
   * @returns Category[]
   */

  numerateCategories(categories: Category[]): Category[] {

    if ( categories[0].sn ) {
      return categories;
    }

    let counter = 0;
    return categories.map(category => {
      return {
        ...category,
        sn: counter++,
        subcategories: category.subcategories && category.subcategories.length > 0 ?
        this.numerateCategories(category.subcategories) : []
      };
    });
  }

  /**
   * Add Serial Number to all categories in category array
   *
   * @param categories category tree
   *
   * @returns Category[]
   */

  numerateSubCategories(categories: Category[]): Category[] {

    let counter = 0;
    return categories.map(category => {
      return {
        ...category,
        sn: counter++
      };
    });
  }

  /**
   * Sort category tree by Serial Number
   *
   * @param categories category tree
   *
   * @returns Category[]
   */

  sortCategories(categories: Category[]): Category[] {

    if (categories && categories.length === 0){ return []; }

    let tempCategeories: Category[] = this.mapCategories(categories);

    tempCategeories = this.sortSubCategories(tempCategeories);

    tempCategeories.forEach((category: Category) => {
        category.subcategories = this.sortCategories(category.subcategories);
    });

    return tempCategeories;
  }


  /**
   * Sort category array by Serial Number
   *
   * @param categories category array
   *
   * @returns Category[]
   */

  sortSubCategories(categories: Category[]): Category[] {

     const tempCategeories: Category[] = this.mapCategories(categories);
     tempCategeories.sort((c1: Category, c2: Category) =>  { return c1.sn - c2.sn; });
     return tempCategeories;
  }
}
