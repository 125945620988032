import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.scss'],
})
export class YesNoDialogComponent {

  @Input() dialogText: string = undefined;
  @Input() buttonText: string[] = undefined;

  constructor( private dialogController: PopoverController ) { }

  onChose(chose: boolean): void {
    this.dialogController.dismiss(chose, 'decision');
  }
}
