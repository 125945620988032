import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ModalController } from '@ionic/angular';

import { FileService } from '../../../services/file.service';

import { Ad } from '../../../models/Ad/Ad';
import { Image } from '../../../models/Image';
import { Chat } from '../../../models/Chat';

import { DateTime } from 'luxon';

import { RateUserComponent } from '../../rate-user/rate-user.component';

@Component({
  selector: 'app-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.scss'],
})
export class ChatHeaderComponent {

  @Input() classfield: Ad = undefined;
  @Input() chat: Chat = undefined;
  @Input() chatHeaderTitle: string = undefined;

  ratingModal: any;

  constructor( private fileService: FileService,
               private router: Router,
               public modalController: ModalController ) { }

  readPic(image: Image) {

    return this.fileService.readPicture(image);
  }

  calculateCreationDate( createdAt: Date): string {

    const today           = DateTime.now();
    const createdAtHelper = DateTime.fromFormat(createdAt, 'YYYY-MM-DD');
    const time            = today.diff(createdAtHelper, 'days').days;
    if (time === 0) {
      return 'danas';
    } else if (time === 1) {
     return 'juče';
    } else if (time > 1) {
      return `pre ${Math.floor(time)} dana`;
    }
  }

  async openRatingModal() {

    this.ratingModal = await this.modalController.create({
      component: RateUserComponent,
      cssClass: 'rate-user-modal-class',
      componentProps: {
        rating: this.chat.rating,
        chat: this.chat
      }
    });

    this.ratingModal.onWillDismiss().then( data => {
      if ( data.role === 'Submit' ){

        this.chat.rating = data.data.rating;
      }
    });

    return await this.ratingModal.present();
  }
}
