import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-inbox-icon',
  templateUrl: './inbox-icon.component.html',
  styleUrls: ['./inbox-icon.component.scss'],
})
export class InboxIconComponent {

  @Input() notificationsCount = 0;
}
