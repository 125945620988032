import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, tap } from 'rxjs/operators';
import {authUser, isLoggedIn} from '../../auth/auth.selectors';
import { AppState } from '../../reducers';

@Injectable()
export class AuthGuard implements CanActivate {

  //private isUserAuth$: Observable<boolean> = this.store.pipe(select(isLoggedIn));

  private user;

  constructor(private store: Store<AppState>,
              private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.store.pipe(select(authUser)).subscribe( response => {
      this.user = response;
    }).unsubscribe();

    if ( this.user ) {
        return true;
    } else {
      if (state.url === '/home/oglasi/dodaj-oglas') {
        return  this.router.navigate(['/auth/prijava'],{queryParams: {navigate_to: '/home/oglasi/dodaj-oglas'}, replaceUrl: true });
      } else if (next.paramMap.has('chatId')) {
        return  this.router.navigate(['/auth/prijava'],
          { queryParams: { navigate_to: `/home/inbox/${ next.paramMap.get('chatId')}`}, replaceUrl: true });
      } else {
        return false;
      }
    }
  }

}
