import { createReducer, on } from '@ngrx/store';
import { Token } from '../../_shared/models/Auth/Token';
import { User } from '../../_shared/models/User';
import { AuthActions } from '../action-types';

export const authFeatureKey = 'auth';

export interface AuthState {
  token: Token;
  user: User;
  userAuthCheck: boolean;
  notifications: {
    unreadChatsIds: number[]
  };
}

export const initialAuthState: AuthState = {
  token:         undefined,
  user:          undefined,
  userAuthCheck: false,
  notifications: {
    unreadChatsIds: []
  }
};

export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.authInit, (state, _action) => {
    return {
      ...state,
      userAuthCheck: true
    };
  }),
  on(AuthActions.getCurrentUser, (state, _action) => {
    return {
      ...state,
      userAuthCheck: false
    };
  }),
  on(AuthActions.login, (state, _action) => {
    return {
      ...state,
      userAuthCheck: false
    };
  }),
  on(AuthActions.loginFacebook, (state, _action) => {
    return {
      ...state,
      userAuthCheck: false
    };
  }),
  on(AuthActions.loginSuccess, (state, action) => {
    return {
      ...state,
      user:         action.user,
      userAuthCheck: false
    };
  }),
  on(AuthActions.loginFailure, (state, _action) => {
    return {
      ...state,
      user:          undefined,
      userAuthCheck: false,
    };
  }),
  on(AuthActions.register, (state, _action) => {
    return {
      ...state,
      userAuthCheck: false
    };
  }),
  on(AuthActions.registerSuccess, (state, _action) => {
    return {
      ...state,
      userAuthCheck: false
    };
  }),
  on(AuthActions.registerFailure, (state, _action) => {
    return {
      ...state,
      token:         undefined,
      user:          undefined,
      userAuthCheck: false,
    };
  }),
  on(AuthActions.resetPassword, (state, _action) => {
    return {
      ...state,
      userAuthCheck: false
    };
  }),
  on(AuthActions.resetPasswordSuccess, (state, action) => {
    return {
      ...state,
      user:          action.user,
      userAuthCheck: false
    };
  }),
  on(AuthActions.resetPasswordFailure, (state, _action) => {
    return {
      ...state,
      user:          undefined,
      userAuthCheck: false,
    };
  }),
  on(AuthActions.logout, (state, _action) => {
    return {
      ...state,
      user:          undefined,
      userAuthCheck: false
    };
  }),
  on(AuthActions.logoutSuccess, (state, _action) => {
    return {
      ...state,
      token:         undefined,
      user:          undefined,
      userAuthCheck: false
    };
  }),
  on(AuthActions.updateSuccess, (state, action) => {
    return {
      ...state,
      user:          action.user,
      userAuthCheck: false
    };
  }),
  on(AuthActions.activateAccountByEmail, (state, action) => {
    return {
      ...state,
      user: state.user ? {
        ...state.user,
        userAccountConfirmed: true,
        verificationType: 'email'
      } : undefined,
      userAuthCheck: false
    };
  }),
  on(AuthActions.activateAccountByPhoneNumber, (state, action) => {
    return {
      ...state,
      user: state.user ? {
        ...state.user,
        userAccountConfirmed: true,
        verificationType: 'phoneNumber'
      } : undefined,
      userAuthCheck: false
    };
  }),
  on(AuthActions.clearAuth, (state, action) => {
    return {
      ...state,
      user: undefined,
      userAuthCheck: false,
      token: undefined
    }
  }),

  on(AuthActions.getNotifications, (state, _action) => {
    return {
      ...state
    };
  }),
  on(AuthActions.getNotificationsSuccess, (state, _action) => {
    const chatIds = [...state.notifications.unreadChatsIds];

    _action.chatIds.forEach(id => {
      if (chatIds.find(chatId => chatId === id) === undefined) {
        chatIds.push(id);
      }
    });
    return {
      ...state,
      notifications: {
        ...state.notifications,
        unreadChatsIds: chatIds
      }
    };
  }),

  on(AuthActions.getNotificationsFailure, (state, _action) => {
    return {
      ...state,
      notifications: {
        ...state.notifications,
        unreadChatsIds: []
      }
    };
  }),

  on(AuthActions.notificationsMarkChatAsRead, (state, _action) => {
    return {
      ...state,
      notifications: {
        ...state.notifications,
        unreadChatsIds: state.notifications.unreadChatsIds.filter(chatId => chatId !== _action.chatId)
      }
    };
  }),

  on(AuthActions.updateCredit, (state, _action) => {
    return {
      ...state,
      user: {
        ...state.user,
        credit: _action.credit
      }
    };
  })
);


