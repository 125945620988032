import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import {ModalController, PopoverController, ToastController} from '@ionic/angular';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { ClipboardService } from 'ngx-clipboard';
import { AppState } from 'src/app/reducers';
import { Ad } from '../../../models/Ad/Ad';
import { Location } from '../../../models/Location';
import { PlatformService } from '../../../services/platform.service';
import { locations, locationsLoading} from '../../../store/location/selectors/location.selectors';
import { FileService } from '../../../services/file.service';
import { Image } from '../../../models/Image';
import { Loading } from '../../../models/Loading';
import { locationLoad } from '../../../store/location/actions/location.actions';
import { Subscription } from 'rxjs';
import { YesNoDialogComponent } from '../../yes-no-dialog/yes-no-dialog.component';
import {ClassfieldTypePopoverComponent} from '../../classfield-type-popover/classfield-type-popover.component';
import {DeleteFeedbackComponent} from '../../delete-feedback/delete-feedback.component';
import {StripService} from '../../../services/strip.service';

@Component({
  selector:    'app-classfield-list-card',
  templateUrl: './classfield-list-card.component.html',
  styleUrls:   ['./classfield-list-card.component.scss'],
})
export class ClassfieldListCardComponent implements OnInit, OnDestroy {

  @Input() ad: Ad;
  @Input() owner        = false;
  @Input() adType       = '';
  @Input() renewClassfieldPrice = undefined;
  @Input() isProfilePage = false;
  @Input() admin = false;
  @Input() isAuthUser;
  displayAd: Ad;
  locations: Location[] = [];
  subscriptions: Subscription[] = [];

  yesNoDialog;
  classfieldTypeDialog;

  @Output() refreshMainPage: EventEmitter<void> = new EventEmitter<void>();
  @Output() renewClassfieldEmmiter: EventEmitter<number> = new EventEmitter<number>();
  @Output() strikeClassfieldEmmiter: EventEmitter<number> = new EventEmitter<number>();


  //adTypeId: 2: Top  classfield
  //adTypeId: 3: Bold classfield
  //adTypeId: 4: Gold classfield

  constructor(public platformService: PlatformService,
              private fileService: FileService,
              private clipboardApi: ClipboardService,
              private toastController: ToastController,
              private router: Router,
              private store: Store<AppState>,
              private modalController: ModalController,
              private popoverController: PopoverController,
              private stripService: StripService) {}

  ngOnInit() {
    if ( !this.admin && this.isAuthUser ) {
      this.admin = localStorage.getItem('role')?.includes('admin') ? true : false;
    }

    this.calculateCreationDate();
    this.stripDescription();
  }

  checkIfAdTypeIsActive( adTypeName: string ): boolean {

    const adTypeId = adTypeName.toLowerCase() === 'gold' ? 4 : 3;
    const index = this.ad.adAdTypes.findIndex(x => x.adTypeId === adTypeId);

    return index === -1 ? false : this.ad.adAdTypes[index].active;
  }

  calculateCreationDate() {

    this.displayAd  = Object.assign({}, this.ad);
    const today     = DateTime.now();
    const createdAt =
      DateTime.fromFormat(this.displayAd?.refreshedAt === this.displayAd.createdAt ?
        this.displayAd.createdAt : this.displayAd.refreshedAt, 'YYYY-MM-DD');
    const time      = today.diff(createdAt, 'days').days;
    if (time === 0) {
      this.displayAd.time = 'danas';
    } else if (time === 1) {
      this.displayAd.time = 'juče';
    } else if (time > 1) {
      this.displayAd.time = `pre ${Math.floor(time)} dana`;
    }
  }


  stripDescription(): void {
    this.displayAd.description =
      this.stripService.removeParagraphs( this.stripService.breakPotentionalHarmfullText(this.displayAd.description, false));
  }

  checkPrice(price: string): number {
    return this.stripService.removeCharactersFromPrice(price);
  }

  checkDates(createdAt, refreshedAt ){
    return DateTime.fromFormat(refreshedAt, 'YYYY-MM-DD').diff(DateTime.fromFormat(createdAt, 'YYYY-MM-DD'), 'minutes').minutes;
  }

  readPic(image: Image) {

    return this.fileService.readPicture(image, true);
  }

  stopRouterLink(event: Event){
    event.stopPropagation();
  }

  /**
   * TODO(SSR-hide) This method is called only on xl screens so checking for Desktop is removed
   */
  share() {
    navigator.share({
      title: 'Optional title',
      text:  'Optional message',
      url:   'http://www.myurl.com'
    })
      .then(() => {
        console.log('Successful share');
      })
      .catch((error) => {
        console.log('Error sharing:', error);
      });

    /*
    if (this.platformService.isDesktop()) {
      navigator.share({
                        title: 'Optional title',
                        text:  'Optional message',
                        url:   'http://www.myurl.com'
                      })
        .then(() => {
          console.log('Successful share');
        })
        .catch((error) => {
          console.log('Error sharing:', error);
        });
    }
    else {
      window['plugins'].socialsharing.shareWithOptions({
                                                         url:          'http://localhost:8100/oglasi/lista/' +
                                                                       this.ad.id,
                                                         chooserTitle: 'Odaberite aplikaciju',
                                                       });
    }
    */
  }

  async copyUrl(event, adId) {

    event.stopPropagation();
    this.clipboardApi.copy('https://eventum.net/oglas/' + adId);
    const toast = await this.toastController.create({
                                                      message:       'Link je kopiran u privremenu memoriju.',
                                                      duration:      2000,
                                                      position:      'top',
                                                      color:         'success',
                                                      keyboardClose: true,
                                                      animated:      true
                                                    });
    toast.present();
  }

  editAd($event: MouseEvent, id: number) {

    $event.stopPropagation();
    this.router.navigate(['home/oglasi/' + id + '/izmeni-oglas']);
  }

  async renewClassfield( event: any, classfieldId: number ): Promise<any> {
    event.stopPropagation();

    this.yesNoDialog = await this.popoverController.create({
      component: YesNoDialogComponent,

      componentProps : { dialogText: `Ova usluga će biti naplaćena ${ this.renewClassfieldPrice } poena`, buttonText: ['Ok', 'Odustani'] }
    });

    this.yesNoDialog.onDidDismiss().then((response: any) => {
      if (response.role === 'decision' && response.data) {
          this.renewClassfieldEmmiter.emit(classfieldId);
      }
    });

    return await this.yesNoDialog.present();
  }

  async updateClassfieldType(event: any): Promise<any> {

    event.stopPropagation();
    this.classfieldTypeDialog = await this.popoverController.create({
      component: ClassfieldTypePopoverComponent,
      componentProps : { classfieldId: this.ad.id,
                         classfieldTypeId: this.ad.typeId,
                         classfieldClassfieldTypes: this.ad.adAdTypes.map(adType => {
                             return {
                               ...adType
                             };
                           }),
                         popover: true},
      cssClass: 'classfield-type-modal-class',
    });

    this.classfieldTypeDialog.onDidDismiss().then((response: any) => {
      if (response.role === 'submit') {
        this.refreshMainPage.emit();
      }
    });

    return await this.classfieldTypeDialog.present();
  }

  async onDeleteClassfield($event: any, admin: boolean) {
    event.stopPropagation();

    const css = admin ? 'delete-feedback-modal-class-admin' : 'delete-feedback-modal-class';

    const deletePopover = await this.modalController.create({
      component: DeleteFeedbackComponent,
      cssClass: css,
      componentProps: {
        classfieldId: this.ad.id,
        admin,
        isProfilePage: this.isProfilePage
      }
    });

    await deletePopover.present();

    deletePopover.onDidDismiss().then(value => {
      if ( value.role === 'delete-refresh' ){
        this.refreshMainPage.emit();
      }
    });
  }

  async onStrikeClassfield($event: any, admin: boolean) {
    event.stopPropagation();

    const deletePopover = await this.popoverController.create({
      component: YesNoDialogComponent,
      componentProps : { dialogText: `Da li ste sigurni?`, buttonText: ['Da', 'Ne'] }
    });

    await deletePopover.present();

    deletePopover.onDidDismiss().then(value => {

      if ( value.role === 'decision' && value.data ) {
        this.strikeClassfieldEmmiter.emit(this.ad.id);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.map(s => s.unsubscribe());
    this.subscriptions = [];
  }
}
