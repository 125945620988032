import { createFeatureSelector, createSelector } from '@ngrx/store';

import { classfieldCountFeatureKey } from '../reducers/classfield-count.reducers';

export const selectClassfieldCountState = createFeatureSelector<any>(classfieldCountFeatureKey);

export const classfieldCount = createSelector(
  selectClassfieldCountState,
  ( state ) => state
);
