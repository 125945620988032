import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SettingsService extends BaseApiService {

  public getRenewClassfieldPrice(): Observable<any> {

    return this.http.get(`${this.apiUrl}/api/settings/get-by-key/${environment.setOnTopPrice}`);
  }

  public getMaxAllowedDebt(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/settings/get-by-key/${environment.maxAllowedDebt}`)
  }
}
