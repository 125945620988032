import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { RecaptchaComponent } from 'ng-recaptcha';

import { RecaptchaService } from '../../services/recaptcha.service';

@Component({
  selector: 'app-invisible-recaptcha',
  templateUrl: './invisible-recaptcha.component.html',
  styleUrls: ['./invisible-recaptcha.component.scss'],
})
export class InvisibleReCaptchaComponent {

  @ViewChild('reCaptchaRef') invisibleReCaptcha: RecaptchaComponent ;

  @Input()  setCaptchaInline = false;
  @Output() captchaResponseEmmiter: EventEmitter<boolean> = new EventEmitter<boolean>();

  // production siteKey: 6LebM64oAAAAAIhfLWDyXA85-_9K1afeOUC39FJS
  // development siteKey: 6LcWVEEeAAAAAGDGizW86XRfoskzRd11jnf8dM66
  public siteKey = '6LebM64oAAAAAIhfLWDyXA85-_9K1afeOUC39FJS';

  constructor(private recaptchaService: RecaptchaService) { }

  public executeReCaptcha(): void {

    this.invisibleReCaptcha.execute();
  }

  reCaptchaResolved( reCaptchaToken: string ): void {
    if (reCaptchaToken) {
      this.recaptchaService.recaptcha( reCaptchaToken ).subscribe( response => {
          this.captchaResponseEmmiter.emit( response );
        },
        error => {
          console.log( error );
        },
        () => this.invisibleReCaptcha.reset()
      );
    }

  }
}
